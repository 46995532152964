import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { QUERY_SYSTEMS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

type DropdownClustersProps = {
    clusterName: string;
    siteReference: string;
};

export default function DropdownClusters({
    clusterName,
    siteReference,
}: DropdownClustersProps) {
    const navigate = useNavigate();

    const { data } = useQuery(QUERY_SYSTEMS, {
        variables: { search: siteReference, limit: 50, offset: 0 },
    });

    if (!data) {
        return (
            <div className="flex text-gray-500 font-bold">{clusterName}</div>
        );
    }
    const systems: Connection<CoreSystem> = data.systems;

    return (
        <Menu as="div" className="relative">
            <div className="group flex items-center gap-2 hover:cursor-pointer">
                <Menu.Button className="flex text-gray-500 font-bold">
                    {clusterName}{" "}
                    <HiChevronDown
                        fontSize={20}
                        className="opacity-100 transition-opacity duration-150 ease-in-out"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right z-10 absolute left-0 mt-2 w-36 rounded-sm shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {systems.edges.map(({ node }) => {
                        const nodeName = node.clientRef || node.macAddress;
                        return (
                            <Menu.Item key={node.macAddress}>
                                {({ active }) => (
                                    <div
                                        onClick={() =>
                                            navigate(
                                                `/sites/${siteReference}/${node.macAddress}`
                                            )
                                        }
                                        className={classNames(
                                            active && "bg-gray-100",
                                            clusterName === nodeName &&
                                                "bg-gray-200",
                                            "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                                        )}
                                    >
                                        {nodeName}
                                    </div>
                                )}
                            </Menu.Item>
                        );
                    })}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
