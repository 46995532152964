import { Link } from "react-router-dom";

import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import Tabs from "../../layout/Tabs";
import { Box, Col, Grid } from "../../layout/Boxes";
import { Content, ContentTop } from "../../layout/Content";
import { TagHealth } from "../../layout/Tag";
import { useTranslation } from "react-i18next";

type PageSiteProps = {
    sites: Connection<CoreSite>;
};

const PageSites = ({ sites }: PageSiteProps) => {
    const { t } = useTranslation();
    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        { label: t("Sites") },
    ];
    const tabPaths = [{ label: t("Overview"), href: "#" }];

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />

            <ContentTop h1={t("All Sites")} h2={t("Dashboard")} />

            <Tabs paths={tabPaths} />

            <Grid cols={6}>
                {sites.edges.map(({ node }, index) => (
                    <Col key={`site-${node.reference}`}>
                        <Box title={`${node.name}`}>
                            <div className="text-sm text-gray-500">
                                {t(node.status) || t("No status")}
                            </div>
                            <div className="flex justify-between py-3">
                                <div>
                                    <TagHealth value={node.healthScore} />
                                </div>
                                <div>
                                    <Link
                                        className="button light"
                                        to={`/sites/${node.reference}`}
                                    >
                                        {t("View Site")}
                                    </Link>
                                </div>
                            </div>
                        </Box>
                    </Col>
                ))}
            </Grid>
        </Content>
    );
};

export default PageSites;
