import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import { getCookie } from "../helpers/cookies";
import config from "../config";

const accountReference: string | null = getCookie("account_reference");
const accessKey: string | null = getCookie("access_key");
let auth = {};
let credentials = "include";

if (accountReference && accessKey) {
    console.log("*** Authenticating Core with cookies ***");
    auth = { account_reference: accountReference, access_key: accessKey };
    credentials = "omit";
}

const httpLink = new HttpLink({
    uri: config.apiUrl + "/graphql/v1",
    credentials,
    headers: {
        "Content-Type": "application/json",
        ...auth,
    },
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: config.wsUrl + "/graphql/v1",
        connectionParams: {
            ...auth,
        },
        lazy: true,
        on: {
            connected: () => console.log("WebSocket Connected"),
            error: (error) => console.error("WebSocket Error", error),
        },
    })
);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    wsLink,
    httpLink
);

const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
        typePolicies: {
            SystemType: {
                keyFields: ["macAddress"],
            },
            Site: {
                keyFields: ["reference"],
            },
            AccountType: {
                keyFields: ["reference"],
            },
            SystemIssue: {
                keyFields: ["number"],
            },
        },
    }),
});

export default client;
