import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { generateColor, systemStatus } from "../../../helpers/utils";
import { Tag, TagHealth } from "../../layout/Tag";
import { PageSiteProps } from "./Site";
import { useUser } from "../../context/UserContext";

export default function TableSystems({ systems, site }: PageSiteProps) {
    const { hasScope } = useUser();
    const { t } = useTranslation();

    const rows = systems.edges.map((edge) => {
        const textName = edge.node.clientRef || edge.node.macAddress;
        const textStatus = systemStatus(edge.node);

        const tdName = (
            <Link to={`/sites/${site.reference}/${edge.node.macAddress}`}>
                {textName}
            </Link>
        );
        const tdHealth = <TagHealth value={edge.node.healthScore} />;

        let commissionedColor = "Orange";
        if (edge.node.commissionedPercent === 100) {
            commissionedColor = "Green";
        } else if (edge.node.commissionedPercent === 0) {
            commissionedColor = "Gray";
        } else if (edge.node.commissionedPercent > 100) {
            commissionedColor = "Red";
        }
        const tdCommissioned = (
            <Tag bgColor={generateColor(commissionedColor)}>
                {edge.node.commissionedPercent <= 100
                    ? `${edge.node.commissionedPercent}%`
                    : t("Error")}
            </Tag>
        );

        const tdStatus = (
            <Tag bgColor={generateColor(textStatus)}>{t(textStatus)}</Tag>
        );

        let reachable = edge.node.reachable;
        // disabled = false;

        if (reachable === false) {
            return {
                ...edge.node,
                td_name: tdName,
                td_health: tdHealth,
                td_status: tdStatus,
                td_commissioned: tdCommissioned,
                reachable: reachable,
            };
        }

        return {
            ...edge.node,
            td_name: tdName,
            td_health: tdHealth,
            td_status: tdStatus,
            td_commissioned: tdCommissioned,
            reachable: reachable,
        };
    });

    return (
        <table className="table-auto w-full my-3">
            <thead>
                <tr>
                    <th>{t("Cluster")}</th>
                    <th className="hidden md:table-cell">{t("Hostname")}</th>
                    {hasScope("superadmin") && (
                        <th className="text-center">{t("Health")}</th>
                    )}
                    <th className="text-center">{t("System Status")}</th>
                    <th className="text-center hidden md:table-cell">
                        {t("Commissioned")}
                    </th>
                </tr>
            </thead>
            <tbody>
                {rows.map((node) => (
                    <tr
                        key={node.macAddress}
                        className={classNames(
                            systemStatus(node) === "Connected"
                                ? ""
                                : "unreachable"
                        )}
                    >
                        <td className="max-w-[50px] truncate">
                            {node.td_name}
                        </td>
                        <td className="hidden md:table-cell">
                            {node.hostname}
                        </td>
                        {hasScope("superadmin") && (
                            <td className="text-center">{node.td_health}</td>
                        )}
                        <td className="text-center">{node.td_status}</td>
                        <td className="text-center hidden md:table-cell">
                            {node.td_commissioned}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
