import classNames from "classnames";

const Box = ({
    title = null,
    bottom = null,
    children = null,
    className = null,
}) => {
    return (
        <div
            className={classNames(
                "bg-white rounded-sm flex flex-col flex-1",
                className
            )}
        >
            <div className="m-3 h-full">
                {title && <h2 className="text-lg">{title}</h2>}
                {children}
            </div>
            {bottom && (
                <div className="mt-auto bg-gray-200 p-2 text-gray-500 text-sm text-center">
                    {bottom}
                </div>
            )}
        </div>
    );
};

const Boxes = ({ children = null, className = null }) => {
    return (
        <div
            className={classNames(
                "flex flex-wrap gap-4 flex-col md:flex-row",
                className
            )}
        >
            {children}
        </div>
    );
};

const Grid = ({ children = null, cols = 1, className = null }) => {
    const getGridColsClass = (num) => {
        switch (num) {
            case 1:
                return "grid-cols-1";
            case 2:
                return "grid-cols-1 md:grid-cols-2";
            case 3:
                return "grid-cols-1 md:grid-cols-3";
            case 4:
                return "grid-cols-2 md:grid-cols-4";
            case 5:
                return "grid-cols-2 md:grid-cols-5";
            case 6:
                return "grid-cols-2 md:grid-cols-6";
            default:
                return "grid-cols-1"; // default
        }
    };

    return (
        <div
            className={classNames(
                `grid ${getGridColsClass(cols)}`,
                "gap-4",
                className
            )}
        >
            {children}
        </div>
    );
};

const Col = ({ children = null, className = null }) => {
    return (
        <div className={classNames("flex flex-col gap-4", className)}>
            {children}
        </div>
    );
};

export { Grid, Col, Box, Boxes };
