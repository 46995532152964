import { useQuery } from "@apollo/client";
import { createContext, useState, useContext, useMemo, useEffect } from "react";
import { QUERY_SITES } from "../../graphql/queries";

const UserContext = createContext<{
    user: User | null;
    setUser: (user: User | null) => void;
    hasScope: (scope: string) => boolean;
}>({
    user: null, // Default user is set to null, indicating no user is logged in initially
    setUser: () => {}, // Placeholder function, to be replaced by the actual function that updates the user state
    hasScope: () => false,
});

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<{
    children: React.ReactNode;
    initialUser?: User | null;
}> = ({ children, initialUser }) => {
    const [user, setUser] = useState<User | null>(initialUser || null);

    const hasScope = useMemo(() => {
        return (scope: string) => {
            if (!user) {
                return false;
            }
            if (user.superadmin) {
                return true;
            }
            const account = user?.accounts?.find(
                (row) => row.value === user.account_reference
            );
            return account.scopes?.includes(scope) || false;
        };
    }, [user]);

    const { data: sites, refetch: refetch_sites } = useQuery(QUERY_SITES, {
        variables: { limit: 50, offset: 0 },
    });

    useEffect(() => {
        refetch_sites();
    }, [user, refetch_sites]);

    useEffect(() => {
        const connSites: Connection<CoreSite> = sites?.account.sites;
        if (connSites) {
            window.Mixpanel.people.set(
                "Authorized Sites",
                connSites?.edges.map((site) => site.node.reference).join(",")
            );
        }
    }, [sites]);

    return (
        <UserContext.Provider value={{ user, setUser, hasScope }}>
            {children}
        </UserContext.Provider>
    );
};
