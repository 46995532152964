import { useState } from "react";
import { Link } from "react-router-dom";
import { ApolloQueryResult } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { formatDate } from "../../../helpers/utils";
import Pagination from "../../layout/Pagination";
import { Content, ContentTop } from "../../layout/Content";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import { Box } from "../../layout/Boxes";
import Modal from "../../layout/Modal";
import ResolveForm from "./ResolveForm";
import Tabs from "../../layout/Tabs";

interface PageIssuesProps {
    issues: Connection<Issue>;
    refetch: () => Promise<ApolloQueryResult<any>>;
}

const PageIssues = ({ issues, refetch }: PageIssuesProps) => {
    const { t } = useTranslation();

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        { label: t("Support"), href: "/support" },
        { label: t("Issues") },
    ];

    const tabPaths = [
        { label: t("Resources"), href: "/support" },
        { label: t("Support Tickets"), href: "#" },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeResolveModal = () => setIsModalOpen(false);

    const [selectedIssue, selectIssue] = useState<Issue | null>(null);

    const openResolveModal = (issue: Issue) => {
        selectIssue(issue);
        setIsModalOpen(true);
    };

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />
            <ContentTop h1="Issues">
                <div className="border border-stone-300 rounded p-1 buttons">
                    <Link to="/support/issues/new" className="button info">
                        {t("New Issue")}
                    </Link>
                </div>
            </ContentTop>
            <Tabs paths={tabPaths} activeTab="#" />

            <Box>
                <table className="w-full">
                    <thead>
                        <tr>
                            <th>{t("Issue")}</th>
                            <th className="hidden md:table-cell">
                                {t("Severity")}
                            </th>
                            <th className="hidden md:table-cell">
                                {t("Site")}
                            </th>
                            <th>{t("Title")}</th>
                            <th className="hidden md:table-cell">
                                {t("Raised At")}
                            </th>
                            <th>{t("Status")}</th>
                            <th className="w-48 text-center">{t("Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {issues.edges.map((edge) => (
                            <tr key={edge.node.number}>
                                <td>
                                    #{edge.node.number}{" "}
                                    {edge.node.legacyNumber ? (
                                        <span className="text-xs text-gray-400 hidden md:block">
                                            ({edge.node.legacyNumber})
                                        </span>
                                    ) : null}
                                </td>
                                <td className="hidden md:table-cell">
                                    {edge.node.severity}
                                </td>
                                <td className="hidden md:table-cell">
                                    {edge.node.site}
                                </td>
                                <td>{edge.node.title}</td>
                                <td className="hidden md:table-cell">
                                    {formatDate(edge.node.raisedAt)}
                                </td>
                                <td>{edge.node.status}</td>
                                <td>
                                    <div className="flex flex-wrap justify-center gap-2">
                                        <Link
                                            to={`/support/issues/${edge.node.number}`}
                                            title="View"
                                        >
                                            {t("View")}
                                        </Link>

                                        {edge.node.status !== "Resolved" && (
                                            <button
                                                className="button link"
                                                onClick={() =>
                                                    openResolveModal(edge.node)
                                                }
                                            >
                                                {t("Resolve")}
                                            </button>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Pagination pageInfo={issues.pageInfo} />
                <Modal
                    isOpen={isModalOpen}
                    onClose={closeResolveModal}
                    size="max-w-xl"
                >
                    <ResolveForm
                        issue={selectedIssue}
                        refetch={refetch}
                        closeModal={closeResolveModal}
                    />
                </Modal>
            </Box>
        </Content>
    );
};

export default PageIssues;
