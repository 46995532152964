import React, { useRef } from "react";
import Select, { Props as SelectProps } from "react-select";
import classNames from "classnames";

import { useQuery } from "@apollo/client";
import { QUERY_SITES } from "../../graphql/queries";

interface InputProps {
    label?: string | null;
    error?: string | null;
    props?: React.InputHTMLAttributes<HTMLInputElement>;
    className?: string;
}

interface TextareaProps {
    label?: string | null;
    error?: string | null;
    props?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
    className?: string;
}

interface DropdownProps {
    label?: string | null;
    error?: string | null;
    props?: SelectProps; //React.SelectHTMLAttributes<HTMLSelectElement>;
    className?: string;
    value?: string | string[] | null;
    options: OptionType[];
}

interface DropdownSitesProps {
    label?: string | null;
    value?: string | string[] | null;
    error?: string | null;
    props?: SelectProps; //React.SelectHTMLAttributes<HTMLSelectElement>;
    className?: string;
}

interface FormProps {
    props?: React.FormHTMLAttributes<HTMLFormElement>;
    children?: React.ReactNode | null;
    className?: string;
    error?: string | null;
}

const Form = React.forwardRef<HTMLFormElement, FormProps>(
    ({ children = null, props = {}, className = "", error = null }, ref) => {
        return (
            <form
                ref={ref}
                noValidate={true}
                {...props}
                className={classNames(
                    "form-base bg-white py-2 flex-1",
                    className
                )}
            >
                {error && (
                    <div className="error mb-4 font-semibold">{error}</div>
                )}
                {children}
            </form>
        );
    }
);

const InputText = ({
    label = null,
    error = null,
    props = {},
    className = null,
}: InputProps) => {
    const { name = "", required = false } = { ...props };

    return (
        <div className={classNames("my-2", error ? "error" : "", className)}>
            {label && (
                <label htmlFor={name}>
                    {label} {required && "*"}
                </label>
            )}
            <input type="text" id={name} required={required} {...props} />
            {error && <p>{error}</p>}
        </div>
    );
};

const InputDate = ({
    label = null,
    error = null,
    props = {},
    className = null,
}: InputProps) => {
    const { name = "", required = false } = { ...props };
    const inputRef = useRef(null);

    const handleFocus = () => {
        if (inputRef.current) {
            inputRef.current.showPicker();
        }
    };

    return (
        <div className={classNames("my-2", error ? "error" : "", className)}>
            {label && (
                <label htmlFor={name}>
                    {label} {required && "*"}
                </label>
            )}
            <input
                type="datetime-local"
                id={name}
                ref={inputRef}
                required={required}
                {...props}
                onClick={handleFocus}
            />
            {error && <p>{error}</p>}
        </div>
    );
};

const InputColor = ({
    label = null,
    error = null,
    props = {},
    className = null,
}: InputProps) => {
    const { name = "", required = false } = { ...props };
    return (
        <div className={classNames("my-2", error ? "error" : "", className)}>
            {label && (
                <label htmlFor={name}>
                    {label} {required && "*"}
                </label>
            )}
            <input type="color" id={name} required={required} {...props} />
            {error && <p>{error}</p>}
        </div>
    );
};

const InputTextarea = ({
    label = null,
    error = null,
    props = {},
    className = null,
}: TextareaProps) => {
    const { name = "", required = false } = { ...props };
    return (
        <div className={classNames("my-2", error ? "error" : "", className)}>
            {label && (
                <label htmlFor={name}>
                    {label} {required && "*"}
                </label>
            )}
            <textarea id={name} {...props} />
            {error && <p>{error}</p>}
        </div>
    );
};

const InputDropdown = ({
    label = null,
    error = null,
    props = {},
    value = null,
    options = [],
    className = "",
}: DropdownProps) => {
    const { name = "", required = false } = { ...props };

    let selected = null;
    // String value - One option
    if (value && value !== "") {
        selected = options.find((o) => o.value === value);
    }
    // Array value - Multiple options
    if (value && value.length > 0) {
        selected = options.filter((o) => value.includes(o.value));
    }

    return (
        <div className={classNames("mb-2", error ? "error" : "", className)}>
            {label && (
                <label htmlFor={name}>
                    {label} {required && "*"}
                </label>
            )}
            <Select
                options={options}
                value={selected}
                {...props}
                className="dropdown"
                classNames={{
                    control: (state) =>
                        state.isFocused
                            ? "border-red-600"
                            : "bg-red-50 border-red-500",
                }}
                styles={{
                    control: (styles) => ({
                        ...styles,
                        // backgroundColor: "green",
                    }),
                }}
            />
            {error && <p>{error}</p>}
        </div>
    );
};

const InputSites = ({
    label = null,
    value = null,
    error = null,
    props = {},
    className = "",
}: DropdownSitesProps) => {
    const { name = "", required = false } = { ...props };

    const { data } = useQuery(QUERY_SITES, {
        variables: { limit: 50, offset: 0 },
    });

    if (!data) {
        return null;
    }
    const sites: Connection<CoreSite> = data.account.sites;

    const options = sites.edges.map((e) => {
        return { value: e.node.reference, label: e.node.name };
    });

    let selected = null;
    // String value - One option
    if (value && value !== "") {
        selected = options.find((o) => o.value === value);
    }
    // Array value - Multiple options
    if (value && value.length > 0) {
        selected = options.filter((o) => value.includes(o.value));
    }

    return (
        <div className={classNames("mb-2", error ? "error" : "", className)}>
            {label && (
                <label htmlFor={name}>
                    {label} {required && "*"}
                </label>
            )}
            <Select
                options={options}
                value={selected}
                {...props}
                className="dropdown"
            />
            {error && <p>{error}</p>}
        </div>
    );
};

export {
    InputText,
    InputDate,
    InputColor,
    InputTextarea,
    InputDropdown,
    InputSites,
    Form,
};
