import { formatDistance } from "date-fns";
import { useSubscription } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { SUBSCRIPTION_CONTROLLER_EVENTS } from "../../../graphql/subscriptions";
import { generateColor } from "../../../helpers/utils";
import { useConnection } from "../../context/ConnectionContext";

type SystemEventsProps = {
    system: CoreSystem;
    refetch: () => void;
};

export function ControllerEvents({ system, refetch }: SystemEventsProps) {
    const { t } = useTranslation();

    const TIME_TO_WAIT = 2500; // 2.5 seconds
    const lastRefetchTime = useRef(0);
    const timeoutRef = useRef(null);

    const { data, loading, error } = useSubscription(
        SUBSCRIPTION_CONTROLLER_EVENTS,
        {
            variables: {
                macAddresses: [system.macAddress],
            },
        }
    );
    const [accumulatedData, setAccumulatedData] = useState([]);
    const dataEndRef = useRef(null);

    useEffect(() => {
        if (data && data.events) {
            setAccumulatedData((currentData) => [
                ...currentData,
                {
                    date: new Date(),
                    event: data.events,
                    color: generateColor(data.events.kind),
                },
            ]);

            const now = Date.now();

            if (now - lastRefetchTime.current > TIME_TO_WAIT) {
                lastRefetchTime.current = now;
                refetch();
            } else {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }

                timeoutRef.current = setTimeout(
                    () => {
                        lastRefetchTime.current = Date.now();
                        refetch();
                    },
                    TIME_TO_WAIT - (now - lastRefetchTime.current)
                );
            }
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [data, refetch]);

    if (error) return <p>Error :(</p>;

    return (
        <div>
            <h5 className="mb-2 text-xl font-medium ">Events</h5>

            <hr className="border-t border-muted mb-4" />

            <table className="w-full">
                <thead>
                    <tr>
                        <th className="w-1/12">Time</th>
                        <th className="w-1/5">Event Kind</th>
                        <th>Event Payload</th>
                    </tr>
                </thead>
                <tbody>
                    {accumulatedData.map((row) => (
                        <tr key={row.event.id}>
                            <td className="text-xs text-gray-500">
                                {formatDistance(row.date, new Date(), {
                                    addSuffix: true,
                                })}
                            </td>
                            <td>
                                <span
                                    style={{ backgroundColor: row.color }}
                                    className={`text-center text-xs text-white rounded py-1 px-2 inline-block`}
                                >
                                    {row.event.kind}
                                </span>
                            </td>
                            <td>
                                <div className="max-h-[60px] overflow-hidden text-ellipsis">
                                    {JSON.stringify(row.event.payload)}
                                </div>
                            </td>
                        </tr>
                    ))}

                    {loading && (
                        <tr>
                            <td colSpan={3}>{t("Loading...")}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div ref={dataEndRef} />
        </div>
    );
}

export default function ControllerEventsWrapper({
    system,
    refetch,
}: SystemEventsProps) {
    const { connectionLost } = useConnection();

    if (connectionLost) {
        return null;
    }

    if (system.reachable !== true) {
        return null;
    }

    return <ControllerEvents system={system} refetch={refetch} />;
}
