import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";

import { useUser } from "../../context/UserContext";
import { getSignOutUrl } from "../../../services/auth";

const UserHeader = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    if (!user) {
        return null;
    }

    return (
        <Menu as="div" className="relative hidden md:flex">
            <div className="flex items-center gap-2">
                {/* <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400"> */}
                <span className="sr-only">Open user menu</span>
                <div
                    className="h-7 w-7 rounded-full bg-gray-300 bg-cover bg-no-repeat bg-center"
                    style={
                        user.picture
                            ? {
                                  backgroundImage: `url("${user.picture}")`,
                              }
                            : {}
                    }
                >
                    <span className="sr-only">{user.name}</span>
                </div>
                {/* </Menu.Button> */}
                <div className="pl-13 py-3">
                    <div className="text-neutral-500 text-sm">{user.name}</div>
                    <div className="text-neutral-400 text-xs">{user.email}</div>
                </div>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                        {({ active }) => (
                            <div
                                onClick={() => navigate("/profile")}
                                className={classNames(
                                    active && "bg-gray-100",
                                    "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                                )}
                            >
                                Your Profile
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <div
                                onClick={() => navigate("/settings")}
                                className={classNames(
                                    active && "bg-gray-100",
                                    "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                                )}
                            >
                                Settings
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <Link
                                to={getSignOutUrl()}
                                className={classNames(
                                    active && "bg-gray-100",
                                    "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                                )}
                            >
                                Sign out
                            </Link>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default UserHeader;
