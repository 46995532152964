import { Form, Link } from "react-router-dom";

export default function PageLogin() {
    return (
        <div className="bg-neutral-100 h-screen w-screen overflow-hidden flex flex-col justify-center">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    src="/logo192b.png"
                    alt="MaxiluxSystems"
                    className="w-auto h-16 mx-auto"
                />
                <h2 className="mt-3 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Customer Portal
                </h2>
            </div>

            <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm bg-white p-5 rounded">
                <Form className="space-y-3" action="/login" method="POST">
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="w-full p-3 mb-3"
                                defaultValue="admin@test.com"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Password
                            </label>
                            <div className="text-sm">
                                <a
                                    href="#forgot"
                                    className="font-semibold text-sky-600 hover:text-sky-500"
                                >
                                    Forgot password?
                                </a>
                            </div>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="w-full p-3 mb-3"
                                defaultValue="*********"
                            />
                        </div>
                    </div>

                    <div className="text-center">
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-sky-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                            >
                                Sign in
                            </button>
                        </div>
                    </div>
                </Form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Not a member?{" "}
                    <Link
                        to="/signup"
                        className="font-semibold leading-6 text-sky-600 hover:text-sky-500"
                    >
                        Sign up
                    </Link>
                </p>
            </div>
        </div>
    );
}
