import { useState } from "react";
import classNames from "classnames";
import { HiServer } from "react-icons/hi";

import ModalNodeFlashFirmware from "./modals/ModalNodeFlashFirmware";

export default function NodeFlashFirmware({ system, refetch }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <button
                className={classNames(
                    "flex justify-center items-center gap-2 bg-white text-sm py-2 px-4 border border-gray-400 rounded",
                    "text-sky-500 hover:text-sky-700"
                )}
                onClick={() => setIsModalOpen(true)}
            >
                <HiServer fontSize={24} />
                Flash Firmware
            </button>

            <ModalNodeFlashFirmware
                system={system}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                refetch={refetch}
            />
        </>
    );
}
