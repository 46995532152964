import { Outlet } from "react-router-dom";

import Sidebar from "./Sidebar";
import Header from "./header/Header";
import { Loader } from "./Loader";
import { LoadingProvider } from "../context/LoadingContext";
import { ConnectionProvider } from "../context/ConnectionContext";
import { ConnectionWarning } from "./ConnectionWarning";

export default function Layout() {
    return (
        <ConnectionProvider>
            <LoadingProvider>
                <ConnectionWarning />
                <div className="flex flex-row bg-neutral-100 h-screen w-screen overflow-auto">
                    <Sidebar />
                    <div className="flex flex-col flex-1">
                        <Header />
                        <div className="flex-1 p-4 min-h-0 overflow-auto">
                            <Outlet />
                        </div>
                    </div>
                    <Loader />
                </div>
            </LoadingProvider>
        </ConnectionProvider>
    );
}
