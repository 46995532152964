import {
    ArrayFieldTemplateItemType,
    ArrayFieldTemplateProps,
    FormContextType,
    getTemplate,
    getUiOptions,
    RJSFSchema,
    StrictRJSFSchema,
} from "@rjsf/utils";

export default function ArrayFieldTemplate<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any,
>(props: ArrayFieldTemplateProps<T, S, F>) {
    const {
        canAdd,
        disabled,
        idSchema,
        uiSchema,
        items,
        onAddClick,
        readonly,
        registry,
        required,
        schema,
        title,
    } = props;
    const uiOptions = getUiOptions<T, S, F>(uiSchema);
    const ArrayFieldDescriptionTemplate = getTemplate<
        "ArrayFieldDescriptionTemplate",
        T,
        S,
        F
    >("ArrayFieldDescriptionTemplate", registry, uiOptions);
    const ArrayFieldItemTemplate = getTemplate<
        "ArrayFieldItemTemplate",
        T,
        S,
        F
    >("ArrayFieldItemTemplate", registry, uiOptions);
    const ArrayFieldTitleTemplate = getTemplate<
        "ArrayFieldTitleTemplate",
        T,
        S,
        F
    >("ArrayFieldTitleTemplate", registry, uiOptions);
    // Button templates are not overridden in the uiSchema
    const {
        ButtonTemplates: { AddButton },
    } = registry.templates;

    return (
        <div>
            <div className="m-0 flex p-0">
                <div className="m-0 w-full p-0">
                    <div className="flex gap-4 justify-between align-middle w-full">
                        <div className="w-full">
                            <ArrayFieldTitleTemplate
                                idSchema={idSchema}
                                title={uiOptions.title || title}
                                schema={schema}
                                uiSchema={uiSchema}
                                required={required}
                                registry={registry}
                            />
                            <ArrayFieldDescriptionTemplate
                                idSchema={idSchema}
                                description={
                                    uiOptions.description || schema.description
                                }
                                schema={schema}
                                uiSchema={uiSchema}
                                registry={registry}
                            />
                        </div>
                        <div>
                            {canAdd && (
                                <div className="">
                                    <AddButton
                                        className="array-item-add rounded"
                                        onClick={onAddClick}
                                        disabled={disabled || readonly}
                                        uiSchema={uiSchema}
                                        registry={registry}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="m-0 w-full p-0">
                        {items &&
                            items.map(
                                ({
                                    key,
                                    ...itemProps
                                }: ArrayFieldTemplateItemType<T, S, F>) => {
                                    // console.log("itemProps", itemProps);
                                    return (
                                        <ArrayFieldItemTemplate
                                            key={key}
                                            {...itemProps}
                                        />
                                    );
                                }
                            )}
                        {items && items.length === 0 && (
                            <div className="text-gray-500 text-sm">
                                No items
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
