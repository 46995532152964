import classNames from "classnames";
import { generateColor } from "../../helpers/utils";
import { useUser } from "../context/UserContext";

interface TagProps {
    children?: React.ReactNode;
    icon?: React.ElementType;
    className?: string;
    bgColor?: string;
}

const Tag = ({
    children = null,
    icon: Icon = null,
    className = "",
    bgColor = "",
}: TagProps) => {
    return (
        <div
            className={classNames(
                "inline-flex items-center rounded-full",
                className,
                bgColor ? `text-white` : ""
            )}
            style={{ backgroundColor: bgColor }}
        >
            {Icon && <Icon className="w-6 h-6" />}
            <span
                className={classNames(
                    "text-xs font-medium px-1 py-1",
                    Icon ? "pr-2" : "px-2"
                )}
            >
                {children}
            </span>
        </div>
    );
};

const TagHealth = ({ value }: { value: number | null }) => {
    const { hasScope } = useUser();
    if (!hasScope("superadmin")) {
        return null;
    }

    if (value === null) {
        return null;
    }
    let color = "";
    if (value === 0) {
        color = generateColor("Gray");
    } else if (value === 100) {
        color = generateColor("Green");
    } else if (value < 50) {
        color = generateColor("Red");
    } else {
        color = generateColor("Orange");
    }

    return <Tag bgColor={color}>{value}%</Tag>;
};

export { Tag, TagHealth };
