import { Link } from "react-router-dom";

interface BreadcrumbsProps {
    items: any[]; //{ href?: string; label: string }[] | React.ReactNode[];
}

export default function Breadcrumbs({ items = [] }: BreadcrumbsProps) {
    const reactItems = [];
    items.forEach((item, index) => {
        // Link
        if (item.href) {
            reactItems.push(
                <BreadcrumbsLink key={index} to={item.href}>
                    {item.label}
                </BreadcrumbsLink>
            );
            return;
        }

        // Span
        if (item.label) {
            reactItems.push(<BreadcrumbsSpan label={item.label} />);
            return;
        }

        // React element
        reactItems.push(item);
    });

    return <BreadcrumbsTemplate items={reactItems} />;
}

const BreadcrumbsTemplate = ({ items }: { items: React.ReactNode[] }) => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="flex text-sm">
                {items.map((item, index) => (
                    <li key={index} className="flex items-center">
                        {item}
                        {index !== items.length - 1 && (
                            <span className="mx-3 text-gray-400">{">"}</span>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

const BreadcrumbsLink = (props) => {
    return <Link className="text-gray-400 hover:underline" {...props} />;
};

const BreadcrumbsSpan = ({ label }) => {
    return <span className="text-gray-400 font-bold">{label}</span>;
};
