import React, { useState } from "react";

const Tooltip = ({ children, text }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div
            className="relative flex items-center"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {children}
            {showTooltip && (
                <div className="absolute bottom-full mb-2 w-max bg-gray-800 text-white text-sm py-1 px-2 rounded shadow-lg">
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
