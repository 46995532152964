import classNames from "classnames";

import UpgradeSystemBtn from "../UpgradeSystem";
import { Tag } from "../../../layout/Tag";
import { generateColor } from "../../../../helpers/utils";
import { useUser } from "../../../context/UserContext";

type StartCommissioningButtonProps = {
    system: CoreSystem;
    startCalibration: () => void;
    refetch: () => void;
    sensorsState: SensorsState;
};

const StartCommissioningButton = ({
    system,
    startCalibration,
    refetch,
    sensorsState,
}: StartCommissioningButtonProps) => {
    const { hasScope } = useUser();

    if (hasScope("superadmin")) {
        return (
            <button
                id="btn-aisle-start-calibration"
                className={classNames(
                    "button light w-48",
                    sensorsState.valid === false ? "disabled" : ""
                )}
                onClick={startCalibration}
                disabled={sensorsState.valid === false}
            >
                Start Commissioning
            </button>
        );
    }

    if (system.softwareStatus === "UPGRADING") {
        return (
            <Tag className="px-4" bgColor={generateColor("UPGRADING")}>
                UPGRADING
            </Tag>
        );
    }

    if (system.softwareStatus !== "UP_TO_DATE") {
        return <UpgradeSystemBtn system={system} refetch={refetch} />;
    }

    return (
        <button
            id="btn-aisle-start-calibration"
            className={classNames("button light w-48")}
            onClick={startCalibration}
        >
            Start Commissioning
        </button>
    );
};

export { StartCommissioningButton };
