export function getCookie(name: string): string | null {
    const cookieArray: string[] = document.cookie.split(";");
    let cookieValue: string | null = null;

    cookieArray.forEach((cookie) => {
        const [key, value] = cookie.trim().split("=");
        if (key === name) {
            cookieValue = decodeURIComponent(value);
        }
    });

    return cookieValue;
}
