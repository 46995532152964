import { useEffect } from "react";
import { useQuery } from "@apollo/client";

import { useLoading } from "../components/context/LoadingContext";
import { useConnection } from "../components/context/ConnectionContext";
import { useUser } from "../components/context/UserContext";

type FetchPolicy = "network-only" | null;

interface UseQueryWithParamsProps<TData, TVariables> {
    query: any;
    variables?: TVariables | null;
    fetchPolicy?: FetchPolicy;
    accessScope?: string | null;
}

export function useQueryWithParams<TData, TVariables>({
    query,
    variables = null,
    fetchPolicy = null,
    accessScope = null,
}: UseQueryWithParamsProps<TData, TVariables>) {
    const { hasScope } = useUser();
    const { setLoading } = useLoading();
    const { setConnectionLost } = useConnection();

    if (accessScope && !hasScope(accessScope)) {
        throw new Error("NOT_ALLOWED");
    }

    const { loading, error, data, refetch } = useQuery<TData, TVariables>(
        query,
        {
            variables,
            fetchPolicy,
        }
    );

    useEffect(() => {
        setLoading(loading);
    }, [loading, setLoading]);

    useEffect(() => {
        setConnectionLost(!!(error && error.networkError));
    }, [error, setConnectionLost]);

    return { loading, error, data, refetch };
}
