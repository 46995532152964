import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { formatDateTime } from "../../../helpers/utils";
import { Box } from "../../layout/Boxes";
import { Content, ContentTop } from "../../layout/Content";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";

interface PageIssueProps {
    issue: Issue;
}

export default function PageIssueView({ issue }: PageIssueProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        { label: "Support", href: "/support" },
        { label: "Issues", href: "/support/issues" },
        { label: "View Issue" },
    ];

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />

            <ContentTop h1={`Issue #${issue.number}`} />

            <Box>
                <div className="">
                    <div className="bg-white p-42rounded-sm flex flex-col gap-4">
                        <form className="form-base">
                            <div className={classNames("mb-5")}>
                                <label htmlFor="title">Title / Summary</label>
                                <input
                                    type="text"
                                    id="title"
                                    value={issue.title || ""}
                                    disabled
                                />
                            </div>
                            <div className={classNames("mb-5")}>
                                <label htmlFor="description">Description</label>
                                <textarea
                                    id="description"
                                    value={issue.description || ""}
                                    disabled
                                    rows={5}
                                />
                            </div>
                            <div className="mb-5 flex gap-x-2">
                                <div className="flex-auto">
                                    <label htmlFor="severity">Severity</label>
                                    <input
                                        type="text"
                                        id="severity"
                                        value={issue.severity}
                                        disabled
                                    />
                                </div>
                                <div className="flex-auto">
                                    <label htmlFor="status">Status</label>
                                    <input
                                        type="text"
                                        id="status"
                                        value={issue.status}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className={classNames("mb-5")}>
                                <label htmlFor="resolution">Resolution</label>
                                <textarea
                                    id="resolution"
                                    value={issue.resolution || ""}
                                    disabled
                                    rows={5}
                                />
                            </div>
                            <div className="mb-5 flex gap-x-2">
                                <div className="flex-auto">
                                    <label htmlFor="site">Site</label>
                                    <input
                                        type="text"
                                        id="site"
                                        value={issue.site || ""}
                                        disabled
                                    />
                                </div>
                                <div className="flex-auto">
                                    <label htmlFor="location">Location</label>
                                    <input
                                        type="text"
                                        id="location"
                                        value={issue.location || ""}
                                        disabled
                                    />
                                </div>
                                <div className="flex-auto">
                                    <label htmlFor="clientUserRef">
                                        Chime user
                                    </label>
                                    <input
                                        type="text"
                                        id="clientUserRef"
                                        value={issue.clientUserRef || ""}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="mb-5 flex gap-x-2">
                                <div className="flex-auto">
                                    <label htmlFor="raisedAt">Raised at</label>
                                    <input
                                        type="text"
                                        id="raisedAt"
                                        value={
                                            formatDateTime(issue.raisedAt) || ""
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="flex-auto">
                                    <label htmlFor="acknowledgeAt">
                                        Acknowledged at
                                    </label>
                                    <input
                                        type="text"
                                        id="acknowledgeAt"
                                        value={
                                            formatDateTime(
                                                issue.acknowledgeAt
                                            ) || ""
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="flex-auto">
                                    <label htmlFor="resolvedAt">
                                        Resolved at
                                    </label>
                                    <input
                                        type="text"
                                        id="acknowledgeAt"
                                        value={
                                            formatDateTime(issue.resolvedAt) ||
                                            ""
                                        }
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="buttons">
                                <button
                                    className="button light"
                                    type="button"
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    Back
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Box>
        </Content>
    );
}
