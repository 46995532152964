import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { SEVERITY_OPTIONS } from "../../../constants";
import { MUTATION_RAISE_ISSUE } from "../../../graphql/mutations";
import { useLoading } from "../../context/LoadingContext";
import { useUser } from "../../context/UserContext";
import {
    Form,
    InputDropdown,
    InputText,
    InputTextarea,
} from "../../layout/Form";
import { Content, ContentTop } from "../../layout/Content";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import { Box } from "../../layout/Boxes";

type Errors = {
    form?: string;
    title?: string;
    description?: string;
    severity?: string;
    status?: string;
};

interface FormState {
    title: string;
    description: string;
    site: string;
    location: string;
    clientUserRef: string;
    severity: string;
}

export default function PageIssueNew() {
    const { t } = useTranslation();

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        { label: t("Support"), href: "/support" },
        { label: t("Issues"), href: "/support/issues" },
        { label: t("New Issue") },
    ];

    const severityOptions: OptionType[] = SEVERITY_OPTIONS;

    const location = useLocation();
    const navigate = useNavigate();
    const { setLoading } = useLoading();
    const { user } = useUser();

    const [submitFormMutation] = useMutation(MUTATION_RAISE_ISSUE);

    const [formState, setFormState] = useState<FormState>({
        title: "",
        description: "",
        site: "",
        location: "",
        clientUserRef: "",
        severity: "",
    });

    const [errors, setErrors] = useState<Errors>({});

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSeverityChange = (event: OptionType) => {
        const value = event.value;
        setFormState((prevState) => ({
            ...prevState,
            severity: value,
        }));
    };

    const validate = () => {
        const newErrors: Errors = {};

        const query = new URLSearchParams(location.search);
        const novalidate = query.get("novalidate") || false;
        if (novalidate) {
            return newErrors;
        }

        if (!formState.title) {
            newErrors.title = "Title is required!";
        }
        if (!formState.description) {
            newErrors.description = "Description is required!";
        }
        if (!formState.severity) {
            newErrors.severity = "Severity is required!";
        }
        return newErrors;
    };

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        const errors = validate();
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);

        submitFormMutation({
            variables: {
                user: user.email,
                title: formState.title,
                description: formState.description,
                severity: formState.severity,
                site: formState.site,
                location: formState.location,
                clientUserRef: formState.clientUserRef,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                // navigate("/issues"); # does not refresh the page
                window.location.href = "/support/issues";
            })
            .catch((err) => {
                console.error("Mutation error:", err);
                setErrors({ ...errors, form: "Something went wrong!" });
                setLoading(false);
            })
            .finally(() => {
                // setLoading(false); # should load until the end on success
            });
    };

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />

            <ContentTop h1={t("New Issue")} />

            <Box>
                <Form props={{ onSubmit: handleSubmit }} error={errors.form}>
                    <InputText
                        label="Title / Summary"
                        error={errors.title}
                        props={{
                            name: "title",
                            required: true,
                            value: formState.title,
                            onChange: handleInputChange,
                        }}
                    />
                    <InputTextarea
                        label="Description"
                        error={errors.description}
                        props={{
                            name: "description",
                            value: formState.description,
                            required: true,
                            onChange: handleInputChange,
                            rows: 5,
                        }}
                    />
                    <InputDropdown
                        label="Severity"
                        error={errors.severity}
                        props={{
                            defaultValue: severityOptions.find(
                                (o: OptionType) =>
                                    o.value === formState.severity
                            ),
                            required: true,
                            isClearable: false,
                            onChange: handleSeverityChange,
                        }}
                        options={severityOptions}
                    />

                    <div className="flex gap-2">
                        <InputText
                            label="Site"
                            props={{
                                name: "site",
                                value: formState.site,
                                onChange: handleInputChange,
                            }}
                            className="flex-auto"
                        />
                        <InputText
                            label="Location"
                            props={{
                                name: "location",
                                value: formState.location,
                                onChange: handleInputChange,
                            }}
                            className="flex-auto"
                        />
                        <InputText
                            label="Chime user"
                            props={{
                                name: "clientUserRef",
                                value: formState.clientUserRef,
                                onChange: handleInputChange,
                            }}
                            className="flex-auto"
                        />
                    </div>

                    <div className="buttons">
                        <button
                            className="button light"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Back
                        </button>
                        <button className="button info" type="submit">
                            Save
                        </button>
                    </div>
                </Form>
            </Box>
        </Content>
    );
}
