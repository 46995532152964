import { useLocation, useParams } from "react-router-dom";

import PageControllers from "../components/pages/systems/Systems";
import PageSystem from "../components/pages/system/System";
import { QUERY_SYSTEM, QUERY_SYSTEMS } from "../graphql/queries";
import { ErrorComponent } from "../components/pages/Error";
import { useQueryWithParams } from "../graphql/useQueryWithParams";
import { sortPeripherals } from "../helpers/utils";

interface QuerySystemsData {
    systems?: CoreSystems;
}

const SystemsRoute = () => {
    const query = new URLSearchParams(useLocation().search);

    const limit = 50;
    const page = parseInt(query.get("page") || "1", 10);
    const search = query.get("q") || "";

    if (page < 1) {
        throw new Error("Invalid page number");
    }

    const { loading, error, data, refetch } = useQueryWithParams<
        QuerySystemsData,
        { limit: number; offset: number; search: string }
    >({
        query: QUERY_SYSTEMS,
        variables: {
            limit: limit,
            offset: (page - 1) * limit,
            search: search,
        },
        accessScope: "superadmin",
    });

    if (loading) return null;

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    return <PageControllers systems={data.systems} />;
};

interface QuerySystemData {
    system: CoreSystem;
}

const SystemRoute = () => {
    const { macAddress } = useParams<{ macAddress: string }>();

    const { loading, error, data, refetch } = useQueryWithParams<
        QuerySystemData,
        { macAddress: string }
    >({
        query: QUERY_SYSTEM,
        variables: { macAddress: macAddress },
        fetchPolicy: "network-only",
        accessScope: "superadmin",
    });

    if (loading) return null;

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    return (
        <PageSystem system={sortPeripherals(data.system)} refetch={refetch} />
    );
};

export { SystemsRoute, SystemRoute };
