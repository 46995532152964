const Content = ({ children }) => {
    return <div className="flex flex-col text-gray-700 gap-4">{children}</div>;
};

const ContentTop = ({ h1 = null, h2 = null, children = null }) => {
    return (
        <div className="flex justify-between items-center flex-wrap">
            <div className="flex-1">
                {h2 && <h2>{h2}</h2>}
                {h1 && <h1>{h1}</h1>}
            </div>
            {children}
        </div>
    );
};

export { Content, ContentTop };
