import { useRef, useState } from "react";
import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useMutation } from "@apollo/client";

import TailwindForm from "../../../rjsf";
import Modal from "../../../layout/Modal";
import { useLoading } from "../../../context/LoadingContext";
import { MUTATION_LIGHT_NODE } from "../../../../graphql/mutations";

const schema: RJSFSchema = {
    title: "Light - Set Pixels",
    type: "object",
    required: ["start", "count", "colour"],
    properties: {
        start: {
            type: "integer",
            title: "Start",
            default: 0,
        },
        count: {
            type: "integer",
            title: "Count",
        },
        colour: {
            $ref: "#/definitions/RGBColour",
            title: "Colour",
        },
    },
    definitions: {
        RGBColour: {
            title: "Colour",
            examples: ["#FFFF00", "#00FF00", "#FF0000", "#0000FF", "#000000"],
            type: "string",
            pattern: "^#[0-9A-Fa-f]{6}$",
            default: "#00FF00",
        },
    },
};
const uischema = {
    colour: {
        "ui:widget": "color",
    },
};

type ModalNodeLightProps = {
    system: CoreSystem;
    selectedNode: CoreNode;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
};

export default function ModalNodeLight({
    system,
    selectedNode,
    isOpen,
    setIsOpen,
}: ModalNodeLightProps) {
    const formRef = useRef<any>(null);
    const [submitFormMutation] = useMutation(MUTATION_LIGHT_NODE);
    const [formData, setFormData] = useState({});
    const { setLoading } = useLoading();

    if (!system || !selectedNode) {
        return null;
    }

    const handleSubmit = (ev) => {
        setLoading(true);
        submitFormMutation({
            variables: {
                macAddress: system.macAddress,
                address: selectedNode.address,
                start: ev.formData.start,
                count: ev.formData.count,
                colour: ev.formData.colour,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                setFormData(ev.formData);
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeModal = () => setIsOpen(false);

    return (
        <Modal
            children={
                <>
                    <TailwindForm
                        schema={schema}
                        uiSchema={uischema}
                        formData={formData}
                        onSubmit={handleSubmit}
                        validator={validator}
                        noHtml5Validate
                        ref={formRef}
                        children={true}
                        showErrorList={false}
                        focusOnFirstError={true}
                    />
                    <div className="flex flex-row gap-2 justify-end border-t pt-5">
                        <button
                            className="button light"
                            type="button"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="button info"
                            type="button"
                            onClick={() => formRef.current.submit()}
                        >
                            Execute
                        </button>
                    </div>
                </>
            }
            isOpen={isOpen}
            onClose={closeModal}
            size="max-w-md"
        />
    );
}
