import { useLocation } from "react-router-dom";

import { QUERY_USERS } from "../graphql/queries";
import PageUsers from "../components/pages/users/Users";
import { ErrorComponent } from "../components/pages/Error";
import { useQueryWithParams } from "../graphql/useQueryWithParams";

interface QueryUsersData {
    account: {
        users: CoreUsers;
    };
}

const UsersRoute = () => {
    const query = new URLSearchParams(useLocation().search);

    const limit = 100;
    const page = parseInt(query.get("page") || "1", 10);

    if (page < 1) {
        throw new Error("Invalid page number");
    }

    const { loading, error, data, refetch } = useQueryWithParams<
        QueryUsersData,
        { limit: number; offset: number }
    >({
        query: QUERY_USERS,
        variables: { limit: limit, offset: (page - 1) * limit },
        fetchPolicy: "network-only",
    });

    if (loading) return null;

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    return <PageUsers users={data.account.users} refetch={refetch} />;
};

export { UsersRoute };
