import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import "./i18n";
import mixpanel from "mixpanel-browser";

import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { router } from "./routes/routes";
import client from "./graphql/apollo";
import { LoadingProvider } from "./components/context/LoadingContext";

const App = () => {
    // showLoading();

    // useEffect(() => {
    //     const unsubscribe = router.subscribe(({ location }) => {
    //         console.log("Page changed to:", location.pathname);
    //     });

    //     return () => unsubscribe();
    // }, []);

    return (
        <ApolloProvider client={client}>
            <RouterProvider router={router} />
        </ApolloProvider>
    );
};

mixpanel.init("af01356c4f01477bb95f3d4f06ea97e3", {
    debug: true,
    track_pageview: false,
    persistence: "localStorage",
});
window.Mixpanel = mixpanel;

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <LoadingProvider>
            <App />
        </LoadingProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
