import { ApolloQueryResult } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { Box } from "../../layout/Boxes";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import { Content, ContentTop } from "../../layout/Content";
import Pagination from "../../layout/Pagination";
import InviteUser from "./InviteUser";
import { formatDate } from "../../../helpers/utils";
import EditUserButton from "./EditUserForm";
import ResendInviteButton from "./ResendInvite";

type PageUsersProps = {
    users: CoreUsers;
    refetch: () => Promise<ApolloQueryResult<any>>;
};

export default function PageUsers({ users, refetch }: PageUsersProps) {
    const { t } = useTranslation();

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        { label: t("Users") },
    ];

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />

            <ContentTop h1={t("Users")}>
                <div className="border border-stone-300 rounded p-1 buttons mt-0">
                    <InviteUser refetch={refetch} />
                </div>
            </ContentTop>

            <Box>
                <table className="w-full text-gray-700">
                    <thead>
                        <tr>
                            <th>{t("Email")}</th>
                            <th className="text-center">{t("Role")}</th>
                            <th className="text-center hidden md:table-cell">
                                {t("Expiration Date")}
                            </th>
                            <th className="w-48 text-center">{t("Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.edges.map((edge) => (
                            <tr key={edge.node.email}>
                                <td className="max-w-[165px] truncate">
                                    {edge.node.email}
                                </td>
                                <td className="text-center">
                                    {edge.node.role}
                                </td>
                                <td className="text-center hidden md:table-cell">
                                    {formatDate(edge.node.expiresAt)}
                                </td>
                                <td>
                                    <div className="flex justify-center gap-2">
                                        <EditUserButton
                                            user={edge.node}
                                            refetch={refetch}
                                        />
                                        <ResendInviteButton
                                            user={edge.node}
                                            refetch={refetch}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Pagination pageInfo={users.pageInfo} />
            </Box>
        </Content>
    );
}
