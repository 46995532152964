import { Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { languages } from "../../../i18n";

function DropdownLanguages() {
    const { i18n } = useTranslation();

    useEffect(() => {
        console.log("Detected language:", i18n.language);
    }, [i18n.language]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const language = i18n.language.split("-")[0].toUpperCase();

    return (
        <Menu as="div" className="relative">
            <div className="group flex items-center gap-2 hover:cursor-pointer">
                <Menu.Button className="flex">
                    {language}{" "}
                    <HiChevronDown
                        fontSize={20}
                        className="group-hover:opacity-100 opacity-0 transition-opacity duration-150 ease-in-out"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {languages.map((lang) => (
                        <Menu.Item key={lang}>
                            {({ active }) => (
                                <div
                                    onClick={() => changeLanguage(lang)}
                                    className={classNames(
                                        active && "bg-gray-100",
                                        language === lang && "bg-gray-200",
                                        "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                                    )}
                                >
                                    {lang.toUpperCase()}
                                </div>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}

export default DropdownLanguages;
