// // src/i18n.js
// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import detector from "i18next-browser-languagedetector";
// import backend from "i18next-http-backend";

// const languages = ["en", "de", "fr", "es", "it", "ja"];

// i18n.use(backend)
//     .use(initReactI18next)
//     .use(detector)
//     .init({
//         fallbackLng: "en",
//         debug: false,
//         interpolation: {
//             escapeValue: false, // React already safes from xss
//         },
//         backend: {
//             loadPath: "/locales/{{lng}}/{{ns}}.json", // Path to the translation files
//         },
//         // saveMissing: true,
//         // saveMissing: false,
//     });
////////
import i18n from "i18next";
import { initReactI18next, setI18n } from "react-i18next";
import LocizeBackend from "i18next-locize-backend";
import detector from "i18next-browser-languagedetector";

import { isProd } from "./helpers/utils";

const languages = ["fr", "de", "en", "es", "it", "ja"];

if (isProd === false) {
    languages.push("dev");
}

const backendOptions = {
    projectId: "76e4af77-6e7f-475c-8a89-50192b77024d",
    apiKey: "b6918c11-f06a-4e1c-80ee-0ee6a2704065"
};

const options = {
    debug: true,
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
    },
    supportedLngs: languages,
    saveMissing: true,
};

// this is only used if not using suspense
i18n.options.react = options.react;
setI18n(i18n);

const backend = new LocizeBackend(backendOptions, (err, opts) => {
    if (err) return console.error(err);
    i18n.use(backend)
        .use(detector)
        .use(initReactI18next) // keep this if using suspense
        .init({ ...opts, ...options }, (err, t) => {
            if (err) return console.error(err);
        });
});
////////

console.log("Detected language:", i18n.language);

export { languages };
export default i18n;
