import { useEffect, useState, useRef } from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function ErrorComponent({
    title = null,
    description = null,
    error = null,
    refetch = null,
    backButton = false,
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [retryDelay, setRetryDelay] = useState(1000); // Start with 1 second (1000ms)
    const [retryCount, setRetryCount] = useState(0); // Track the number of refetches
    const retryRef = useRef(null); // To keep track of the timeout

    console.log("Error:", error);

    useEffect(() => {
        if (!refetch) {
            return;
        }

        let delay = retryDelay * 2; // Increase delay exponentially
        if (delay > 10000) {
            delay = 0.25 * 1000;
        }

        console.log(
            `Attempt ${retryCount + 1} failed. Retrying in ${delay / 1000}s...`
        );

        retryRef.current = setTimeout(() => {
            refetch();
            setRetryDelay(delay);
            setRetryCount((prevCount) => prevCount + 1); // Increment the retry count
        }, delay);

        // Clear the timeout when component unmounts or refetch changes
        return () => clearTimeout(retryRef.current);
    }, [refetch, retryDelay, retryCount]);

    return (
        <main className="flex  min-h-full justify-center bg-white py-32">
            <div className="text-center">
                <h1 className="text-2xl font-bold text-gray-900 ">
                    {title || "Error"}
                </h1>
                <p className="mt-2 text-base leading-7 text-gray-600">
                    {description || t("LOST_CONNECTION")}
                </p>
                <div className="buttons justify-center">
                    {refetch && (
                        <button
                            onClick={() => {
                                refetch();
                                setRetryDelay(1000); // Reset delay if manually triggered
                                setRetryCount(0); // Reset retry count if manually triggered
                            }}
                            className="button light"
                        >
                            Reload
                        </button>
                    )}
                    {backButton && (
                        <button
                            onClick={() => {
                                navigate(-1);
                            }}
                            className="button light"
                        >
                            Go back
                        </button>
                    )}
                </div>
            </div>
        </main>
    );
}

export function NotFoundPage() {
    return (
        <ErrorComponent
            title="Page not found!"
            description="The page you are looking for does not exist."
            backButton
        />
    );
}

export default function ErrorPage({ title = null, description = null }) {
    const error = useRouteError();

    if (!error) {
        return null;
    }

    if (error.status === 404) {
        return <NotFoundPage />;
    }

    if (error.message === "NOT_ALLOWED") {
        return <NotFoundPage />;
    }

    return (
        <ErrorComponent title={title} error={error} description={description} />
    );
}
