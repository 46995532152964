import { useLoaderData } from "react-router-dom";

import authenticate from "../services/auth";
import { UserProvider, useUser } from "../components/context/UserContext";
import Layout from "../components/layout/Layout";
import { ProjectRoute } from "./projects";
import { SitesRoute } from "./sites";

type LoaderData = {
    user?: User | null;
    completed?: boolean;
};

const rootLoader = async () => {
    const user = await authenticate();

    if (!user) {
        // -> Core whoami not working -> Show loading screen
        return { user: null };
    }

    window.Mixpanel.identify(user.hash);
    window.Mixpanel.people.set({
        $name: user.name,
        $email: user.email,
        Account: user.account_reference,
    });

    localStorage.setItem(
        "user",
        JSON.stringify({
            name: user.name,
            email: user.email,
        })
    );
    window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "febi3ql0",
        name: user.name,
        email: user.email,
        user_hash: user.hash,
    });
    return { user };
};

const RootRoute = () => {
    const { user }: LoaderData = useLoaderData();
    const { setUser } = useUser();

    if (!user) {
        return null;
    }

    setUser(user);

    return (
        <UserProvider initialUser={user}>
            <Layout />
        </UserProvider>
    );
};

const IndexRoute = () => {
    const { user } = useUser();

    if (user.account_reference === "amazon") {
        return <ProjectRoute />;
    }

    return <SitesRoute />;
};

export { IndexRoute, RootRoute, rootLoader };
