import { useRef, useState } from "react";
import { HiOutlineSearch, HiX } from "react-icons/hi";
import { Form, useLocation, useNavigate } from "react-router-dom";

export default function Search() {
    const location = useLocation();
    const navigate = useNavigate();
    const formRef = useRef(null);
    const searchParams = new URLSearchParams(location.search);
    const [q, setQ] = useState(searchParams.get("q") || "");

    const clearQ = () => {
        setQ("");
        // searchParams.delete("q");
        // searchParams.delete("page"); //reset pagination
        // navigate(`${location.pathname}?${searchParams.toString()}`);
        navigate("/systems");
    };

    return (
        <Form
            method="GET"
            // action={location.pathname}
            action="/systems"
            role="search"
            ref={formRef}
        >
            <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ">
                    <HiOutlineSearch
                        fontSize={20}
                        className="text-gray-400 absolute top-1/2 -translate-y-1/2 left-3"
                    />
                </div>
                <input
                    name="q"
                    type="search"
                    id="search"
                    className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                    placeholder="Search"
                    value={q}
                    onChange={(ev) => setQ(ev.target.value)}
                />
                {q && (
                    <button
                        type="button"
                        onClick={clearQ}
                        className="text-gray-400 absolute inset-y-0 right-3 top-1/2 -translate-y-1/2"
                    >
                        <HiX fontSize={20} />
                    </button>
                )}
            </div>
        </Form>
    );
}
