import { useUser } from "../context/UserContext";

const Permission = ({ scopes, children }) => {
    const { hasScope } = useUser();

    if (Array.isArray(scopes)) {
        const hasOneScope = scopes.some((scope) => hasScope(scope));
        return hasOneScope ? children : null;
    }

    return hasScope(scopes) ? children : null;
};

export default Permission;
