import { useMutation } from "@apollo/client";

import Modal from "../../../layout/Modal";
import { useLoading } from "../../../context/LoadingContext";
import { MUTATION_SMARTSTOW_STOP_EVENTS } from "../../../../graphql/mutations";

export default function ModalSmartStowStopEvents({
    system,
    isOpen,
    setIsOpen,
}) {
    const [submitFormMutation] = useMutation(MUTATION_SMARTSTOW_STOP_EVENTS);
    const { setLoading } = useLoading();

    if (!system) {
        return null;
    }

    const handleSubmit = () => {
        setLoading(true);
        submitFormMutation({
            variables: {
                macAddress: system.macAddress,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeModal = () => setIsOpen(false);

    return (
        <Modal
            title="Smart Stow - Stop Events"
            children={
                <>
                    <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-5">
                        <button
                            className="button light"
                            type="button"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="button info"
                            type="button"
                            onClick={handleSubmit}
                        >
                            Execute
                        </button>
                    </div>
                </>
            }
            isOpen={isOpen}
            onClose={closeModal}
            size="max-w-md"
        />
    );
}
