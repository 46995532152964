import { HiCheckCircle, HiXCircle } from "react-icons/hi";

const SensorGrid = ({ sensorStructure }) => (
    <div className="flex flex-row flex-wrap gap-2 mb-4">
        {sensorStructure.map((item, i) => (
            <div className="flex flex-col gap-1 border p-1" key={`col-${i}`}>
                <div className="flex text-xs justify-center">{i + 1}</div>
                <div className="flex flex-row text-xs gap-1">
                    {item.sensors.map((sensor, j) =>
                        sensor.on ? (
                            <HiCheckCircle
                                key={`${i}-${j}`}
                                className="w-5 h-5 text-green-500"
                            />
                        ) : (
                            <HiXCircle
                                key={`${i}-${j}`}
                                className="w-5 h-5 text-gray-500"
                            />
                        )
                    )}
                </div>
            </div>
        ))}
    </div>
);

export default SensorGrid;
