import { useState } from "react";
import { useMutation } from "@apollo/client";

import Modal from "../../../layout/Modal";
import { useLoading } from "../../../context/LoadingContext";
import { Form, InputDropdown, InputText } from "../../../layout/Form";
import { MUTATION_SYSTEM_NODES_FLASH_FIRMWARE } from "../../../../graphql/mutations";

type Errors = {
    form?: string;
    firmware?: string;
    nodes?: string;
};

interface FormState {
    firmware: string;
    nodes: string[];
}

export default function ModalNodeFlashFirmware({
    system,
    isOpen,
    setIsOpen,
    refetch,
}) {
    const [submitFormMutation] = useMutation(
        MUTATION_SYSTEM_NODES_FLASH_FIRMWARE
    );
    const { setLoading } = useLoading();

    const [formState, setFormState] = useState<FormState>({
        firmware: "",
        nodes: [],
    });

    const [errors, setErrors] = useState<Errors>({});

    if (!system) {
        return null;
    }

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDropdownChange = (name: string, event: [OptionType]) => {
        const values = (event || []).map((node) => node.value);
        setFormState((prevState) => ({
            ...prevState,
            [name]: values,
        }));
    };

    const validate = () => {
        const newErrors: Errors = {};

        if (!formState.firmware) {
            newErrors.firmware = "Firmware is required";
        }
        if (!formState.nodes || formState.nodes.length === 0) {
            newErrors.nodes = "Nodes are required";
        }
        return newErrors;
    };

    const handleExecute = () => {
        const errors = validate();
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);

        submitFormMutation({
            variables: {
                macAddress: system.macAddress,
                firmware: formState.firmware,
                nodes: formState.nodes,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                refetch();
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
                setErrors({ ...errors, form: err.message });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeModal = () => setIsOpen(false);

    const getNodeList = (data) => {
        const nodes = [];

        data.forEach((branch) => {
            // Add the branch itself
            nodes.push({ value: branch.address, label: branch.address });

            // Add each node in the branch
            branch.nodes.forEach((node) => {
                nodes.push({ value: node.address, label: node.address });
            });
        });

        return nodes;
    };

    const nodeOptions = getNodeList(system.nodes);

    return (
        <Modal
            children={
                <>
                    <Form error={errors.form}>
                        <InputText
                            label="Firmware"
                            error={errors.firmware}
                            props={{
                                name: "firmware",
                                required: true,
                                value: formState.firmware,
                                onChange: handleInputChange,
                            }}
                        />
                        <InputDropdown
                            label="Nodes"
                            error={errors.nodes}
                            options={nodeOptions}
                            value={formState.nodes}
                            props={{
                                name: "nodes",
                                required: true,
                                isMulti: true,
                                onChange: (ev: [OptionType]) =>
                                    handleDropdownChange("nodes", ev),
                            }}
                        />

                        <div className="buttons justify-end">
                            <button
                                className="button light"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="button info"
                                onClick={handleExecute}
                            >
                                Execute
                            </button>
                        </div>
                    </Form>
                </>
            }
            isOpen={isOpen}
            onClose={closeModal}
            size="max-w-xl"
            title="Flash Firmware"
        />
    );
}
