import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { QUERY_GUIDE_STEPS } from "../../../graphql/queries";
import { useState } from "react";

type ViewGuideProps = {
    uuid: string;
};

const ViewGuide = ({ uuid }: ViewGuideProps) => {
    const { t } = useTranslation();
    const { data, loading } = useQuery(QUERY_GUIDE_STEPS, {
        variables: { uuid },
        skip: !uuid,
    });

    const [stepIndex, setStepIndex] = useState(0);

    if (!uuid) {
        return <div>Please provide a valid UUID</div>;
    }

    if (loading || !data) {
        return <div className="my-4 italic">{t("Loading...")}</div>;
    }

    const { guideSteps } = data.maxilux;

    if (!guideSteps.length) {
        return <div className="my-4 error">Wrong Guide - Missing steps</div>;
    }

    const handleNext = () => {
        if (stepIndex < guideSteps.length - 1) {
            setStepIndex(stepIndex + 1);
        } else {
            setStepIndex(0);
        }
    };

    const handlePrev = () => {
        if (stepIndex > 0) {
            setStepIndex(stepIndex - 1);
        } else {
            setStepIndex(guideSteps.length - 1);
        }
    };

    return (
        <div>
            <div
                className="py-4"
                dangerouslySetInnerHTML={{
                    __html: guideSteps[stepIndex].content,
                }}
            />
            <div className="flex justify-between">
                <div>
                    {stepIndex + 1}/{guideSteps.length}
                </div>
                <div className="flex gap-2">
                    <button className="button light" onClick={handlePrev}>
                        Prev
                    </button>
                    <button className="button light" onClick={handleNext}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ViewGuide;
