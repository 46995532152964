import { useState } from "react";
import { useMutation } from "@apollo/client";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { MUTATION_DEMO_CALIBRATION } from "../../../../graphql/mutations";
import Modal from "../../../layout/Modal";

type VerifySensorsProps = {
    system: CoreSystem;
    selectedNode: CoreNode;
};

export const VerifySensors = ({ system, selectedNode }: VerifySensorsProps) => {
    const { t } = useTranslation();
    const [executeVerifySensors] = useMutation(MUTATION_DEMO_CALIBRATION);

    const [running, setRunning] = useState(false);

    const handleVerifySensors = () => {
        setRunning(true);
        executeVerifySensors({
            variables: {
                macAddress: system.macAddress,
                branch: Number(selectedNode.address),
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                setRunning(false);
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            });
    };

    return (
        <button
            id="btn-aisle-start-verify"
            className={classNames("button info w-32", running ? "light" : "")}
            type="button"
            onClick={handleVerifySensors}
            disabled={running}
        >
            {running ? t("Loading...") : t("Verify")}
        </button>
    );
};

const VerifySensorsModal = ({ system, selectedNode }: VerifySensorsProps) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => setIsModalOpen(false);

    return (
        <>
            <button
                id="btn-aisle-open-verify"
                className="button indigo"
                onClick={() => setIsModalOpen(true)}
            >
                {t("Verify Sensor Order")}
            </button>

            <Modal
                title={t("Verify Sensor Order")}
                isOpen={isModalOpen}
                onClose={closeModal}
                size="max-w-2xl"
                children={
                    <>
                        <div className="mt-4 text-sm">
                            <p>
                                {t(
                                    "Use this tool to verify that the order of the sensors is correct."
                                )}
                            </p>
                            <p>
                                {t(
                                    "After clicking GO, the red sensor lights on the floor will switch off. They will then turn on in sequence, starting from A column."
                                )}
                            </p>
                            <p>
                                {t(
                                    "Once all on, they will then turn off in the same sequence."
                                )}
                            </p>
                            <p>
                                {t(
                                    "Once all off, they will all turn back on after a few seconds."
                                )}
                            </p>
                            <p>
                                {t(
                                    "If the sequence is not correct, re-run the Commissioning Tool for this aisle."
                                )}
                            </p>
                        </div>
                        <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-2">
                            <button
                                className="button light  w-32"
                                type="button"
                                onClick={closeModal}
                            >
                                {t("Close")}
                            </button>
                            <VerifySensors
                                system={system}
                                selectedNode={selectedNode}
                            />
                        </div>
                    </>
                }
            />
        </>
    );
};

const VerifySensorsBtn = ({ system, selectedNode }: VerifySensorsProps) => {
    if (!system) {
        return null;
    }

    if (!system.maintenanceMode) {
        return null;
    }

    if (!selectedNode.commissioned) {
        return null;
    }

    return <VerifySensorsModal system={system} selectedNode={selectedNode} />;
};

export default VerifySensorsBtn;
