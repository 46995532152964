import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Tag } from "../../../layout/Tag";
import { generateColor } from "../../../../helpers/utils";
import { MappedPeripheral } from "./helpers";

export default function PeripheralsTable({ peripherals, className = "" }) {
    const { t } = useTranslation();

    return (
        <table className={classNames("w-full", className)}>
            <thead>
                <tr>
                    <th className="text-center">{t("Column")}</th>
                    <th className="text-center">{t("Sensor")}</th>
                    <th className="text-center">{t("Device Type")}</th>
                    <th className="text-center">{t("Status")}</th>
                    <th className="hidden md:table-cell">{t("Last Update")}</th>
                </tr>
            </thead>
            <tbody>
                {peripherals &&
                    peripherals.map((row: MappedPeripheral, index: number) => {
                        return (
                            <tr
                                key={`sensor-${index}-${row.column}-${row.address}`}
                                className={classNames(
                                    row.disabled ? "disabled" : ""
                                )}
                            >
                                {row.address === "1" && (
                                    <td
                                        className="text-center"
                                        rowSpan={
                                            peripherals.filter(
                                                (r) => r.column === row.column
                                            ).length
                                        }
                                    >
                                        {row.column}
                                    </td>
                                )}
                                {row.address === "-" && (
                                    <td className="text-center">
                                        {row.column}
                                    </td>
                                )}
                                <td className="text-center">{row.address}</td>
                                <td className="text-center">
                                    {row.model && (
                                        <Tag bgColor={generateColor(row.model)}>
                                            {row.model}
                                        </Tag>
                                    )}
                                </td>
                                <td className="text-center">
                                    <Tag bgColor={generateColor(row.status)}>
                                        {t(row.status)}
                                    </Tag>
                                </td>
                                <td className="hidden md:table-cell">
                                    {row.lastUpdate}
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
}
