import { useRef, useState } from "react";
import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useMutation } from "@apollo/client";

import TailwindForm from "../../../rjsf";
import Modal from "../../../layout/Modal";
import { useLoading } from "../../../context/LoadingContext";
import { MUTATION_SMARTSTOW_DISARM_BIN } from "../../../../graphql/mutations";

const schema: RJSFSchema = {
    title: "Smart Stow - Disarm Bin",
    type: "object",
    required: ["binId"],
    properties: {
        binId: {
            type: "string",
            title: "Bin Id",
        },
    },
};
const uischema = {};

export default function ModalSmartStowDisarmBin({ system, isOpen, setIsOpen }) {
    const formRef = useRef<any>(null);
    const [submitFormMutation] = useMutation(MUTATION_SMARTSTOW_DISARM_BIN);
    const [formData, setFormData] = useState({});
    const { setLoading } = useLoading();

    if (!system) {
        return null;
    }

    const handleSubmit = (ev) => {
        setLoading(true);
        submitFormMutation({
            variables: {
                macAddress: system.macAddress,
                binId: ev.formData.binId,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                setFormData(ev.formData);
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeModal = () => setIsOpen(false);

    return (
        <Modal
            children={
                <>
                    <TailwindForm
                        schema={schema}
                        uiSchema={uischema}
                        formData={formData}
                        onSubmit={handleSubmit}
                        validator={validator}
                        noHtml5Validate
                        ref={formRef}
                        children={true}
                        showErrorList={false}
                        focusOnFirstError={true}
                    />
                    <div className="flex flex-row gap-2 justify-end border-t pt-5">
                        <button
                            className="button light"
                            type="button"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="button info"
                            type="button"
                            onClick={() => formRef.current.submit()}
                        >
                            Execute
                        </button>
                    </div>
                </>
            }
            isOpen={isOpen}
            onClose={closeModal}
            size="max-w-md"
        />
    );
}
