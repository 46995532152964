import { useState } from "react";
import classNames from "classnames";
import { HiOutlineChip } from "react-icons/hi";

import ModalRemap from "./modals/ModalRemap";

export default function Remap({ system }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <button
                className={classNames(
                    "flex justify-center items-center gap-2 bg-white text-sm py-2 px-4 border border-sky-600 rounded",
                    "text-sky-600 hover:text-sky-950 hover:border-sky-950"
                )}
                onClick={() => setIsModalOpen(true)}
            >
                <HiOutlineChip fontSize={24} />
                Remap
            </button>

            <ModalRemap
                system={system}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
        </>
    );
}
