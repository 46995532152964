import { useSubscription } from "@apollo/client";
import { useEffect, useRef } from "react";

import { SUBSCRIPTION_CONTROLLER_EVENTS } from "../../../graphql/subscriptions";
import { useUser } from "../../context/UserContext";
import { useConnection } from "../../context/ConnectionContext";

type ClusterEventsProps = {
    system: CoreSystem;
    refetch: () => void;
};

const Events = ({ system, refetch }: ClusterEventsProps) => {
    const { hasScope } = useUser();

    const TIME_TO_WAIT = 2500; // 2.5 seconds
    const lastRefetchTime = useRef(0);
    const timeoutRef = useRef(null);

    const { data, error } = useSubscription(SUBSCRIPTION_CONTROLLER_EVENTS, {
        variables: {
            macAddresses: [system.macAddress],
        },
    });

    useEffect(() => {
        if (data && data.events) {
            const now = Date.now();

            if (hasScope("superadmin")) {
                console.log("Cluster event:", data.events);
            }

            if (now - lastRefetchTime.current > TIME_TO_WAIT) {
                lastRefetchTime.current = now;
                refetch();
            } else {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }

                timeoutRef.current = setTimeout(
                    () => {
                        lastRefetchTime.current = Date.now();
                        refetch();
                    },
                    TIME_TO_WAIT - (now - lastRefetchTime.current)
                );
            }
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [data, hasScope, refetch]);

    if (error) return null;

    return null;
};

export default function ClusterEvents({ system, refetch }: ClusterEventsProps) {
    const { connectionLost } = useConnection();

    if (connectionLost) {
        return null;
    }

    if (system.reachable !== true) {
        return null;
    }
    if (system.maintenanceMode === false && system.commissioned === true) {
        return null;
    }

    return <Events system={system} refetch={refetch} />;
}
