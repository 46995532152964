import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { useLoading } from "../../context/LoadingContext";
import { useUser } from "../../context/UserContext";
import { MUTATION_RESOLVE_ISSUE } from "../../../graphql/mutations";
import { Form, InputTextarea } from "../../layout/Form";

type Errors = {
    form?: string;
    resolution?: string;
};

interface FormState {
    resolution: string;
}

export default function ResolveForm({ closeModal, issue, refetch }) {
    const location = useLocation();
    const { setLoading } = useLoading();
    const { user } = useUser();

    const [submitFormMutation] = useMutation(MUTATION_RESOLVE_ISSUE);

    const [formState, setFormState] = useState<FormState>({
        resolution: "",
    });

    const [errors, setErrors] = useState<Errors>({});

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validate = () => {
        const newErrors: Errors = {};

        const query = new URLSearchParams(location.search);
        const novalidate = query.get("novalidate") || false;
        if (novalidate) {
            return newErrors;
        }

        if (!formState.resolution) {
            newErrors.resolution = "Resolution is required!";
        }
        return newErrors;
    };

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        const errors = validate();
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);

        submitFormMutation({
            variables: {
                number: issue.number,
                resolvedBy: user.email,
                resolution: formState.resolution,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                refetch();
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
                setErrors({ ...errors, form: "Something went wrong!" });
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Form props={{ onSubmit: handleSubmit }} error={errors.form}>
            <h1>Issue #{issue.number}</h1>
            <hr />
            <InputTextarea
                label="Resolution"
                error={errors.resolution}
                props={{
                    name: "resolution",
                    value: formState.resolution,
                    required: true,
                    onChange: handleInputChange,
                    rows: 5,
                }}
            />

            <div className="buttons">
                <button className="button light" onClick={closeModal}>
                    Back
                </button>
                <button className="button info" type="submit">
                    Save
                </button>
            </div>
        </Form>
    );
}
