import { generateColor } from "../../../../helpers/utils";
import { useUser } from "../../../context/UserContext";
import { Tag } from "../../../layout/Tag";
import RefreshAndStatus from "../../system/RefreshAndStatus";
import UpgradeSystemBtn from "../UpgradeSystem";

type AislesSectionBottomProps = {
    system: CoreSystem;
    remapCallback: () => void;
};

export default function AislesSectionBottom({
    system,
    remapCallback,
}: AislesSectionBottomProps) {
    const { hasScope } = useUser();

    if (system.maintenanceMode === false) {
        return null;
    }

    // if (system.commissioned === true) {
    //     return null;
    // }

    const btnRefresh = (
        <RefreshAndStatus
            macAddress={system.macAddress}
            callback={remapCallback}
            label="Refresh"
        />
    );
    const tagUpgrading = (
        <Tag
            className="rounded-md h-7 px-10"
            bgColor={generateColor(system.softwareStatus)}
        >
            Upgrading
        </Tag>
    );
    const btnUpgrade = (
        <UpgradeSystemBtn system={system} refetch={remapCallback} />
    );

    let actions = [];
    if (hasScope("superadmin") || system.softwareStatus === "UP_TO_DATE") {
        actions.push(btnRefresh);
    }
    if (system.softwareStatus === "UPGRADING") {
        actions.push(tagUpgrading);
    } else if (system.softwareStatus !== "UP_TO_DATE") {
        actions.push(btnUpgrade);
    }

    return (
        <div className="flex justify-center gap-2">
            {actions.map((action, index) => (
                <div key={index}>{action}</div>
            ))}
        </div>
    );
}
