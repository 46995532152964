import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi";
import classNames from "classnames";
import { useQuery } from "@apollo/client";

import { QUERY_SITES } from "../../../graphql/queries";

type DropdownSitesProps = {
    siteName: string;
};

export default function DropdownSites({ siteName }: DropdownSitesProps) {
    const navigate = useNavigate();

    const { data } = useQuery(QUERY_SITES, {
        variables: { limit: 50, offset: 0 },
    });

    if (!data) {
        return <div className="text-gray-500 font-bold w-12">Sites</div>;
    }
    const sites: Connection<CoreSite> = data.account.sites;

    return (
        <Menu as="div" className="relative w-12">
            <div className="group flex items-center gap-2 hover:cursor-pointer">
                <Menu.Button className="flex text-gray-500 font-bold">
                    {"Sites"}
                    <HiChevronDown
                        fontSize={20}
                        className="opacity-100 transition-opacity duration-150 ease-in-out"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right z-10 absolute left-0 mt-2 w-32 rounded-sm shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {sites.edges.map(({ node }) => {
                        return (
                            <Menu.Item key={node.reference}>
                                {({ active }) => (
                                    <div
                                        onClick={() =>
                                            navigate(`/sites/${node.reference}`)
                                        }
                                        className={classNames(
                                            active && "bg-gray-100",
                                            siteName === node.name &&
                                                "bg-gray-200",
                                            "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                                        )}
                                    >
                                        {node.name}
                                    </div>
                                )}
                            </Menu.Item>
                        );
                    })}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
