import { redirect } from "react-router-dom";

import PageLogin from "../components/pages/Login";

const loginAction = async ({ request, params }) => {
    return redirect(`/`);
};

const LoginRoute = () => {
    return <PageLogin />;
};

const logoutLoader = async ({ request, params }) => {
    console.log("Logging out...");
    return redirect(`/login`);
};

const logoutAction = ({ request, params }) => {
    console.log("Logging out...");
    return redirect(`/login`);
};

export { loginAction, LoginRoute, logoutAction, logoutLoader };
