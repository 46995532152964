import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";



const COLORS = ["#3B82F6", "#EA4649"]; //bg-red-500

export default function SamplePieHealthScore({ data }) {
    return (
        <div className="w-full h-44 flex flex-col">
            <div className="w-full flex-1 text-sm">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={300}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="45%"
                            innerRadius={50}
                            outerRadius={60}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((_, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
