import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { MUTATION_INVITE_USER } from "../../../graphql/mutations";
import { useLoading } from "../../context/LoadingContext";
import {
    Form,
    InputDate,
    InputDropdown,
    InputSites,
    InputText,
} from "../../layout/Form";

type Errors = {
    form?: string;
    email?: string;
    role?: string;
    sites?: string;
    date?: string;
};

interface FormState {
    email: string;
    sites: string[];
    role: string;
    date: string;
}

type InviteUserFormProps = {
    closeModal: () => void;
    refetch: () => Promise<ApolloQueryResult<any>>;
};

export default function InviteUserForm({
    closeModal,
    refetch,
}: InviteUserFormProps) {
    const { t } = useTranslation();
    const location = useLocation();
    const { setLoading } = useLoading();

    const [submitFormMutation] = useMutation(MUTATION_INVITE_USER);

    const [formState, setFormState] = useState<FormState>({
        email: "",
        sites: [],
        role: "",
        date: "",
    });

    const [errors, setErrors] = useState<Errors>({});

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDropdownChange = (name: string, event: OptionType) => {
        const { value } = event;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSitesChange = (events: OptionType[] = []) => {
        const sites = events.map((site) => site.value);
        setFormState((prevState) => ({
            ...prevState,
            sites: sites,
        }));
    };

    const validate = () => {
        const newErrors: Errors = {};

        const query = new URLSearchParams(location.search);
        const novalidate = query.get("novalidate") || false;
        if (novalidate) {
            return newErrors;
        }

        if (!formState.email) {
            newErrors.email = t("Email is required");
        }
        if (!formState.role) {
            newErrors.role = t("Role is required");
        }
        return newErrors;
    };

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        const errors = validate();
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);

        submitFormMutation({
            variables: {
                email: formState.email,
                sites: formState.sites,
                role: formState.role,
                expiresAt: formState.date || null,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                refetch();
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
                setErrors({ ...errors, form: "Something went wrong!" });
                setLoading(false);
                // refetch();
                // closeModal();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Form props={{ onSubmit: handleSubmit }} error={errors.form}>
            <h1>{t("Invite User")}</h1>
            <hr />
            <InputText
                label={t("Email")}
                error={errors.email}
                props={{
                    name: "email",
                    required: true,
                    value: formState.email,
                    onChange: handleInputChange,
                }}
            />
            <InputDropdown
                label={t("Role")}
                error={errors.role}
                options={[
                    { value: "admin", label: "Admin" },
                    { value: "installer", label: "Installer" },
                    { value: "maintainer", label: "Maintainer" },
                ]}
                value={formState.role}
                props={{
                    name: "role",
                    required: true,
                    onChange: (ev: OptionType) =>
                        handleDropdownChange("role", ev),
                }}
            />
            <InputSites
                label={t("Sites")}
                value={formState.sites}
                error={errors.sites}
                props={{
                    name: "sites",
                    isMulti: true,
                    onChange: handleSitesChange,
                }}
            />
            <InputDate
                label={t("Expiration Date")}
                error={errors.date}
                props={{
                    name: "date",
                    type: "date",
                    value: formState.date,
                    onChange: handleInputChange,
                }}
            />

            <div className="buttons justify-end">
                <button className="button light" onClick={closeModal}>
                    {t("Cancel")}
                </button>
                <button type="submit" className="button info">
                    {t("Invite")}
                </button>
            </div>
        </Form>
    );
}
