import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Trans, useTranslation } from "react-i18next";

import { useLoading } from "../../context/LoadingContext";
import { MUTATION_SYSTEM_MAINTENANCE_MODE } from "../../../graphql/mutations";
import { Toogle } from "../../layout/Toogle";
import Modal from "../../layout/Modal";

type SystemMaintenanceModeProps = {
    system: CoreSystem;
    refetch: () => void;
};

const SystemMaintenanceMode = ({
    system,
    refetch,
}: SystemMaintenanceModeProps) => {
    const { t } = useTranslation();
    const [isMaintenanceOn, setMaintenace] = useState<boolean>(
        system.maintenanceMode
    );

    const { setLoading } = useLoading();
    const [error, setError] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => setIsModalOpen(false);

    const [submitFormMutation] = useMutation(MUTATION_SYSTEM_MAINTENANCE_MODE);

    const handleSystemMaintenace = async () => {
        setLoading(true);
        try {
            await submitFormMutation({
                variables: {
                    macAddress: system.macAddress,
                    state: !isMaintenanceOn,
                },
            });
            setMaintenace(!isMaintenanceOn);
            refetch();
            setError("");
        } catch (error) {
            setError("Something went wrong. Please try again.");
            console.error(error);
        }
        setLoading(false);
        closeModal();
    };

    return (
        <>
            <Toogle
                label={t("System Maintenance Mode")}
                inputElementId="btn-system-maintenance-mode-toggle"
                isChecked={isMaintenanceOn}
                onChange={() => {
                    if (isMaintenanceOn) {
                        // stop maintenance
                        handleSystemMaintenace();
                    } else {
                        // confirm
                        setIsModalOpen(true);
                    }
                }}
                className="mt-4 md:mt-0"
            />
            <Modal
                title="Switch to Maintenance Mode"
                isOpen={isModalOpen}
                onClose={closeModal}
                size="max-w-xl"
                children={
                    <>
                        <div className="mt-4">
                            <Trans>
                                <p>
                                    By turning on maintenance mode you confirm
                                    that you'll have access to functionalities
                                    that may affect site operations.
                                </p>
                                <p>Confirm to proceed?</p>
                            </Trans>
                        </div>
                        {error && <div className="error my-5">{error}</div>}
                        <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-2">
                            <button
                                className="button light w-32"
                                type="button"
                                onClick={closeModal}
                            >
                                {t("Cancel")}
                            </button>
                            <button
                                id="btn-system-confirm-maintenance-mode"
                                className="button info w-32 info"
                                type="button"
                                onClick={handleSystemMaintenace}
                            >
                                {t("Confirm")}
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
};

export default SystemMaintenanceMode;
