import { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import { useTranslation } from "react-i18next";

type QrReaderProps = {
    callbackOnSuccess: (result: QrScanner.ScanResult) => void;
};

const QrReader = ({ callbackOnSuccess }: QrReaderProps) => {
    const { t } = useTranslation();
    // QR States
    const scanner = useRef<QrScanner>();
    const videoEl = useRef<HTMLVideoElement>(null);
    const qrBoxEl = useRef<HTMLDivElement>(null);
    const [qrOn, setQrOn] = useState<boolean>(true);

    // Success
    const onScanSuccess = (result: QrScanner.ScanResult) => {
        console.log(result?.data);
        callbackOnSuccess(result);
        // scanner.current?.stop();
    };

    // Fail
    const onScanFail = (err: string | Error) => {
        console.log(err);
    };

    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
            scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
                onDecodeError: onScanFail,
                preferredCamera: "environment",
                highlightScanRegion: true,
                highlightCodeOutline: true,
                overlay: qrBoxEl?.current || undefined,
                maxScansPerSecond: 6,
            });

            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        return () => {
            if (!videoEl?.current) {
                scanner?.current?.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (!qrOn)
            alert(
                "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
            );
    }, [qrOn]);

    return (
        <div className="qr-reader">
            <p>{t("QR Code Scanner")}</p>
            <div className="bg-gray-50 border border-gray-300 rounded-lg ">
                <video ref={videoEl}></video>
            </div>
        </div>
    );
};

export default QrReader;
