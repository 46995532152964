import { Link } from "react-router-dom";
import classNames from "classnames";

interface TabProps {
    paths: { label: string; href: string }[];
    activeTab?: string;
}

export default function Tabs({ paths, activeTab = null }: TabProps) {
    return (
        <nav aria-label="tab">
            <ol className="flex text-sm gap-3 mb-1">
                {paths.map((path, index) => {
                    let activeClass = "";
                    if (activeTab === path.href) {
                        //tab selected for real
                        activeClass = "border-b-2";
                    } else if (!activeTab && index === 0) {
                        //no tab selected, first tab is default
                        activeClass = "border-b-2";
                    }

                    return (
                        <li key={index} className="">
                            <>
                                <Link
                                    to={path.href}
                                    className={classNames(
                                        "text-gray-700 pb-1 hover:no-underline hover:border-b-2 border-blue-500",
                                        activeClass
                                    )}
                                >
                                    {path.label}
                                </Link>
                            </>
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
}
