import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    HiCheckCircle,
    HiChevronLeft,
    HiChevronRight,
    HiExclamationCircle,
} from "react-icons/hi";

import { Box, Boxes } from "../../layout/Boxes";
import { Tag } from "../../layout/Tag";
import classNames from "classnames";
import { Link } from "react-router-dom";

export default function IssueMonitoringBox({ site }) {
    const { t } = useTranslation();

    const { issues } = site;
    const [activeIndexIssue, setIndexActiveIssue] = useState(0);

    const nextActiveIssue = () => {
        if (activeIndexIssue + 1 === issues.length) {
            setIndexActiveIssue(0);
            return;
        }
        setIndexActiveIssue(activeIndexIssue + 1);
    };
    const prevActiveIssue = () => {
        if (activeIndexIssue - 1 < 0) {
            setIndexActiveIssue(issues.length - 1);
            return;
        }
        setIndexActiveIssue(activeIndexIssue - 1);
    };

    const activeIssue = issues[activeIndexIssue];

    if (!issues || issues.length === 0) {
        return (
            <Box title={t("Issue Monitoring")} className="flex-none">
                <div className="flex flex-col items-center justify-center">
                    <HiCheckCircle className="w-24 h-24 text-green-700" />
                    <div>{t("No issues detected")}</div>
                </div>
            </Box>
        );
    }

    return (
        <Box title={t("Issue Monitoring")} className="flex-none">
            <div className="flex flex-wrap gap-4 flex-col md:flex-row min-h-44">
                <div className="flex-none md:flex-1 flex flex-col items-center justify-center">
                    <div>
                        <HiExclamationCircle className="w-24 h-24 text-red-500" />
                    </div>
                    <div className="text-gray-600">
                        {t(`ISSUE_TYPE_${activeIssue.type}`, {
                            count: activeIssue.entities.length,
                        })}
                    </div>
                    <div className="font-semibold text-gray-600 flex items-center">
                        <button className="button" onClick={prevActiveIssue}>
                            <HiChevronLeft className="h-5 w-5" />
                        </button>
                        <div>
                            {activeIndexIssue + 1} / {issues.length}{" "}
                        </div>
                        <button className="button" onClick={nextActiveIssue}>
                            <HiChevronRight className="h-5 w-5" />
                        </button>
                    </div>
                </div>

                <div className="flex-none md:flex-1 flex items-center">
                    <div className="flex flex-col gap-4 w-full">
                        {activeIssue.attributes.map(
                            (attr: CoreIssueAttribute, index: number) => (
                                <div
                                    className="flex justify-between"
                                    key={`issue-attr-${index}`}
                                >
                                    <div>{t(attr.key)}</div>
                                    <div>
                                        {attr.tagColor ? (
                                            <Tag bgColor={attr.tagColor}>
                                                {attr.value}
                                            </Tag>
                                        ) : (
                                            attr.value
                                        )}
                                    </div>
                                </div>
                            )
                        )}

                        {activeIssue.entities &&
                            activeIssue.entities.length > 0 && (
                                <div className="flex flex-wrap gap-1">
                                    {activeIssue.entities.map(
                                        (
                                            entity: CoreIssueEntity,
                                            index: number
                                        ) => (
                                            <Link
                                                to={`/sites/${entity.siteReference}/${entity.macAddress}`}
                                                key={`issue-entity-${index}`}
                                            >
                                                <Tag bgColor="#F59E0B">
                                                    {entity.clientRef ||
                                                        entity.hostname}
                                                </Tag>
                                            </Link>
                                        )
                                    )}
                                </div>
                            )}

                        <div className="w-full mb-2">
                            <button
                                className={classNames(
                                    "button info w-full troubleshoter",
                                    activeIssue.type
                                )}
                            >
                                {t("Open Troubleshooter")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
}
