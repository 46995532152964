import classNames from "classnames";

interface ToogleProps {
    label?: string;
    inputElementId?: string,
    isChecked: boolean;
    onChange: (isChecked: boolean) => void;
    size?: "small" | "medium" | "large";
    className?: string;
}

const Toogle = ({
    label = "",
    inputElementId = undefined,
    isChecked,
    onChange,
    size = "medium",
    className = "",
}: ToogleProps) => {
    const handleToggleChange = (event) => {
        onChange(event.target.checked);
    };

    let sizeClasses =
        "w-11 h-6 after:top-[2px] after:start-[2px] after:h-5 after:w-5";
    switch (size) {
        case "small":
            sizeClasses =
                "w-9 h-5 after:h-4 after:w-4 after:top-[2px] after:start-[2px]";
            break;
        case "large":
            sizeClasses =
                "w-14 h-7 after:h-6 after:w-6 after:top-0.5 after:start-[4px]";
            break;
    }

    return (
        <label
            className={classNames(
                "relative inline-flex items-center cursor-pointer",
                className
            )}
        >
            <input
                id={inputElementId}
                type="checkbox"
                className="sr-only peer"
                checked={isChecked}
                onChange={handleToggleChange}
            />
            <div
                className={classNames(
                    sizeClasses,
                    "relative bg-gray-200 rounded-full",
                    "after:content-[''] after:absolute after:bg-white after:border-gray-300 after:border after:rounded-full after:transition-all",
                    "peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-blue-600",
                    "peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300"
                )}
            ></div>
            <span className="ms-3 text-sm font-medium text-gray-900">
                {label}
            </span>
        </label>
    );
};

export { Toogle };
