import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import Tabs from "../../layout/Tabs";
import { Box, Boxes } from "../../layout/Boxes";
import { Content, ContentTop } from "../../layout/Content";
import { Tag } from "../../layout/Tag";
import { generateColor } from "../../../helpers/utils";

type PageProjectsProps = {
    projects: Connection<CoreProject>;
};

const PageProjects = ({ projects }: PageProjectsProps) => {
    const { t } = useTranslation();

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        { label: t("Projects") },
    ];
    const tabPaths = [{ label: t("Overview"), href: "#" }];

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />

            <ContentTop h1={t("All Projects")} h2={t("Dashboard")} />

            <Tabs paths={tabPaths} />

            <Boxes>
                {projects.edges.map(({ node }) => (
                    <Box
                        key={node.uuid}
                        title={node.name}
                        className="flex-none w-full md:w-[calc(33.333%-1rem)]"
                    >
                        <div className="flex justify-between py-3">
                            <div>
                                <Tag bgColor={generateColor(node.status)}>
                                    {t(node.status)}
                                </Tag>
                            </div>
                            <div>
                                <Link
                                    className="button light"
                                    to={`/projects/${node.code}`}
                                >
                                    {t("View Project")}
                                </Link>
                            </div>
                        </div>
                    </Box>
                ))}
            </Boxes>
        </Content>
    );
};

export default PageProjects;
