import { useState } from "react";
import { HiOutlineCog } from "react-icons/hi";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { getUiStatus } from "../../../helpers/utils";
import Pagination from "../../layout/Pagination";
import Search from "../../layout/header/Search";
import { Content, ContentTop } from "../../layout/Content";
import { Box } from "../../layout/Boxes";
import Modal from "../../layout/Modal";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import AddSystemForm from "./AddSystemForm";
import { TagHealth } from "../../layout/Tag";
import { LinkAction } from "../../layout/Actions";

export default function PageControllers({ systems }: { systems: CoreSystems }) {
    const { t } = useTranslation();

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        { label: "Systems" },
    ];

    const [isAddSystemModalOpen, setAddSystemModalOpen] = useState(false);
    const closeAddSystemModal = () => setAddSystemModalOpen(false);

    const statusBox = (status: string, reachable: boolean) => {
        const { bgColour } = getUiStatus(status, reachable);

        return (
            <div
                className={classNames("flex w-8 h-8 rounded text-xs", bgColour)}
            ></div>
        );
    };

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />
            <ContentTop h1="Systems">
                <div className="border border-stone-300 rounded p-1 buttons mb-2">
                    <button
                        className="button info"
                        onClick={() => setAddSystemModalOpen(true)}
                    >
                        Add System
                    </button>
                    <Modal
                        children={
                            <AddSystemForm closeModal={closeAddSystemModal} />
                        }
                        isOpen={isAddSystemModalOpen}
                        onClose={closeAddSystemModal}
                        size="max-w-lg"
                    />
                </div>
            </ContentTop>

            <Box>
                <div className="pt-2 w-1/4 mb-2">
                    <Search />
                </div>

                <table className="w-full text-gray-700">
                    <thead>
                        <tr>
                            <th className="w-12"> </th>
                            <th>Mac Address</th>
                            <th>Host</th>
                            <th>Ref</th>
                            <th>Site</th>
                            <th>Health</th>
                            <th>Status</th>
                            <th>Reachable</th>
                            <th className="w-48 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {systems.edges.map((edge) => (
                            <tr key={edge.node.macAddress}>
                                <td>
                                    {statusBox(
                                        edge.node.status,
                                        edge.node.reachable
                                    )}
                                </td>
                                <td>
                                    <Link
                                        to={`/systems/${edge.node.macAddress}`}
                                        title={`System deatils ${edge.node.macAddress}`}
                                    >
                                        {edge.node.macAddress}
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={`/systems/${edge.node.macAddress}`}
                                        title={`System deatils ${edge.node.macAddress}`}
                                    >
                                        {edge.node.hostname}
                                    </Link>
                                </td>
                                <td>{edge.node.clientRef}</td>
                                <td>{edge.node.siteReference}</td>
                                <td>
                                    <TagHealth value={edge.node.healthScore} />
                                </td>
                                <td>{edge.node.status}</td>
                                <td>{edge.node.reachable ? "Yes" : "No"}</td>

                                <td>
                                    <div className="flex justify-center">
                                        <LinkAction
                                            to={`/systems/${edge.node.macAddress}`}
                                            label={`System deatils ${edge.node.macAddress}`}
                                            icon={HiOutlineCog}
                                            color="green"
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Pagination pageInfo={systems.pageInfo} />
            </Box>
        </Content>
    );
}
