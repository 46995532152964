import { Link } from "react-router-dom";
import classNames from "classnames";
import { HiOutlineViewGrid } from "react-icons/hi";

export default function CustomerView({ system }) {
    if (!system.siteReference) {
        return null;
    }
    return (
        <>
            <Link
                to={`/sites/${system.siteReference}/${system.macAddress}`}
                className={classNames(
                    "flex justify-center items-center gap-2 bg-white text-sm py-2 px-4 border border-sky-600 rounded",
                    "text-sky-600 hover:text-sky-950 hover:border-sky-950"
                )}
            >
                <HiOutlineViewGrid fontSize={24} />
                Customer View
            </Link>
        </>
    );
}
