import { gql } from "@apollo/client";

const MUTATION_SYSTEM_SMARTSTOW_SETTINGS = gql`
    mutation SystemSmartStowSettings(
        $macAddress: String!
        $settings: RawJSONObject!
    ) {
        system(macAddress: $macAddress) {
            smartstow {
                settings(settings: $settings)
            }
        }
    }
`;

const MUTATION_SYSTEM_SMARTCART_SETTINGS = gql`
    mutation SystemSmartCartSettings(
        $macAddress: String!
        $settings: RawJSONObject!
    ) {
        system(macAddress: $macAddress) {
            smartcart {
                settings(settings: $settings)
            }
        }
    }
`;

const MUTATION_LIGHT_NODE = gql`
    mutation SystemLightNode(
        $macAddress: String!
        $address: String!
        $start: Int!
        $count: Int!
        $colour: String!
    ) {
        system(macAddress: $macAddress) {
            lightbar {
                setPixels(
                    pixels: {
                        address: $address
                        start: $start
                        count: $count
                        colour: $colour
                    }
                )
            }
        }
    }
`;

const MUTATION_BUZZ_NODE = gql`
    mutation SystemBuzzNode(
        $macAddress: String!
        $address: String!
        $frequencyHz: Int!
        $durationMs: Int!
        $duty: Float!
    ) {
        system(macAddress: $macAddress) {
            buzzer {
                enqueueTones(
                    address: $address
                    tones: {
                        frequencyHz: $frequencyHz
                        durationMs: $durationMs
                        duty: $duty
                    }
                )
            }
        }
    }
`;

const MUTATION_SMARTSTOW_ENABLE_EVENTS = gql`
    mutation SystemSmartStowEnableEvents(
        $macAddress: String!
        $timeoutSeconds: Int!
    ) {
        system(macAddress: $macAddress) {
            smartstow {
                enableStowEvents(timeoutSeconds: $timeoutSeconds)
            }
        }
    }
`;

const MUTATION_SMARTSTOW_STOP_EVENTS = gql`
    mutation SystemSmartStowStopEvents($macAddress: String!) {
        system(macAddress: $macAddress) {
            smartstow {
                stopStowEvents
            }
        }
    }
`;

const MUTATION_SMARTSTOW_ARM_BIN = gql`
    mutation SystemSmartStowArmBin($macAddress: String!, $binId: String!) {
        system(macAddress: $macAddress) {
            smartstow {
                arm(binId: $binId)
            }
        }
    }
`;

const MUTATION_SMARTSTOW_DISARM_BIN = gql`
    mutation SystemSmartStowDisarmBin($macAddress: String!, $binId: String!) {
        system(macAddress: $macAddress) {
            smartstow {
                disarm(binId: $binId)
            }
        }
    }
`;

const MUTATION_REMAP = gql`
    mutation SystemRemap($macAddress: String!) {
        system(macAddress: $macAddress) {
            remap
        }
    }
`;

const MUTATION_REFRESH = gql`
    mutation SystemRefresh($macAddress: String!) {
        system(macAddress: $macAddress) {
            refresh
        }
    }
`;

const MUTATION_INVITE_USER = gql`
    mutation AccountInviteUser(
        $email: String!
        $sites: [String!]!
        $role: String!
        $expiresAt: DateTime
    ) {
        account {
            inviteUser(
                email: $email
                siteReferences: $sites
                role: $role
                expiresAt: $expiresAt
            )
        }
    }
`;

const MUTATION_RESEND_INVITE = gql`
    mutation AccountResendUserInvite($email: String!) {
        account {
            resendInvite(email: $email)
        }
    }
`;

const MUTATION_UPDATE_USER = gql`
    mutation AccountUpdateUser(
        $email: String!
        $sites: [String!]!
        $role: String!
        $expiresAt: DateTime
    ) {
        account {
            updateUser(
                email: $email
                siteReferences: $sites
                role: $role
                expiresAt: $expiresAt
            )
        }
    }
`;

const MUTATION_RAISE_ISSUE = gql`
    mutation OrganisationRaiseIssue(
        $user: String!
        $title: String!
        $description: String
        $severity: IssueSeverityEnum!
        $site: String
        $location: String
        $clientUserRef: String
    ) {
        organisation {
            raiseIssue(
                command: {
                    raisedBy: $user
                    title: $title
                    description: $description
                    severity: $severity
                    site: $site
                    location: $location
                    clientUserRef: $clientUserRef
                }
            )
        }
    }
`;

const MUTATION_RESOLVE_ISSUE = gql`
    mutation OrganisationResolveIssue(
        $number: Int!
        $resolvedBy: String!
        $resolution: String!
    ) {
        organisation {
            resolveIssue(
                command: {
                    number: $number
                    resolvedBy: $resolvedBy
                    resolution: $resolution
                }
            )
        }
    }
`;

const MUTATION_ACCOUNT_ADD_SYSTEM = gql`
    mutation AccountAddSystem(
        $macAddress: String!
        $site: String
        $clientRef: String
    ) {
        account {
            addSystem(
                command: {
                    macAddress: $macAddress
                    site: $site
                    clientRef: $clientRef
                }
            )
        }
    }
`;

const MUTATION_CONFIGURE_SITE = gql`
    mutation AccountConfigureSite(
        $site: String!
        $configNoSystems: Int!
        $configNoAisles: Int!
        $configNoColumns: Int!
        $configNoColumnsOv: Int!
        $configNoSensors: Int!
        $configNoSensorsOv: Int!
    ) {
        account {
            configureSite(
                command: {
                    site: $site
                    configNoSystems: $configNoSystems
                    configNoAisles: $configNoAisles
                    configNoColumns: $configNoColumns
                    configNoColumnsOv: $configNoColumnsOv
                    configNoSensors: $configNoSensors
                    configNoSensorsOv: $configNoSensorsOv
                }
            )
        }
    }
`;

const MUTATION_CONFIGURE_SYSTEM = gql`
    mutation ConfigureSystem(
        $macAddress: String!
        $name: String!
        $configNoAisles: Int!
    ) {
        system(macAddress: $macAddress) {
            configureSystem(
                command: { name: $name, configNoAisles: $configNoAisles }
            )
        }
    }
`;

const MUTATION_CONFIGURE_BRANCH = gql`
    mutation ConfigureBranch(
        $macAddress: String!
        $branch: Int!
        $name: String!
        $configNoColumns: Int!
        $configNoColumnsOv: Int!
        $configNoSensors: Int!
        $configNoSensorsOv: Int!
    ) {
        system(macAddress: $macAddress) {
            configureBranch(
                command: {
                    branch: $branch
                    name: $name
                    configNoColumns: $configNoColumns
                    configNoColumnsOv: $configNoColumnsOv
                    configNoSensors: $configNoSensors
                    configNoSensorsOv: $configNoSensorsOv
                }
            )
        }
    }
`;

const MUTATION_START_CALIBRATION = gql`
    mutation StartCalibration(
        $macAddress: String!
        $correlationId: String!
        $branch: Int!
        $expectedSensorCount: Int!
    ) {
        system(macAddress: $macAddress) {
            commission {
                start(
                    payload: {
                        correlationId: $correlationId
                        branch: $branch
                        expectedSensorCount: $expectedSensorCount
                        minDistanceCm: 70
                        maxDistanceCm: 95
                    }
                )
            }
        }
    }
`;

const MUTATION_DEMO_CALIBRATION = gql`
    mutation DemoCalibration($macAddress: String!, $branch: Int!) {
        system(macAddress: $macAddress) {
            commission {
                demo(payload: { branch: $branch })
            }
        }
    }
`;

const MUTATION_TURN_ON_ALL_SENSORS = gql`
    mutation TurnOnAllSensors($macAddress: String!, $branch: Int!) {
        system(macAddress: $macAddress) {
            commission {
                turnOnAllSensors(payload: { branch: $branch })
            }
        }
    }
`;

const MUTATION_TURN_OFF_ALL_SENSORS = gql`
    mutation TurnOffAllSensors($macAddress: String!, $branch: Int!) {
        system(macAddress: $macAddress) {
            commission {
                turnOffAllSensors(payload: { branch: $branch })
            }
        }
    }
`;

const MUTATION_TURN_OFF_ALL_ONLINE_SENSORS = gql`
    mutation TurnOffAllOnlineSensors($macAddress: String!, $branch: Int!) {
        system(macAddress: $macAddress) {
            commission {
                turnOffAllOnlineSensors(payload: { branch: $branch })
            }
        }
    }
`;

const MUTATION_SYSTEM_MAINTENANCE_MODE = gql`
    mutation SystemMaintenanceMode($macAddress: String!, $state: Boolean!) {
        system(macAddress: $macAddress) {
            maintenance(payload: { state: $state })
        }
    }
`;

const MUTATION_SYSTEM_UPGRADE = gql`
    mutation SystemUpgrade($macAddress: String!) {
        system(macAddress: $macAddress) {
            upgradeSystem
        }
    }
`;

const MUTATION_SYSTEM_NODES_FLASH_FIRMWARE = gql`
    mutation SystemNodesFlashFirmware(
        $macAddress: String!
        $firmware: String!
        $nodes: [String!]!
    ) {
        system(macAddress: $macAddress) {
            flashFirmware(firmware: $firmware, nodes: $nodes)
        }
    }
`;

const MUTATION_ISSUE_ESCALATE = gql`
    mutation IssueEscalate($number: Int!, $email: String!) {
        organisation {
            escalateIssue(command: { number: $number, escalatedBy: $email })
        }
    }
`;

const MUTATION_ISSUE_ADD_RESOLUTION = gql`
    mutation IssueEscalate($number: Int!, $resolutionType: String!, $resolutionNotes: String!) {
        organisation {
            addResolutionNotes(
                command: {
                    number: $number
                    resolutionType: $resolutionType
                    resolutionNotes: $resolutionNotes
                }
            )
        }
    }
`;

export {
    MUTATION_SYSTEM_SMARTSTOW_SETTINGS,
    MUTATION_SYSTEM_SMARTCART_SETTINGS,
    MUTATION_LIGHT_NODE,
    MUTATION_BUZZ_NODE,
    MUTATION_SMARTSTOW_ENABLE_EVENTS,
    MUTATION_SMARTSTOW_STOP_EVENTS,
    MUTATION_SMARTSTOW_ARM_BIN,
    MUTATION_SMARTSTOW_DISARM_BIN,
    MUTATION_REMAP,
    MUTATION_REFRESH,
    MUTATION_INVITE_USER,
    MUTATION_RESEND_INVITE,
    MUTATION_UPDATE_USER,
    MUTATION_RAISE_ISSUE,
    MUTATION_RESOLVE_ISSUE,
    MUTATION_ACCOUNT_ADD_SYSTEM,
    MUTATION_CONFIGURE_SITE,
    MUTATION_CONFIGURE_SYSTEM,
    MUTATION_CONFIGURE_BRANCH,
    MUTATION_START_CALIBRATION,
    MUTATION_DEMO_CALIBRATION,
    MUTATION_TURN_ON_ALL_SENSORS,
    MUTATION_TURN_OFF_ALL_SENSORS,
    MUTATION_TURN_OFF_ALL_ONLINE_SENSORS,
    MUTATION_SYSTEM_MAINTENANCE_MODE,
    MUTATION_SYSTEM_UPGRADE,
    MUTATION_SYSTEM_NODES_FLASH_FIRMWARE,
    MUTATION_ISSUE_ESCALATE,
    MUTATION_ISSUE_ADD_RESOLUTION,
};
