import SmartCartSettings from "./SmartCartSettings";

export default function SystemSmartCart({ system }: { system: CoreSystem }) {
    return (
        <div>
            <h5 className="mb-2 text-xl font-medium ">Smart Cart</h5>

            <hr className="border-t border-muted mb-4" />

            <div className="flex justify-start gap-2">
                <SmartCartSettings system={system} />
            </div>
        </div>
    );
}
