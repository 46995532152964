import { useState, useEffect, useRef } from "react";

export function useCalibrationTimer() {
    const [elapsedTime, setElapsedTime] = useState(0);
    const elapsedTimeIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const startTimeout = (onTimeout: () => void, delay = 45000) => {
        clearTimeout(timeoutId);
        const id = setTimeout(() => {
            onTimeout();
        }, delay);
        setTimeoutId(id);
    };

    const startElapsedTimer = () => {
        if (elapsedTimeIntervalRef.current) return;

        const intervalId = setInterval(() => {
            setElapsedTime((prevTime) => prevTime + 1);
        }, 1000);

        elapsedTimeIntervalRef.current = intervalId;
    };

    const stopTimers = () => {
        setElapsedTime(0);
        if (elapsedTimeIntervalRef.current) {
            clearInterval(elapsedTimeIntervalRef.current);
            elapsedTimeIntervalRef.current = null;
        }
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
    };

    useEffect(() => {
        return () => {
            stopTimers();
        };
    }, []);

    return { startTimeout, startElapsedTimer, stopTimers, elapsedTime };
}
