const Instructions = () => (
    <div className="text-sm mb-5 mt-5">
        <b>Commissioning Process:</b>
        <ol className="ml-4 mt-2 space-y-3">
            <li>
                1. Click the <strong>Start commissioning</strong> button below.
                <ol className="ml-10 list-disc">
                    <li>
                        Wait until <strong>Running</strong> is displayed.
                    </li>
                    <li>
                        Note: The initialisation process can take up to 20
                        seconds.
                    </li>
                </ol>
            </li>
            <li>
                2. Trigger each sensor in turn:
                <ol className="ml-10 list-disc">
                    <li>Start at the conveyor end of the aisle.</li>
                    <li>
                        Trigger each sensor by moving your hand across the laser
                        beam near the top of bag 3.
                    </li>
                    <li>
                        The red beam will turn off once the sensor has been
                        triggered
                    </li>
                    <li>Repeat for each sensor</li>
                </ol>
            </li>
            <li>
                3: The process is complete once all sensors have turned off.
                When you see <strong>Completed</strong> below you can close the
                window.
            </li>
            <li>
                If the number of sensors detected does not match the aisle
                configuration you will get an error. Check all sensors and try
                again.
            </li>
        </ol>
    </div>
);

export default Instructions;
