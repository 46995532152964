import { useState } from "react";
import classNames from "classnames";
import { HiOutlineBell } from "react-icons/hi";

import ModalNodeBuzz from "./modals/ModalNodeBuzz";

type NodeBuzzProps = {
    system: CoreSystem;
    selectedNode: CoreNode | null;
};

export default function NodeBuzz({ system, selectedNode }: NodeBuzzProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    if (!selectedNode) {
        return null;
    }

    return (
        <>
            <button
                className={classNames(
                    "flex justify-center items-center gap-2 bg-white text-sm py-2 px-4 border border-gray-400 rounded",
                    selectedNode
                        ? "text-sky-500 hover:text-sky-700"
                        : "text-gray-400"
                )}
                onClick={() => setIsModalOpen(true)}
            >
                <HiOutlineBell fontSize={24} />
                Buzz
            </button>

            <ModalNodeBuzz
                system={system}
                selectedNode={selectedNode}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
        </>
    );
}
