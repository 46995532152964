import { useParams } from "react-router-dom";

import { QUERY_CLUSTER, QUERY_SITE, QUERY_SITES } from "../graphql/queries";
import PageSite from "../components/pages/site/Site";
import PageSites from "../components/pages/sites/Sites";
import PageCluster from "../components/pages/cluster/Cluster";
import { ErrorComponent, NotFoundPage } from "../components/pages/Error";
import { useQueryWithParams } from "../graphql/useQueryWithParams";
import { filteredSystem } from "../helpers/utils";

interface QuerySitesData {
    account: {
        sites: Connection<CoreSite>;
    };
}

const SitesRoute = () => {
    const { loading, error, data, refetch } = useQueryWithParams<
        QuerySitesData,
        { limit: number; offset: number }
    >({ query: QUERY_SITES, variables: { limit: 50, offset: 0 } });

    if (loading) return null;

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    return <PageSites sites={data.account.sites} />;
};

interface QuerySiteData {
    account: {
        site: CoreSite;
    };
    systems: Connection<CoreSystem>;
    organisation: {
        issuesv2: Connection<CoreIssue>;
    };
}

const SiteRoute = () => {
    const { code } = useParams<{ code: string }>();

    const { loading, error, data, refetch } = useQueryWithParams<
        QuerySiteData,
        { code: string }
    >({ query: QUERY_SITE, variables: { code }, fetchPolicy: "network-only" });

    if (loading) return null;

    if (!data.account.site) {
        return <NotFoundPage />;
    }

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    return (
        <PageSite
            site={data.account.site}
            systems={data.systems}
            issues={data.organisation.issuesv2}
            refetch={refetch}
        />
    );
};

interface QueryClusterData {
    account: {
        site: CoreSite;
    };
    system: CoreSystem;
}

const ClusterRoute = () => {
    const { site, macAddress } = useParams<{
        site: string;
        macAddress: string;
    }>();

    const { loading, error, data, refetch } = useQueryWithParams<
        QueryClusterData,
        { site: string; macAddress: string }
    >({
        query: QUERY_CLUSTER,
        variables: { site, macAddress },
        fetchPolicy: "network-only",
    });

    if (loading) return null;

    if (error && error.message === "System does not belong to account.") {
        return <NotFoundPage />;
    }

    if (!data.system) {
        return <NotFoundPage />;
    }

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    return (
        <PageCluster
            system={filteredSystem(data.system)}
            site={data.account.site}
            refetch={refetch}
        />
    );
};

export { SitesRoute, SiteRoute, ClusterRoute };
