import { useState } from "react";
import { ApolloQueryResult } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Modal from "../../layout/Modal";
import InviteUserForm from "./InviteUserForm";

type InviteUserProps = {
    refetch: () => Promise<ApolloQueryResult<any>>;
};

export default function InviteUser({ refetch }: InviteUserProps) {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => setIsModalOpen(false);
    return (
        <>
            <button
                className="button info"
                onClick={() => setIsModalOpen(true)}
            >
                {t("Invite User")}
            </button>
            <Modal
                children={
                    <InviteUserForm closeModal={closeModal} refetch={refetch} />
                }
                isOpen={isModalOpen}
                onClose={closeModal}
                size="max-w-md"
            />
        </>
    );
}
