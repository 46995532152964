import React from "react";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { MUTATION_INVITE_USER } from "../../../graphql/mutations";
import { useLoading } from "../../context/LoadingContext";
import { Form, InputSites, InputText } from "../../layout/Form";

type Errors = {
    macAddress?: string;
    site?: string;
};

interface FormState {
    macAddress: string;
    site: string;
}

export default function AddSystemForm({ closeModal }) {
    const { t } = useTranslation();
    const { setLoading } = useLoading();

    const [submitFormMutation] = useMutation(MUTATION_INVITE_USER);

    const [formState, setFormState] = useState<FormState>({
        macAddress: "",
        site: "",
    });

    const [errors, setErrors] = useState<Errors>({});

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSiteChange = (event: OptionType) => {
        const { value } = event;

        let state = formState;
        state["site"] = value;

        setFormState(state);
    };

    const validate = () => {
        const newErrors: Errors = {};
        if (!formState.macAddress) {
            newErrors.macAddress = t("Mac Address is required");
        }
        if (!formState.site) {
            newErrors.site = t("Site is required");
        }
        return newErrors;
    };

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        const errors = validate();
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);

        submitFormMutation({
            variables: {
                macAddress: formState.macAddress,
                site: formState.site,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Form props={{ onSubmit: handleSubmit }}>
            <h1>{t("Add System")}</h1>
            <hr />
            <InputText
                label={t("Mac Address")}
                error={errors.macAddress}
                props={{
                    name: "macAddress",
                    required: true,
                    value: formState.macAddress,
                    onChange: handleInputChange,
                }}
            />
            <InputSites
                label={t("Site")}
                error={errors.site}
                props={{
                    name: "site",
                    required: true,
                    onChange: handleSiteChange,
                }}
            />

            <div className="buttons justify-end">
                <button className="button light" onClick={closeModal}>
                    {t("Cancel")}
                </button>
                <button type="submit" className="button info">
                    {t("Save")}
                </button>
            </div>
        </Form>
    );
}
