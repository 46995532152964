import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiCheckCircle, HiExclamationCircle } from "react-icons/hi";
import { MdOutlineCloudOff } from "react-icons/md";

import Breadcrumbs from "../layout/Breadcrumbs/Breadcrumbs";
import Tabs from "../layout/Tabs";
import SampleChart from "../samples/SampleChart";
import SamplePieHealthScore from "../samples/SamplePieHealthScore";
import { Tag } from "../layout/Tag";
import { Box, Boxes, Grid } from "../layout/Boxes";
import { Content, ContentTop } from "../layout/Content";
import { Toogle } from "../layout/Toogle";
import { useTranslation } from "react-i18next";
import {
    Form,
    InputColor,
    InputDate,
    InputDropdown,
    InputSites,
    InputText,
    InputTextarea,
} from "../layout/Form";
import classNames from "classnames";
import PageDashboard from "./dashboard/Dashboard";
import Tooltip from "../layout/Tooltip";
import Modal from "../layout/Modal";

const Issues = ({ boxes }) => {
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const closeEditModal = () => setEditModalOpen(false);

    return (
        <div className="flex flex-wrap">
            {boxes.map((box, index) => (
                <div
                    key={index}
                    className={`${boxes.length === 1 ? "w-full" : "w-full sm:w-1/2"} p-2`}
                    style={{ height: "150px" }}
                >
                    <div className="flex h-full border border-red-600 rounded bg-white">
                        <div className=" w-[100px] flex items-center justify-center">
                            <MdOutlineCloudOff className="text-red-600 font-extrabold text-4xl" />
                        </div>
                        <div className="flex flex-col gap-3 justify-center w-full p-4">
                            <div>
                                <strong>Aisle Controller Offline</strong>
                            </div>
                            <div>
                                Part of your system is unreachable and needs
                                troubleshooting.
                            </div>
                            <div className="flex justify-end">
                                <button
                                    className="btn danger"
                                    onClick={() => setEditModalOpen(true)}
                                >
                                    FIX ISSUE
                                </button>

                                {isEditModalOpen && (
                                    <Modal
                                        title={"Fix Issue"}
                                        children={
                                            <>
                                                <div className="flex my-3">
                                                    <div className=" w-[100px] flex items-center justify-center">
                                                        <MdOutlineCloudOff className="text-red-600 font-extrabold text-4xl" />
                                                    </div>
                                                    <div className="flex justify-between w-full">
                                                        <div className="flex-1 p-2">
                                                            <div className="my-2">
                                                                Device type:{" "}
                                                                <strong>
                                                                    Aisle
                                                                    Controller
                                                                </strong>
                                                            </div>
                                                            <div className="my-2">
                                                                Last seen:{" "}
                                                                <strong>
                                                                    28m ago
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 p-2">
                                                            <div className="my-2">
                                                                Location:{" "}
                                                                <strong>
                                                                    B23
                                                                </strong>
                                                            </div>
                                                            <div className="my-2">
                                                                Devices
                                                                affected:{" "}
                                                                <strong>
                                                                    20
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-end gap-4">
                                                    <button className="btn danger">
                                                        ESCALTE TO SEV1
                                                    </button>
                                                    <button className="btn danger">
                                                        OPEN TROUBLESHOOTER
                                                    </button>
                                                </div>
                                            </>
                                        }
                                        isOpen={isEditModalOpen}
                                        onClose={closeEditModal}
                                        size="max-w-2xl"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default function PageTemplates() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        { label: t("Projects"), href: "/projects" },
        { label: t("Scanless"), href: "#" },
        { label: t("DBI7"), href: "#" },
    ];
    const tabPaths = [
        { label: t("Overview"), href: "#" },
        { label: t("Historic Data"), href: "#history" },
        { label: t("Site Info"), href: "#info" },
    ];

    const boxes = ["Box 1", "Box 2", "Box 3", "Box 4", "Box 5"];

    const [isToggleChecked, handleToggleChange] = useState(false);

    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.hash || "");

    useEffect(() => {
        setActiveTab(location.hash || "");
    }, [location]);

    return (
        <>
            <Content>
                <Breadcrumbs items={breadcrumbPaths} />

                <ContentTop h1={t("Dashboard")} h2={t("DBI7")}>
                    {/* <div className="border border-stone-300 rounded p-1 buttons mb-2">
                    <button className="button indigo w-36">Status</button>
                    <button className="button light w-36">Configuration</button>
                </div> */}
                </ContentTop>

                <Tabs paths={tabPaths} activeTab={activeTab} />

                <Boxes className={classNames(activeTab === "" ? "" : "hidden")}>
                    <Box>{t("Overview Box")}</Box>
                </Boxes>

                <Boxes
                    className={classNames(
                        activeTab === "#history" ? "" : "hidden"
                    )}
                >
                    <Box>{t("Historic Data Box")}</Box>
                </Boxes>

                <Boxes
                    className={classNames(
                        activeTab === "#info" ? "" : "hidden"
                    )}
                >
                    <Box>{t("Site Info Box")}</Box>
                </Boxes>

                {/* From Poject */}

                <Issues boxes={boxes} />

                <Boxes>
                    <Boxes className="flex-1">
                        <Box
                            className="flex-none w-full md:flex-1"
                            title={t("Health Score 1")}
                            bottom={
                                <div className="flex">
                                    <div className="w-1/2">Last 24h</div>
                                    <div className="w-1/2">1294 devices</div>
                                </div>
                            }
                        >
                            <div className="flex items-center justify-center">
                                <SamplePieHealthScore
                                    data={[
                                        { name: "90%", value: 90 },
                                        { name: "10%", value: 10 },
                                    ]}
                                />
                            </div>
                        </Box>
                        <Box
                            title={"Key Info"}
                            bottom={`Last Updated: few minutes ago`}
                        >
                            <div className="flex flex-col gap-4 mt-4">
                                <Tag
                                    className="text-red-500"
                                    icon={HiExclamationCircle}
                                >
                                    1 issues detected
                                </Tag>
                                <Tag
                                    className="text-green-700"
                                    icon={HiCheckCircle}
                                >
                                    All software is up to date
                                </Tag>
                            </div>
                        </Box>
                    </Boxes>
                    <Box title={t("Issue Monitoring")}>
                        <Boxes>
                            <div className="flex-none md:flex-1 flex flex-col items-center justify-center h-full">
                                <div className="mt-7">
                                    <HiExclamationCircle className="w-24 h-24 text-red-500" />
                                </div>
                                <div className="font-semibold">
                                    1 issue identified
                                </div>
                            </div>
                            <div className="flex-none md:flex-1 flex h-full items-center">
                                <div className="flex flex-col gap-4 w-full mt-4">
                                    <div className="flex justify-between">
                                        <div>Devices affected</div>
                                        <div>
                                            <Tag className="warning">524</Tag>
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div>Time since detection</div>
                                        <div>
                                            <Tag className="indigo">10m</Tag>
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div>Raised by</div>
                                        <div>
                                            <Tag className="bg-red-300">
                                                System
                                            </Tag>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <Link
                                            to="/support"
                                            className="button info w-full block text-center"
                                        >
                                            Open Troubleshooter
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Boxes>
                    </Box>
                </Boxes>

                {/* Second row of boxes */}

                <Boxes>
                    <Boxes className="flex-1">
                        <Box title={"System Usage"}>
                            <SampleChart />
                        </Box>
                    </Boxes>
                    <Box title={"Systems"}>
                        <table className="table-auto w-full my-3">
                            <thead>
                                <tr>
                                    <th>Aisle</th>
                                    <th>Hostname</th>
                                    <th>Health Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Link to="/clusters/e4:5f:01:06:78:fa">
                                            A1-A14
                                        </Link>
                                    </td>
                                    <td>maxsys-ct-0278</td>
                                    <td>
                                        <Tag
                                            className="success"
                                            icon={HiCheckCircle}
                                        >
                                            No Issues
                                        </Tag>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Link to="/clusters/e4:5f:01:06:78:fa">
                                            A15-A27
                                        </Link>
                                    </td>
                                    <td>maxsys-ct-0297</td>
                                    <td>
                                        <Tag
                                            className="danger"
                                            icon={HiExclamationCircle}
                                        >
                                            1 issues detected
                                        </Tag>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Link to="/clusters/e4:5f:01:06:78:fa">
                                            A29-A52
                                        </Link>
                                    </td>
                                    <td>maxsys-ct-0295</td>
                                    <td>
                                        <Tag
                                            className="success"
                                            icon={HiCheckCircle}
                                        >
                                            No Issues
                                        </Tag>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                </Boxes>
                <Box title="Tags">
                    <div className="flex gap-4 my-3">
                        <Tag className="danger" icon={HiExclamationCircle}>
                            1 issues detected
                        </Tag>
                        <Tag className="success" icon={HiCheckCircle}>
                            All software is up to date
                        </Tag>
                        <Tag className="indigo">Text + class</Tag>
                        <Tag className="text-green-700" icon={HiCheckCircle}>
                            Icon + Text
                        </Tag>
                        <Tag bgColor="#1e3f62">Custom color</Tag>
                    </div>
                </Box>

                <Form className="px-4">
                    <InputText
                        label="Base input"
                        props={{
                            name: "base-input",
                            required: true,
                            placeholder: "Base input",
                            value: "Base input",
                            onChange: () => {},
                        }}
                    />

                    <InputText
                        label="Error input"
                        error="Oops! Error message!"
                        props={{ name: "error-input", required: true }}
                    />
                    <InputDropdown
                        label="Dropdown - Error"
                        props={{ name: "Dropdown-error", isClearable: true }}
                        error="Oops! Error message!"
                        options={[
                            { label: "Option 1", value: "1" },
                            { label: "Option 2", value: "2" },
                            { label: "Option 3", value: "3" },
                        ]}
                    />

                    <div className="flex flex-wrap gap-2">
                        <InputDate
                            label="Date Input"
                            props={{ name: "Date-input", type: "date" }}
                            className="w-full md:w-1/5"
                        />
                        <InputDate
                            label="Datetime Input"
                            props={{ name: "Datetime-input" }}
                            className="w-full md:w-1/5"
                        />
                    </div>

                    <InputColor
                        label="Color input"
                        props={{
                            value: "#22C55E",
                            required: true,
                            name: "color-input",
                            onChange: () => {},
                        }}
                    />

                    <div className="flex flex-wrap gap-2 ">
                        <InputDropdown
                            label="Dropdown - Simple"
                            props={{
                                name: "Dropdown-Simple",
                                isClearable: true,
                            }}
                            options={[
                                { label: "Option 1", value: "1" },
                                { label: "Option 2", value: "2" },
                                { label: "Option 3", value: "3" },
                            ]}
                            className="w-full md:flex-1"
                        />
                        <InputDropdown
                            label="Dropdown - Multiselect"
                            props={{
                                name: "Dropdown-Multiselect",
                                isMulti: true,
                                defaultValue: [
                                    { label: "Option 2", value: "2" },
                                    { label: "Option 3", value: "3" },
                                ],
                            }}
                            options={[
                                { label: "Option 1", value: "1" },
                                { label: "Option 2", value: "2" },
                                { label: "Option 3", value: "3" },
                            ]}
                            className="w-full md:flex-1"
                        />
                    </div>

                    <div className="flex flex-wrap gap-2 ">
                        <InputSites
                            label="Sites - Simple"
                            props={{
                                name: "InputSites-Simple",
                                isClearable: true,
                            }}
                            className="w-full md:flex-1"
                        />
                        <InputSites
                            label="Sites - Multiselect"
                            props={{
                                name: "InputSites-Multiselect",
                                isMulti: true,
                            }}
                            className="w-full md:flex-1"
                        />
                    </div>

                    <InputTextarea
                        label="Textarea"
                        props={{ name: "Textarea" }}
                    />

                    <div className="flex flex-wrap gap-2 ">
                        <InputTextarea
                            label="Column 1"
                            className="flex-auto"
                            props={{
                                name: "Column1",
                                required: true,
                                value: "Value",
                                onChange: () => {},
                            }}
                        />
                        <InputTextarea
                            label="Column 2"
                            props={{ name: "Column2" }}
                            className="flex-auto"
                        />
                    </div>

                    <div className="buttons flex-wrap">
                        <button type="submit" className="button info">
                            Info
                        </button>
                        <button
                            className="button light"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Light
                        </button>

                        <button
                            className="button indigo"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Indigo
                        </button>
                        <button className="button warning">Warning</button>
                        <button className="button danger">Danger</button>
                        <button className="button success">Success</button>
                        <button className="button link">Link</button>
                    </div>
                </Form>

                <Box title={"Links Button Style"}>
                    <div className="buttons flex-wrap">
                        <Link to="#" className="button info">
                            Info
                        </Link>
                        <Link to="#" className="button light">
                            Light
                        </Link>
                        <Link to="#" className="button indigo">
                            Indigo
                        </Link>
                        <Link to="#" className="button warning">
                            Warning
                        </Link>
                        <Link to="#" className="button danger">
                            Danger
                        </Link>
                        <Link to="#" className="button success">
                            Success
                        </Link>
                        <Link to="#">Link</Link>
                    </div>
                </Box>

                <Grid cols={2}>
                    <Box title="Toogle">
                        <Toogle
                            label="Toggle (Small)"
                            isChecked={isToggleChecked}
                            onChange={handleToggleChange}
                            size="small"
                        />
                        <Toogle
                            label="Toggle (Default)"
                            isChecked={isToggleChecked}
                            onChange={handleToggleChange}
                            className="mx-3"
                        />
                        <Toogle
                            label="Toggle (Large)"
                            isChecked={isToggleChecked}
                            onChange={handleToggleChange}
                            size="large"
                        />
                    </Box>
                    <Box title="Toogle Value">
                        <p>
                            The toggle is{" "}
                            {isToggleChecked ? "checked" : "unchecked"}.
                        </p>
                    </Box>
                </Grid>
            </Content>

            <PageDashboard />

            <div>
                <Tooltip text="Tooltip text">
                    <button>Hover me</button>
                </Tooltip>
            </div>
        </>
    );
}
