import { gql } from "@apollo/client";

const SUBSCRIPTION_CONTROLLER_EVENTS = gql`
    subscription ControllerEventsSubscription($macAddresses: [String!]) {
        events(macAddresses: $macAddresses) {
            id
            kind
            payload
            correlationId
        }
    }
`;

const SUBSCRIPTION_CONTROLLER_EVENTS_WITH_CORRELATION_ID = gql`
    subscription ControllerEventsSubscription(
        $macAddresses: [String!]
        $correlationId: String
    ) {
        events(macAddresses: $macAddresses, correlationId: $correlationId) {
            id
            kind
            payload
            correlationId
        }
    }
`;

export { SUBSCRIPTION_CONTROLLER_EVENTS, SUBSCRIPTION_CONTROLLER_EVENTS_WITH_CORRELATION_ID };
