import { useTranslation } from "react-i18next";

import ControllerEvents from "./Events";
import SystemHeader from "./Header";
import SystemNodes from "./Nodes";
import SystemSmartCart from "./SmartCart";
import SystemSmartStow from "./SmartStow";
import SystemActions from "./Actions";
import { Content } from "../../layout/Content";
import { Box } from "../../layout/Boxes";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import SystemDockers from "./Dockers";
import { useEffect } from "react";

type PageSystemProps = {
    system: CoreSystem;
    refetch: () => void;
};

export default function PageSystem({ system, refetch }: PageSystemProps) {
    const { t } = useTranslation();

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        ...(system.siteReference
            ? [
                  {
                      label: system.siteReference.toUpperCase(),
                      href: `/sites/${system.siteReference}`,
                  },
              ]
            : []),
        { label: system.clientRef || system.hostname },
    ];

    useEffect(() => {
        const intervalId = setInterval(async () => {
            refetch();
        }, 1000 * 5);

        return () => clearInterval(intervalId);
    }, [refetch]);

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />

            <Box>
                <SystemHeader system={system} />
            </Box>

            <Box>
                <SystemActions system={system} />
            </Box>

            {system.nodes && system.nodes.length > 0 && (
                <Box>
                    <SystemNodes system={system} refetch={refetch} />
                </Box>
            )}

            <Box>
                <SystemDockers system={system} />
            </Box>

            <Box>
                <SystemSmartStow system={system} />
            </Box>

            <Box>
                <SystemSmartCart system={system} />
            </Box>

            <Box>
                <ControllerEvents system={system} refetch={refetch} />
            </Box>
        </Content>
    );
}
