import SmartStowSettings from "./SmartStowSettings";
import SmartStowEnableEvents from "./SmartStowEnableEvents";
import SmartStowStopEvents from "./SmartStowStopEvents";
import SmartStowArmBin from "./SmartStowArmBin";
import SmartStowDisarmBin from "./SmartStowDisarmBin";

export default function SystemSmartStow({ system }: { system: CoreSystem }) {
    return (
        <div>
            <h5 className="mb-2 text-xl font-medium ">Smart Stow</h5>

            <hr className="border-t border-muted mb-4" />

            <div className="flex justify-start gap-2">
                <SmartStowEnableEvents system={system} />
                <SmartStowStopEvents system={system} />
                <SmartStowSettings system={system} />
                <SmartStowArmBin system={system} />
                <SmartStowDisarmBin system={system} />
            </div>
        </div>
    );
}
