import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import Tabs from "../../layout/Tabs";
import { Box, Boxes } from "../../layout/Boxes";
import { Content, ContentTop } from "../../layout/Content";
import { Tag, TagHealth } from "../../layout/Tag";
import { generateColor } from "../../../helpers/utils";
import { HiCheckCircle, HiExclamationCircle } from "react-icons/hi";
import SamplePieHealthScore from "../../samples/SamplePieHealthScore";
import IssueMonitoringBox from "./IssueMonitoring";
import { useUser } from "../../context/UserContext";

type PageProjectProps = {
    project: CoreProject;
};

const PageProject = ({ project }: PageProjectProps) => {
    const { hasScope } = useUser();
    const { t } = useTranslation();
    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        // { label: "Projects", href: "/projects" },
        { label: project.name },
    ];
    const tabPaths = [
        { label: t("Overview"), href: "#" },
        // { label: t("Resources"), href: "/support" },
    ];

    const issueCount = useMemo(() => {
        return project.sites.reduce((acc, site) => acc + site.issues.length, 0);
    }, [project.sites]);

    const [show] = useState(false);

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />

            <ContentTop h1={project.name} h2={t("Dashboard")} />

            <Tabs paths={tabPaths} />

            {show && (
                <div className="flex flex-wrap lg:flex-nowrap lg:gap-4">
                    <div className="w-1/2 order-1 lg:w-1/4 lg:order-1 pr-1 md:pr-0">
                        <Box
                            className="flex-none w-full md:flex-1"
                            title={t("Health Score")}
                            bottom={
                                <div className="text-center">
                                    {t("Last 24h")}
                                </div>
                            }
                        >
                            <div className="flex items-center justify-center">
                                <SamplePieHealthScore
                                    data={[
                                        {
                                            name: `${project.health}%`,
                                            value: project.health,
                                        },
                                        {
                                            name: `${100 - project.health}%`,
                                            value: 100 - project.health,
                                        },
                                    ]}
                                />
                            </div>
                        </Box>
                    </div>
                    <div className="w-full order-3 lg:w-1/2 lg:order-2">
                        <IssueMonitoringBox project={project} />
                    </div>
                    <div className="w-1/2 order-2 lg:w-1/4 lg:order-3 pl-1 md:pl-0">
                        <Box
                            title={t("Key Info")}
                            bottom={t("Last Updated: few minutes ago")}
                            className="h-full"
                        >
                            <div className="flex flex-col gap-4 mt-4">
                                {issueCount ? (
                                    <Tag
                                        className="text-red-500"
                                        icon={HiExclamationCircle}
                                    >
                                        {t("COUNT_ISSUES_DETECTED", {
                                            count: issueCount,
                                        })}
                                    </Tag>
                                ) : (
                                    <Tag
                                        className="text-green-700"
                                        icon={HiCheckCircle}
                                    >
                                        {t("No issues detected")}
                                    </Tag>
                                )}
                                {/* {project.softwareIsUpToDate ? (
                                    <Tag
                                        className="text-green-700"
                                        icon={HiCheckCircle}
                                    >
                                        All software is up to date
                                    </Tag>
                                ) : (
                                    <Tag
                                        className="text-red-500"
                                        icon={HiExclamationCircle}
                                    >
                                        Updates are missing
                                    </Tag>
                                )} */}

                                <div className="flex justify-between">
                                    <div>{t("Status")}</div>
                                    <div>
                                        <Tag className="indigo">
                                            {t(project.status)}
                                        </Tag>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </div>
                </div>
            )}

            <Boxes className="">
                <Box title={t("Live Sites")}>
                    <table className="w-full my-3">
                        <thead>
                            <tr>
                                <th>{t("Name")}</th>
                                {hasScope("superadmin") && (
                                    <th className="text-center">
                                        {t("Health")}
                                    </th>
                                )}
                                <th className="text-center">{t("Status")}</th>
                                <th className="text-center">{t("Region")}</th>
                                <th className="hidden md:table-cell">
                                    {t("Location")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {project.sites
                                .filter(
                                    (node: CoreSite) => node.status === "Live"
                                )
                                .map((node: CoreSite) => (
                                    <tr key={node.reference}>
                                        <td>
                                            <Link
                                                to={`/sites/${node.reference}`}
                                            >
                                                {node.name}
                                            </Link>
                                        </td>
                                        {hasScope("superadmin") && (
                                            <td className="text-center">
                                                <TagHealth
                                                    value={node.healthScore}
                                                />
                                            </td>
                                        )}
                                        <td className="text-center">
                                            <Tag
                                                bgColor={generateColor(
                                                    node.status
                                                )}
                                            >
                                                {t(node.status) ||
                                                    t("No Status")}
                                            </Tag>
                                        </td>
                                        <td className="text-center">
                                            {node.region}
                                        </td>
                                        <td className="hidden md:table-cell">
                                            {node.location}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </Box>
                <Box title={t("Planned Sites")}>
                    <table className="w-full my-3">
                        <thead>
                            <tr>
                                <th>{t("Name")}</th>
                                <th className="text-center">{t("Status")}</th>
                                <th className="text-center">{t("Region")}</th>
                                <th className="hidden md:table-cell">
                                    {t("Location")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {project.sites
                                .filter(
                                    (node: CoreSite) =>
                                        !["Live", "Closed"].includes(
                                            node.status
                                        )
                                )
                                .map((node: CoreSite) => (
                                    <tr key={node.reference}>
                                        <td>
                                            <Link
                                                to={`/sites/${node.reference}`}
                                            >
                                                {node.name}
                                            </Link>
                                        </td>
                                        <td className="text-center">
                                            <Tag
                                                bgColor={generateColor(
                                                    node.status || "No Status"
                                                )}
                                            >
                                                {t(node.status) ||
                                                    t("No Status")}
                                            </Tag>
                                        </td>
                                        <td className="text-center">
                                            {node.region}
                                        </td>
                                        <td className="hidden md:table-cell">
                                            {node.location}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </Box>
            </Boxes>
        </Content>
    );
};

export default PageProject;
