import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { MUTATION_CONFIGURE_BRANCH } from "../../../../graphql/mutations";
import { useLoading } from "../../../context/LoadingContext";
import { Form, InputText } from "../../../layout/Form";
import classNames from "classnames";
import { configValue } from "../../../../helpers/utils";
import SensorsValidSection from "./SensorsValid";

type Errors = {
    form?: string;
    name?: string;
    configNoColumns?: string;
    configNoColumnsOv?: string;
    configNoSensors?: string;
    configNoSensorsOv?: string;
};

interface FormState {
    name: string | null;
    configNoColumns: number | null;
    configNoColumnsOv: number | null;
    configNoSensors: number | null;
    configNoSensorsOv: number | null;
}

interface ConfigureClusterProps {
    site: CoreSite;
    system: CoreSystem;
    selectedNode: CoreNode;
    selectNode: (node: CoreNode) => void;
    refetch: () => void;
    handleNext: () => void;
    isCommissioningRunning: boolean;
    closeModal: () => void;
    sensorsState: SensorsState;
    setSensorsState: (state: SensorsState) => void;
}

export default function ConfigureAisleForm({
    site,
    selectedNode,
    system,
    refetch,
    handleNext,
    selectNode,
    sensorsState,
    setSensorsState,
}: ConfigureClusterProps) {
    const { t } = useTranslation();
    const location = useLocation();

    const defaultFormState = {
        name: selectedNode.clientRef,
        configNoColumns: selectedNode.configNoColumns,
        configNoColumnsOv: selectedNode.configNoColumnsOv,
        configNoSensors: selectedNode.configNoSensors,
        configNoSensorsOv: selectedNode.configNoSensorsOv,
    };

    const [formState, setFormState] = useState<FormState>(defaultFormState);

    const [errors, setErrors] = useState<Errors>({});

    const [submitFormMutation] = useMutation(MUTATION_CONFIGURE_BRANCH);

    useEffect(() => {
        const noSensorsExpected =
            formState.configNoColumns * formState.configNoSensors +
            formState.configNoColumnsOv * formState.configNoSensorsOv;
        setSensorsState({
            sensorsExpected: noSensorsExpected,
            sensorsConnected: sensorsState.sensorsConnected,
            valid: noSensorsExpected === sensorsState.sensorsConnected,
        });
    }, [formState, sensorsState.sensorsConnected]);

    const handleGoToCommission = (formState: FormState) => {
        const noSensorsExpected =
            formState.configNoColumns * formState.configNoSensors +
            formState.configNoColumnsOv * formState.configNoSensorsOv;
        if (noSensorsExpected !== sensorsState.sensorsConnected) {
            return;
        }
        handleNext();
    };

    const { setLoading } = useLoading();

    const handleInputTextChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleInputNumberChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        const numericValue = Number(value);
        setFormState((prevState) => ({
            ...prevState,
            [name]: value === "" ? "" : numericValue < 0 ? "" : numericValue,
        }));
    };

    const validate = () => {
        const newErrors: Errors = {};

        const query = new URLSearchParams(location.search);
        const novalidate = query.get("novalidate") || false;
        if (novalidate) {
            return newErrors;
        }

        if (!formState.name) {
            newErrors.name = t("Name is required");
        }
        return newErrors;
    };

    const handleSubmit = ({ reset = false, goNext = true }) => {
        const errors = validate();
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);

        const state = {
            name: formState.name,
            configNoColumns: configValue(
                formState.configNoColumns,
                site.configNoColumns
            ),
            configNoColumnsOv: configValue(
                formState.configNoColumnsOv,
                site.configNoColumnsOv
            ),
            configNoSensors: configValue(
                formState.configNoSensors,
                site.configNoSensors
            ),
            configNoSensorsOv: configValue(
                formState.configNoSensorsOv,
                site.configNoSensorsOv
            ),
        };

        if (reset === true) {
            console.log("Resetting to defaults");
            state.configNoColumns = site.configNoColumns;
            state.configNoColumnsOv = site.configNoColumnsOv;
            state.configNoSensors = site.configNoSensors;
            state.configNoSensorsOv = site.configNoSensorsOv;
        }

        setFormState((prevState) => ({
            ...prevState,
            ...state,
        }));
        selectNode({
            ...selectedNode,
            clientRef: formState.name,
            ...state,
        });

        if (JSON.stringify(state) === JSON.stringify(defaultFormState)) {
            console.log("No changes over the Aisle configuration");
            setLoading(false);
            if (goNext) {
                handleGoToCommission(state);
            }
            return;
        }

        submitFormMutation({
            variables: {
                macAddress: system.macAddress,
                branch: Number(selectedNode.address),
                ...state,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                refetch();
                if (goNext) {
                    handleGoToCommission(state);
                }
            })
            .catch((err) => {
                console.error("Mutation error:", err);
                setErrors({ ...errors, form: "Something went wrong!" });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleBtnReset = () => {
        handleSubmit({ reset: true, goNext: true });
    };

    const handleBtnUpdate = () => {
        handleSubmit({ reset: false, goNext: false });
    };

    const handleBtnNext = () => {
        handleSubmit({ reset: false, goNext: true });
    };

    return (
        <Form error={errors.form}>
            <InputText
                label={t("Aisle Name (e.g. A1, D6, ...)")}
                error={errors.name}
                props={{
                    name: "name",
                    required: true,
                    value: formState.name,
                    onChange: handleInputTextChange,
                }}
            />

            <div className="mt-4">
                <b>{t("Bag (4T) Columns")}</b>
                <div className="flex justify-between gap-4">
                    <InputText
                        label={t("Columns per Aisle")}
                        error={errors.configNoColumns}
                        props={{
                            name: "configNoColumns",
                            type: "number",
                            min: "0",
                            value: formState.configNoColumns,
                            onChange: handleInputNumberChange,
                            placeholder: site.configNoColumns.toString(),
                        }}
                        className="w-1/2"
                    />
                    <InputText
                        label={t("Sensors per Column")}
                        error={errors.configNoSensors}
                        props={{
                            name: "configNoSensors",
                            type: "number",
                            min: "0",
                            value: formState.configNoSensors,
                            onChange: handleInputNumberChange,
                            placeholder: site.configNoSensors.toString(),
                        }}
                        className="w-1/2"
                    />
                </div>
            </div>
            <div className="mt-2">
                <b>{t("OV Columns")}</b>
                <div className="flex justify-between gap-4">
                    <InputText
                        label={t("OV per Aisle")}
                        error={errors.configNoColumnsOv}
                        props={{
                            name: "configNoColumnsOv",
                            type: "number",
                            min: "0",
                            value: formState.configNoColumnsOv,
                            onChange: handleInputNumberChange,
                            placeholder: site.configNoColumnsOv.toString(),
                        }}
                        className="w-1/2"
                    />

                    <InputText
                        label={t("Sensors per OV")}
                        error={errors.configNoSensors}
                        props={{
                            name: "configNoSensorsOv",
                            type: "number",
                            min: "0",
                            value: formState.configNoSensorsOv,
                            onChange: handleInputNumberChange,
                            placeholder: site.configNoSensorsOv.toString(),
                        }}
                        className="w-1/2"
                    />
                </div>
            </div>

            <SensorsValidSection
                system={system}
                selectedNode={selectedNode}
                sensorsState={sensorsState}
            />

            <div className="buttons justify-end">
                {/* <button
                    onClick={closeModal}
                    className={classNames("button light w-24")}
                >
                    {t("Close")}
                </button> */}
                <button
                    type="button"
                    id="btn-aisle-reset-config"
                    className={classNames(
                        "button light"
                        // isCommissioningRunning ? "hidden" : ""
                    )}
                    // disabled={isCommissioningRunning}
                    onClick={() => handleBtnReset()}
                >
                    {t("Reset to Defaults")}
                </button>

                <button
                    type="button"
                    id="btn-aisle-update-config"
                    className={classNames(
                        "button info success px-11"
                        // isCommissioningRunning ? "hidden" : ""
                    )}
                    // disabled={isCommissioningRunning}
                    onClick={() => handleBtnUpdate()}
                >
                    {t("BTN_UPDATE_AISLE_CONFIG")}
                </button>

                <button
                    type="button"
                    id="btn-aisle-open-calibration"
                    className={classNames(
                        "button info",
                        sensorsState.valid ? "" : "disabled"
                        // isCommissioningRunning ? "hidden" : ""
                    )}
                    // disabled={isCommissioningRunning}
                    onClick={() => {
                        if (sensorsState.valid === false) {
                            return;
                        }
                        handleBtnNext();
                    }}
                >
                    {t("BTN_RUN_COMMISSIONING")}
                </button>
            </div>
        </Form>
    );
}
