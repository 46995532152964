import { useTranslation } from "react-i18next";
import { HiCheckCircle } from "react-icons/hi";
import { Tag } from "../../../layout/Tag";
import { generateColor } from "../../../../helpers/utils";
import { SensorsControl } from "./SensorsControl";

type SensorsValidSectionProps = {
    selectedNode: CoreNode;
    system: CoreSystem;
    sensorsState: SensorsState;
};

export default function SensorsValidSection({
    selectedNode,
    system,
    sensorsState,
}: SensorsValidSectionProps) {
    const { t } = useTranslation();

    return (
        <div className="mt-2 mb-4 p-3 bg-gray-200 rounded flex flex-wrap justify-between">
            <div className="min-w-96 flex items-center  h-9">
                <div className="flex gap-3">
                    <div className="flex gap-1">
                        {t("Expected Sensors:")} {sensorsState.sensorsExpected}
                        {sensorsState.valid && (
                            <HiCheckCircle className="w-5 h-5 text-green-700" />
                        )}
                    </div>
                    {sensorsState.valid === false && (
                        <div className="flex gap-1 error ">
                            {t("Detected Sensors:")}
                            <Tag bgColor={generateColor("Error")}>
                                {sensorsState.sensorsConnected}
                            </Tag>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex items-center h-9">
                {sensorsState.valid === false && (
                    <SensorsControl
                        system={system}
                        selectedNode={selectedNode}
                    />
                )}
            </div>
        </div>
    );
}
