import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";

interface PaginationProps {
    pageInfo: PageInfo;
}

export default function Pagination({ pageInfo }: PaginationProps) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentPage = pageInfo.offset / pageInfo.limit + 1;
    const totalPages = Math.ceil(pageInfo.totalCount / pageInfo.limit);

    const pageNumbers = [];
    let ellipsisLeft = false;
    let ellipsisRight = false;

    for (let i = 1; i <= totalPages; i++) {
        if (
            i === 1 ||
            i === totalPages ||
            (i >= currentPage - 2 && i <= currentPage + 2)
        ) {
            pageNumbers.push(i);
        } else if (i < currentPage - 2) {
            if (!ellipsisLeft) {
                pageNumbers.push("...");
                ellipsisLeft = true;
            }
        } else if (i > currentPage + 2) {
            if (!ellipsisRight) {
                pageNumbers.push("...");
                ellipsisRight = true;
            }
        }
    }

    const createPageLink = (page: number) => {
        searchParams.set("page", page.toString());
        return `${location.pathname}?${searchParams.toString()}`;
    };

    return (
        <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                <Link
                    to={createPageLink(currentPage > 1 ? currentPage - 1 : 1)}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Previous
                </Link>
                <Link
                    to={createPageLink(
                        currentPage < totalPages ? currentPage + 1 : totalPages
                    )}
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Next
                </Link>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Showing
                        <span
                            className="font-medium px-1"
                            data-testid="page-show"
                        >
                            {(currentPage - 1) * pageInfo.limit + 1}
                        </span>
                        to
                        <span
                            className="font-medium px-1"
                            data-testid="page-to"
                        >
                            {Math.min(
                                currentPage * pageInfo.limit,
                                pageInfo.totalCount
                            )}
                        </span>
                        of
                        <span
                            className="font-medium px-1"
                            data-testid="page-results"
                        >
                            {pageInfo.totalCount}
                        </span>
                        results
                    </p>
                </div>
                <div>
                    <nav
                        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                        aria-label="Pagination"
                    >
                        <Link
                            to={createPageLink(
                                currentPage > 1 ? currentPage - 1 : 1
                            )}
                            className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
                        >
                            <HiChevronLeft className="text-gray-400 h-5 w-5" />
                        </Link>
                        {pageNumbers.map((page, index) =>
                            typeof page === "number" ? (
                                <Link
                                    to={createPageLink(page)}
                                    key={`page-${index}`}
                                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${currentPage === page ? "text-white bg-sky-600" : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"}`}
                                >
                                    {page}
                                </Link>
                            ) : (
                                <span
                                    key={`page-${index}`}
                                    className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700"
                                >
                                    {page}
                                </span>
                            )
                        )}
                        <Link
                            to={createPageLink(
                                currentPage < totalPages
                                    ? currentPage + 1
                                    : totalPages
                            )}
                            className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
                            aria-disabled={currentPage === totalPages}
                        >
                            <HiChevronRight className="text-gray-400 h-5 w-5" />
                        </Link>
                    </nav>
                </div>
            </div>
        </div>
    );
}
