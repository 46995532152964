import { SensorsStructure, StructureConfig } from ".";

export const generateInitialStructure = ({
    noColumns,
    noColumnsOv,
    noSensors,
    noSensorsOv,
}: StructureConfig): SensorsStructure => {
    const structure = [];
    for (let i = 0; i < noColumns; i++) {
        structure.push({
            sensors: Array(noSensors).fill({ on: false }),
        });
    }
    for (let i = 0; i < noColumnsOv; i++) {
        structure.push({
            sensors: Array(noSensorsOv).fill({ on: false }),
        });
    }
    return structure;
};

export const turnOnNextInStructure = (
    structure: SensorsStructure
): SensorsStructure => {
    const newStructure = structure.map((column) => ({
        sensors: column.sensors.map((sensor) => ({ ...sensor })),
    }));

    let sensorTurnedOn = false;

    for (let i = 0; i < newStructure.length; i++) {
        for (let j = 0; j < newStructure[i].sensors.length; j++) {
            if (!newStructure[i].sensors[j].on && !sensorTurnedOn) {
                newStructure[i].sensors[j].on = true;
                return newStructure;
            }
        }
    }
};
