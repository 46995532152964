import { useState } from "react";
import { useMutation } from "@apollo/client";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import {
    MUTATION_TURN_OFF_ALL_ONLINE_SENSORS,
    MUTATION_TURN_OFF_ALL_SENSORS,
    MUTATION_TURN_ON_ALL_SENSORS,
} from "../../../../graphql/mutations";
import Modal from "../../../layout/Modal";

type SensorsControlProps = {
    system: CoreSystem;
    selectedNode: CoreNode;
};

const SensorsControl = ({ system, selectedNode }: SensorsControlProps) => {
    const { t } = useTranslation();

    const [turnOnAllSensors, { loading: loadingOnAllSensors }] = useMutation(
        MUTATION_TURN_ON_ALL_SENSORS
    );
    const [turnOffAllSensors, { loading: loadingOffAllSensors }] = useMutation(
        MUTATION_TURN_OFF_ALL_SENSORS
    );
    const [turnOffAllOnlineSensors, { loading: loadingOffAllOnlineSensors }] =
        useMutation(MUTATION_TURN_OFF_ALL_ONLINE_SENSORS);

    const [error, setError] = useState("");

    const handleTurnOnAllSensors = () => {
        turnOnAllSensors({
            variables: {
                macAddress: system.macAddress,
                branch: Number(selectedNode.address),
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
            })
            .catch((err) => {
                setError(
                    "Error turning on all sensors. Refresh the page and try again."
                );
                console.error("Mutation error:", err);
            });
    };

    const handleTurnOffAllSensors = () => {
        turnOffAllSensors({
            variables: {
                macAddress: system.macAddress,
                branch: Number(selectedNode.address),
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
            })
            .catch((err) => {
                setError(
                    "Error turning off all sensors. Refresh the page and try again."
                );
                console.error("Mutation error:", err);
            });
    };

    const handleTurnOffAllOnlineSensors = () => {
        turnOffAllOnlineSensors({
            variables: {
                macAddress: system.macAddress,
                branch: Number(selectedNode.address),
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
            })
            .catch((err) => {
                setError(
                    "Error turning off all online sensors. Refresh the page and try again."
                );
                console.error("Mutation error:", err);
            });
    };

    return (
        <>
            {error && <div className="my-4 error">{error}</div>}
            <div className="flex flex-wrap gap-2">
                <button
                    id="btn-aisle-sensors-turn-all-on"
                    className={classNames("button info px-2")}
                    type="button"
                    onClick={handleTurnOnAllSensors}
                >
                    {loadingOnAllSensors
                        ? t("Loading...")
                        : t("BTN_TURN_ON_ALL_SENSORS")}
                </button>
                <button
                    id="btn-aisle-sensors-turn-all-off"
                    className={classNames("button info px-2")}
                    type="button"
                    onClick={handleTurnOffAllSensors}
                >
                    {loadingOffAllSensors
                        ? t("Loading...")
                        : t("BTN_TURN_OFF_ALL_SENSORS")}
                </button>
                <button
                    id="btn-aisle-sensors-turn-online-off"
                    className={classNames("button info px-2")}
                    type="button"
                    onClick={handleTurnOffAllOnlineSensors}
                >
                    {loadingOffAllOnlineSensors
                        ? t("Loading...")
                        : t("BTN_TURN_OFF_ALL_ONLINE_SENSORS")}
                </button>
            </div>
        </>
    );
};

const SensorsControlModal = ({ system, selectedNode }: SensorsControlProps) => {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => setIsModalOpen(false);

    return (
        <>
            <button
                id="btn-aisle-sensors-open"
                className="button indigo"
                onClick={() => setIsModalOpen(true)}
            >
                {t("SENSOR_CONTROL")}
            </button>

            <Modal
                title={t("MODAL_TITLE_SENSOR_CONTROL")}
                isOpen={isModalOpen}
                onClose={closeModal}
                size="max-w-2xl"
                children={
                    <>
                        <div className="mt-4">
                            <SensorsControl
                                system={system}
                                selectedNode={selectedNode}
                            />
                        </div>
                        <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-2">
                            <button
                                className="button light w-32"
                                type="button"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
};

const SensorsControlBtn = ({ system, selectedNode }: SensorsControlProps) => {
    if (!selectedNode || !system) {
        return null;
    }

    if (!system.maintenanceMode) {
        return null;
    }

    return <SensorsControlModal system={system} selectedNode={selectedNode} />;
};

export default SensorsControlBtn;
export { SensorsControl };
