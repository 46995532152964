import React from "react";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import QrScanner from "qr-scanner";

import { MUTATION_ACCOUNT_ADD_SYSTEM } from "../../../graphql/mutations";
import { useLoading } from "../../context/LoadingContext";
import { Form, InputText } from "../../layout/Form";
import QrReader from "../../layout/QrReader";

type AddSystemFormProps = {
    site: CoreSite;
    closeModal: () => void;
    refetch: () => void;
};

type Errors = {
    form?: string;
    macAddress?: string;
    clientRef?: string;
};

interface FormState {
    macAddress: string;
    clientRef: string;
}

export default function AddSystemForm({
    site,
    closeModal,
    refetch,
}: AddSystemFormProps) {
    const { t } = useTranslation();
    const { setLoading } = useLoading();

    const [submitFormMutation] = useMutation(MUTATION_ACCOUNT_ADD_SYSTEM);

    const [formState, setFormState] = useState<FormState>({
        macAddress: "",
        clientRef: "",
    });

    const [errors, setErrors] = useState<Errors>({});

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validate = () => {
        const newErrors: Errors = {};
        if (!formState.macAddress) {
            newErrors.macAddress = t("Mac Address is required");
        }

        if (!formState.clientRef) {
            newErrors.clientRef = t("Controller Location is required");
        }
        return newErrors;
    };

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        const errors = validate();
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);

        submitFormMutation({
            variables: {
                macAddress: formState.macAddress,
                site: site.reference,
                clientRef: formState.clientRef,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                refetch();
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
                setErrors({ ...errors, form: "System does not exist!" });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleQrSuccess = (result: QrScanner.ScanResult) => {
        console.log("QR Success:", result.data);
        setFormState((prevState) => ({
            ...prevState,
            macAddress: result.data,
        }));
    };

    return (
        <Form props={{ onSubmit: handleSubmit }} error={errors.form}>
            <QrReader callbackOnSuccess={(result) => handleQrSuccess(result)} />
            <InputText
                label={t("Mac Address")}
                error={errors.macAddress}
                props={{
                    name: "macAddress",
                    required: true,
                    value: formState.macAddress,
                    onChange: handleInputChange,
                }}
            />
            <InputText
                label={t("Controller Location (e.g. A1, D6, ...)")}
                error={errors.clientRef}
                props={{
                    name: "clientRef",
                    required: true,
                    onChange: handleInputChange,
                }}
            />

            <div className="buttons justify-end">
                <button className="button light" onClick={closeModal}>
                    {t("Cancel")}
                </button>
                <button id="btn-site-save-new-system" type="submit" className="button info">
                    {t("Save")}
                </button>
            </div>
        </Form>
    );
}
