import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi";
import classNames from "classnames";
import Cookies from "js-cookie";

import { useUser } from "../../context/UserContext";
import config from "../../../config";

function DropdownAccounts() {
    const { user } = useUser();

    const selectedAccount = user.accounts.find(
        (account) => account.value === user.account_reference
    );

    const [account, setAccount] = useState(selectedAccount);
    const [hasChanged, setChanged] = useState(false);

    useEffect(() => {
        // Skip effect on initial mount
        if (!hasChanged) {
            return;
        }

        // Set cookie
        Cookies.set("maxiluxcloud_account_reference", account.value, {
            expires: 1, // Expires in 1 day
            domain: config.cookieDomain,
        });

        // window.location.reload();
        window.location.href = "/";
    }, [account, hasChanged]);

    const handleAccountChange = (newAccount: Account) => {
        setAccount(newAccount);
        setChanged(true);
    };

    if (user.accounts.length <= 1) {
        return null;
    }

    return (
        <Menu as="div" className="relative">
            <div className="group flex items-center gap-2 hover:cursor-pointer">
                <Menu.Button className="flex">
                    {account.label}{" "}
                    <HiChevronDown
                        fontSize={20}
                        className="group-hover:opacity-100 opacity-0 transition-opacity duration-150 ease-in-out"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {/* bottom-full */}
                    {user.accounts.map((link) => (
                        <Menu.Item key={link.value}>
                            {({ active }) => (
                                <div
                                    onClick={() => handleAccountChange(link)}
                                    className={classNames(
                                        active && "bg-gray-100",
                                        account === link && "bg-gray-200",
                                        "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                                    )}
                                >
                                    {link.label}
                                </div>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}

export default DropdownAccounts;
