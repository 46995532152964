import classNames from "classnames";
import { useNavigation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useConnection } from "../context/ConnectionContext";

export function ConnectionWarning({ force = false }) {
    const { t } = useTranslation();
    const navigation = useNavigation();
    const { connectionLost } = useConnection();
    return (
        <div
            className={classNames(
                "w-full h-10 bg-orange-300 text-sm font-semibold flex justify-center items-center",
                navigation.state === "loading" || connectionLost || force
                    ? "block"
                    : "hidden"
            )}
        >
            <div className="text-gray-600">{t("LOST_CONNECTION")}</div>
        </div>
    );
}
