import { createContext, useState, useContext } from "react";

const ConnectionContext = createContext({
    connectionLost: false,
    setConnectionLost: (state) => {},
});

export const useConnection = () => useContext(ConnectionContext);

export const ConnectionProvider = ({ children }) => {
    const [connectionLost, setConnectionLost] = useState(false);

    return (
        <ConnectionContext.Provider
            value={{
                connectionLost: connectionLost,
                setConnectionLost: setConnectionLost,
            }}
        >
            {children}
        </ConnectionContext.Provider>
    );
};
