import { useParams } from "react-router-dom";

import { QUERY_PROJECT, QUERY_PROJECTS } from "../graphql/queries";
import PageProjects from "../components/pages/projects/Projects";
import PageProject from "../components/pages/project/Project";
import { ErrorComponent } from "../components/pages/Error";
import { useQueryWithParams } from "../graphql/useQueryWithParams";

interface QueryProjectsData {
    maxilux: {
        projects: Connection<CoreProject>;
    };
}

const ProjectsRoute = () => {
    const { loading, error, data, refetch } = useQueryWithParams<
        QueryProjectsData,
        { limit: number; offset: number }
    >({ query: QUERY_PROJECTS, variables: { limit: 50, offset: 0 } });

    if (loading) return null;

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    return <PageProjects projects={data.maxilux.projects} />;
};

interface QueryProjectData {
    maxilux: {
        project: CoreProject;
    };
}

const ProjectRoute = () => {
    const { code = "AMZ001" } = useParams<{ code: string }>();

    const { loading, error, data, refetch } = useQueryWithParams<
        QueryProjectData,
        { code: string }
    >({ query: QUERY_PROJECT, variables: { code: code } });

    if (loading) return null;

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    const sites = [...data.maxilux.project.sites].sort((a, b) => {
        const statusA = a.status || "";
        const statusB = b.status || "";

        if (statusA < statusB) return -1;
        if (statusA > statusB) return 1;
        return 0;
    });

    return <PageProject project={{ ...data.maxilux.project, sites: sites }} />;
};

export { ProjectsRoute, ProjectRoute };
