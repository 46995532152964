import classNames from "classnames";
import { Link } from "react-router-dom";
import { generateColor } from "../../helpers/utils";

interface ActionButtonProps {
    label?: string;
    icon?: React.ElementType;
    color?: string;
    onClick?: () => void;
}

interface ActionLinkProps {
    to: string;
    label?: string;
    icon?: React.ElementType;
    color?: string;
    onClick?: () => void;
}

export const ActionButton = ({
    label = null,
    icon: Icon = null,
    color = null,
    onClick = null,
}: ActionButtonProps) => {
    return (
        <div style={{ color: generateColor(color) }}>
            <button
                className={classNames("inline-flex items-center")}
                onClick={onClick}
                title={label}
            >
                <Icon
                    fontSize={25}
                    className={`hover:opacity-75`}
                />
            </button>
        </div>
    );
};

export const LinkAction = ({
    to,
    label = null,
    icon: Icon = null,
    color = null,
}: ActionLinkProps) => {
    return (
        <div>
            <Link to={to} title={label}>
                <Icon
                    fontSize={25}
                    className={`text-${color}-600 hover:text-${color}-700`}
                />
            </Link>
        </div>
    );
};
