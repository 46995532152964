import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { MUTATION_SYSTEM_UPGRADE } from "../../../graphql/mutations";
import Modal from "../../layout/Modal";
import classNames from "classnames";

type UpgradeSystemProps = {
    system: CoreSystem;
    refetch: () => void;
};

const UpgradeSystem = ({ system, refetch }: UpgradeSystemProps) => {
    const { t } = useTranslation();

    const [executeUpgrade] = useMutation(MUTATION_SYSTEM_UPGRADE);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => setIsModalOpen(false);

    const [running, setRunning] = useState(false);

    const handleUpgrade = () => {
        setRunning(true);
        executeUpgrade({
            variables: {
                macAddress: system.macAddress,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                setRunning(false);
                refetch();
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            });
    };

    return (
        <>
            <button
                id="btn-system-open-upgrade-modal"
                className="button info w-48"
                onClick={() => setIsModalOpen(true)}
            >
                {t("Upgrade")}
            </button>

            <Modal
                title="Upgrade System"
                isOpen={isModalOpen}
                onClose={closeModal}
                size="max-w-xl"
                children={
                    <>
                        <div className="mt-4 text-sm">
                            <p>
                                {t(
                                    "Use this tool to upgrade the system to the latest version."
                                )}
                            </p>
                        </div>
                        <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-2">
                            <button
                                className="button light  w-32"
                                type="button"
                                onClick={closeModal}
                            >
                                {t("Close")}
                            </button>
                            <button
                                id="btn-system-upgrade"
                                className={classNames(
                                    "button info w-32",
                                    running ? "light" : ""
                                )}
                                type="button"
                                onClick={handleUpgrade}
                                disabled={running}
                            >
                                {running ? t("Loading...") : t("Go")}
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
};

const UpgradeSystemBtn = ({ system, refetch }: UpgradeSystemProps) => {
    const { t } = useTranslation();

    if (!system) {
        return null;
    }

    if (system.maintenanceMode === false) {
        return (
            <button
                className={classNames("button info w-48 disabled")}
                type="button"
            >
                {t("Upgrade")}
            </button>
        );
    }

    return <UpgradeSystem system={system} refetch={refetch} />;
};

export default UpgradeSystemBtn;
