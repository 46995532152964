import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";

import { SUBSCRIPTION_CONTROLLER_EVENTS } from "../../../graphql/subscriptions";
import { MUTATION_REFRESH } from "../../../graphql/mutations";
import { useLoading } from "../../context/LoadingContext";
import { QUERY_SYSTEM_STATUS } from "../../../graphql/queries";
import Modal from "../../layout/Modal";

interface RefrehAndStatusProps {
    macAddress: string;
    callback: () => void;
    label?: string;
}

export default function RefreshAndStatus({
    macAddress,
    callback,
    label = "Remap & Status",
}: RefrehAndStatusProps) {
    const { t } = useTranslation();
    const { setLoading } = useLoading();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState("");

    const [mutationRemap] = useMutation(MUTATION_REFRESH);

    const [skipStatus, setSkipStatus] = useState(true);
    const { refetch } = useQuery(QUERY_SYSTEM_STATUS, {
        variables: {
            macAddress: macAddress,
        },
        skip: skipStatus,
        onCompleted: () => {
            setRunning(false);
            setLoading(false);
            closeModal();
            callback();
        },
        notifyOnNetworkStatusChange: true,
    });

    const [running, setRunning] = useState(false);
    const { data } = useSubscription(SUBSCRIPTION_CONTROLLER_EVENTS, {
        variables: {
            macAddresses: [macAddress],
        },
        skip: !running,
    });

    const handleSubscriptionEvents = useCallback(() => {
        if (!data || !data.events) {
            return;
        }

        console.log("Event received:", data.events);

        // success events
        if (
            ["discovery_complete", "system_refreshed"].includes(
                data.events.kind
            )
        ) {
            setSkipStatus(false);
            refetch();
        }

        // failure events
        if (
            ["system_refresh_failed", "discovery_failed"].includes(
                data.events.kind
            )
        ) {
            setError(t("Something went wrong. Please try again."));
            setRunning(false);
        }
    }, [data, refetch, t]);

    useEffect(() => {
        handleSubscriptionEvents();
    }, [data, handleSubscriptionEvents]);

    const executeRemapMutation = async () => {
        mutationRemap({
            variables: {
                macAddress: macAddress,
            },
        });
    };

    const handleRefreshAndRemap = async () => {
        console.log("Refresh button clicked");
        setLoading(true);
        setRunning(true);
        executeRemapMutation();
    };

    const closeModal = () => setIsModalOpen(false);

    if (error) return <p>Error :(</p>;

    return (
        <>
            <button
                id="btn-system-open-refresh-modal"
                className={"button info w-40"}
                onClick={() => setIsModalOpen(true)}
            >
                {label}
            </button>

            <Modal
                title="Refresh"
                isOpen={isModalOpen}
                onClose={closeModal}
                size="max-w-xl"
                children={
                    <>
                        <div className="mt-4">
                            <Trans i18nKey="REFRESH_POPUP_TEXT" />
                        </div>
                        {error && <div className="error my-5">{error}</div>}
                        <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-2">
                            <button
                                className="button light w-32"
                                type="button"
                                onClick={closeModal}
                            >
                                {t("Cancel")}
                            </button>
                            <button
                                id="btn-system-refresh"
                                className={classNames(
                                    "button info w-32",
                                    running ? "light" : ""
                                )}
                                type="button"
                                onClick={handleRefreshAndRemap}
                                disabled={running}
                            >
                                {running ? t("Loading...") : t("Proceed")}
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
}
