import { QUERY_GUIDES } from "../graphql/queries";
import { ErrorComponent } from "../components/pages/Error";
import PageSupport from "../components/pages/support/Support";
import { useQueryWithParams } from "../graphql/useQueryWithParams";

interface QueryGuidesData {
    maxilux: {
        guides: Connection<Guide>;
    };
}

const SupportRoute = () => {
    const { loading, error, data, refetch } = useQueryWithParams<
        QueryGuidesData,
        null
    >({
        query: QUERY_GUIDES,
        accessScope: "superadmin",
    });

    if (loading) return null;

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    return <PageSupport guides={data.maxilux.guides} />;
};

export { SupportRoute };
