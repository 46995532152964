import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

const data = [
    {
        name: "Jan",
        Wrong: 4000,
        Good: 2400,
    },
    {
        name: "Feb",
        Wrong: 3000,
        Good: 1398,
    },
    {
        name: "Mar",
        Wrong: 2000,
        Good: 9800,
    },
    {
        name: "Apr",
        Wrong: 2780,
        Good: 3908,
    },
    {
        name: "May",
        Wrong: 1890,
        Good: 4800,
    },
    {
        name: "Jun",
        Wrong: 2390,
        Good: 3800,
    },
    {
        name: "July",
        Wrong: 3490,
        Good: 4300,
    },
    {
        name: "Aug",
        Wrong: 2000,
        Good: 9800,
    },
    {
        name: "Sep",
        Wrong: 2780,
        Good: 3908,
    },
    {
        name: "Oct",
        Wrong: 1890,
        Good: 4800,
    },
    {
        name: "Nov",
        Wrong: 2390,
        Good: 3800,
    },
    {
        name: "Dec",
        Wrong: 3490,
        Good: 4300,
    },
];

export default function SampleChart() {
    return (
        <div className="h-[22rem] bg-white p-4 flex flex-col flex-1">
            {/* <strong className="text-gray-700 font-medium">Transactions</strong> */}
            <div className="mt-3 w-full flex-1 text-xs">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 20,
                            right: 10,
                            left: -10,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3 0 0"
                            vertical={false}
                        />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Good" fill="#3B82F6" />
                        {/* bg-red-500 */}
                        <Bar dataKey="Wrong" fill="#EA4649" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
