import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Box } from "../../../layout/Boxes";
import { Tag } from "../../../layout/Tag";
import {
    configValue,
    generateColor,
    nodeStatus,
    timeAgo,
} from "../../../../helpers/utils";
import AisleTable from "./Table";
import AislesSectionBottom from "./BottomSection";

const AislesSection = ({
    system,
    site,
    remapCallback,
    selectedNode,
    selectNode,
    config,
}) => {
    const { t } = useTranslation();

    const getSensorStatus = (node: CoreNode) => {
        if (!node.mappedPeripherals) {
            return null;
        }

        const onlineMappedSensors = node.mappedPeripherals.filter(
            (peripheral) =>
                peripheral.status === "Online" && peripheral.aliasAddress
        ).length;

        const onlineUnmappedSensors = node.mappedPeripherals.filter(
            (peripheral) =>
                peripheral.status === "Online" && !peripheral.aliasAddress
        ).length;

        const configNoColumns = configValue(node.configNoColumns, null);
        const configNoColumnsOv = configValue(node.configNoColumnsOv, null);
        const configNoSensors = configValue(node.configNoSensors, null);
        const configNoSensorsOv = configValue(node.configNoSensorsOv, null);

        let expectedCount = "-";
        if (
            configNoColumns !== null &&
            configNoColumnsOv !== null &&
            configNoSensors !== null &&
            configNoSensorsOv !== null
        ) {
            expectedCount = String(
                configNoColumns * configNoSensors +
                    configNoColumnsOv * configNoSensorsOv
            );
        }

        let onlineExtraText = "";
        if (onlineUnmappedSensors > 0) {
            onlineExtraText = ` (${onlineUnmappedSensors})`;
        }

        if (
            expectedCount !== "-" &&
            expectedCount !== String(onlineMappedSensors)
        ) {
            return (
                <Tag bgColor={generateColor("Offline")}>
                    {onlineMappedSensors}/{expectedCount}
                    {onlineExtraText}
                </Tag>
            );
        }
        return (
            <div>
                {onlineMappedSensors}/{expectedCount}
                {onlineExtraText}
            </div>
        );
    };
    const getAislesRows = () => {
        const rows = [];

        if (!config.noAisles) {
            return rows;
        }

        for (let index = 0; index < config.noAisles; index++) {
            const address = index + 1;

            // Virtual aisles/rows
            if (!system.nodes[index]) {
                rows.push({
                    td_name: address,
                    td_address: address,
                    td_status: t("Never detected"),
                    td_commisioned: "-",
                    td_sensors: "",
                });
                continue;
            }

            // Real, Existing ones
            const branchNode = {
                ...system.nodes[index],
            };

            rows.push({
                td_name: branchNode.clientRef || branchNode.address,
                td_address: branchNode.address,
                td_status: nodeStatus(branchNode, system),
                td_commisioned: branchNode.commissioned
                    ? timeAgo(branchNode.commissionedAt)
                    : "-", // Not commissioned
                td_sensors: getSensorStatus(branchNode),
                node: branchNode,
            });
        }
        return rows;
    };

    const getExtraAislesRows = () => {
        const rows = [];

        if (!config.noAisles) {
            return rows;
        }

        for (
            let index = config.noAisles;
            index < system.nodes.length;
            index++
        ) {
            const branchNode = {
                ...system.nodes[index],
            };

            rows.push({
                td_name: branchNode.clientRef || branchNode.address,
                td_address: branchNode.address,
                td_status: nodeStatus(branchNode, system),
                td_commisioned: branchNode.commissioned
                    ? timeAgo(branchNode.commissionedAt)
                    : "-", // Not commissioned
                td_sensors: getSensorStatus(branchNode),
                node: branchNode,
            });
        }
        return rows;
    };

    const rows = getAislesRows();
    const extraRows = getExtraAislesRows();

    return (
        <Box>
            <AisleTable
                rows={rows}
                selectedNode={selectedNode}
                selectNode={selectNode}
            />

            <div className={classNames("mt-3 text-center")}>
                <AislesSectionBottom
                    system={system}
                    remapCallback={remapCallback}
                />
            </div>

            {extraRows.length > 0 && (
                <>
                    <div className="mt-3 p-2 rounded flex justify-between text-sm bg-red-500 text-white">
                        <div>{t("NON_COMMISSIONED_AISLES_DETECTED")}</div>
                        <div>{t("ACTION_UPDATE_SYSTEM_CONFIGURATION")}</div>
                    </div>
                    <AisleTable
                        rows={extraRows}
                        selectedNode={selectedNode}
                        selectNode={selectNode}
                        className={"opacity-65"}
                        clickable={false}
                    />
                </>
            )}
        </Box>
    );
};

export default AislesSection;
