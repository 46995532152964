import { useLocation, useParams } from "react-router-dom";

import { QUERY_ISSUE, QUERY_ISSUES } from "../graphql/queries";
import PageIssues from "../components/pages/issues/Issues";
import PageIssueView from "../components/pages/issue/IssueView";
import PageIssueNew from "../components/pages/issue/IssueNew";
import { ErrorComponent, NotFoundPage } from "../components/pages/Error";
import { useUser } from "../components/context/UserContext";
import { useQueryWithParams } from "../graphql/useQueryWithParams";

interface QueryIssuesData {
    organisation: {
        issues: Connection<Issue>;
    };
}

const IssuesRoute = () => {
    const query = new URLSearchParams(useLocation().search);

    const limit = 50;
    const page = parseInt(query.get("page") || "1", 10);

    const { loading, error, data, refetch } = useQueryWithParams<
        QueryIssuesData,
        { limit: number; offset: number }
    >({
        query: QUERY_ISSUES,
        variables: { limit: limit, offset: (page - 1) * limit },
        accessScope: "admin",
    });

    if (loading) return null;

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    return <PageIssues issues={data.organisation.issues} refetch={refetch} />;
};

const IssueViewRoute = () => {
    const { number } = useParams<{
        number: string;
    }>();

    const { loading, error, data, refetch } = useQueryWithParams<
        QueryIssuesData,
        { number: number }
    >({
        query: QUERY_ISSUE,
        variables: { number: parseInt(number, 10) },
        accessScope: "admin",
    });

    if (loading) return null;

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    const issue = data.organisation.issues.edges[0].node || null;

    if (!issue) {
        return <NotFoundPage />;
    }

    return <PageIssueView issue={issue} />;
};

const IssueNewRoute = () => {
    const { hasScope } = useUser();

    if (!hasScope("admin")) {
        return <ErrorComponent title="Not Found" error="Not allowed!" />;
    }

    return <PageIssueNew />;
};

export { IssuesRoute, IssueViewRoute, IssueNewRoute };
