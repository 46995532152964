import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApolloQueryResult } from "@apollo/client";
import { HiCheckCircle, HiExclamationCircle } from "react-icons/hi";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import Tabs from "../../layout/Tabs";
import { Box, Boxes, Col, Grid } from "../../layout/Boxes";
import { Content, ContentTop } from "../../layout/Content";
import { Tag } from "../../layout/Tag";
import SamplePieHealthScore from "../../samples/SamplePieHealthScore";
import Modal from "../../layout/Modal";
import ConfigureSiteForm from "./ConfigureSiteForm";
import AddSystemForm from "./AddSystemForm";
import { emitAnalyticsEvent, generateColor, setAnalyticsEventProps } from "../../../helpers/utils";
import DropdownSites from "../../layout/Breadcrumbs/DropdownSites";
import IssueMonitoringBox from "./IssueMonitoring";
import TableSystems from "./TableSystems";
import IssuesSection from "./Issues";
import { useUser } from "../../context/UserContext";
import Permission from "../../layout/Permision";

export type PageSiteProps = {
    site: CoreSite;
    systems: Connection<CoreSystem>;
    issues?: Connection<CoreIssue>;
    refetch: () => Promise<ApolloQueryResult<any>>;
};

type ViewStep = "no-config" | "no-systems" | "full";

const PageSite = ({ site, systems, issues, refetch }: PageSiteProps) => {
    const { t } = useTranslation();
    const { hasScope } = useUser();

    const [show] = useState(false);

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        // { label: t("Sites"), href: "/sites" },
        <DropdownSites siteName={site.name} />,
        { label: site.name },
    ];

    const tabPaths = [
        { label: t("Overview"), href: "#" },
        // { label: t("Historic Data"), href: "#history" },
        { label: t("Site Info"), href: "#info" },
    ];

    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.hash || "");

    const getViewStep = () => {
        if (site.configNoSystems === 0 || site.configNoAisles === 0) {
            return "no-config";
        } else if (systems.edges.length === 0) {
            return "no-systems";
        } else {
            return "full";
        }
    };

    const [viewStep, setViewStep] = useState<ViewStep>(() => getViewStep());

    const [isConfigurationModalOpen, setConfigurationModalOpen] = useState(
        viewStep === "no-config"
    );
    const closeConfigurationModal = () => setConfigurationModalOpen(false);

    const [isAddSystemModalOpen, setAddSystemModalOpen] = useState(
        viewStep === "no-systems"
    );
    const closeAddSystemModal = () => setAddSystemModalOpen(false);

    let uiNoColumns = issues.edges.length ? 2 : 1;
    if (!hasScope("superadmin")) {
        uiNoColumns = 1;
    }

    useEffect(() => {
        setActiveTab(location.hash || "");
    }, [location]);

    const pathChanged = useRef(false);
    useEffect(() => {
        pathChanged.current = true;
    }, [location.pathname]);

    useEffect(() => {
        if (pathChanged.current) {
            pathChanged.current = false;
            setAnalyticsEventProps(site);
            emitAnalyticsEvent("site-open");
        }
    }, [site, pathChanged]);

    useEffect(() => {
        const newStep = getViewStep();
        if (newStep !== viewStep) {
            setViewStep(newStep);
        }
    }, [site, systems, viewStep]);

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />

            <ContentTop h1={site.name}>
                <div className="border border-stone-300 rounded p-1 buttons">
                    {/* <button className="button indigo w-32">Status</button> */}

                    <button
                        id="btn-site-open-config"
                        className="button light w-40"
                        onClick={() => setConfigurationModalOpen(true)}
                    >
                        {t("Site Configuration")}
                    </button>
                    <Modal
                        title={t("Configure Site ") + site.name}
                        children={
                            <ConfigureSiteForm
                                closeModal={closeConfigurationModal}
                                site={site}
                                refetch={refetch}
                            />
                        }
                        isOpen={isConfigurationModalOpen}
                        onClose={closeConfigurationModal}
                        size="max-w-xl"
                    />
                    <button
                        id="btn-site-open-add-system-modal"
                        className="button light w-40"
                        onClick={() => setAddSystemModalOpen(true)}
                    >
                        {t("+ Add System")}
                    </button>
                    <Modal
                        title={t("Add System")}
                        children={
                            <AddSystemForm
                                closeModal={closeAddSystemModal}
                                site={site}
                                refetch={refetch}
                            />
                        }
                        isOpen={isAddSystemModalOpen}
                        onClose={closeAddSystemModal}
                        size="max-w-lg"
                    />
                </div>
            </ContentTop>

            <div className="flex justify-between flex-wrap-reverse gap-3">
                <Tabs paths={tabPaths} activeTab={activeTab} />
            </div>

            {/* <Boxes
                className={classNames(activeTab === "#history" ? "" : "hidden")}
            >
                <Box>{t("Historic Data Box")}</Box>
            </Boxes> */}

            <Boxes
                className={classNames(activeTab === "#info" ? "" : "hidden")}
            >
                <Box title={t("Info")}>
                    <table className="table-auto w-full my-3">
                        <tbody>
                            <tr>
                                <td>{t("Name")}</td>
                                <td>{site.name}</td>
                            </tr>
                            <tr>
                                <td>{t("Region")}</td>
                                <td>{site.region}</td>
                            </tr>
                            <tr>
                                <td>{t("Location")}</td>
                                <td>{site.location}</td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
                <Box title={t("Configuration")}>
                    <table className="table-auto w-full my-3">
                        <tbody>
                            <tr>
                                <td>{t("Number of Systems")}</td>
                                <td>{site.configNoSystems}</td>
                            </tr>
                            <tr>
                                <td>{t("Aisles per System")}</td>
                                <td>{site.configNoAisles}</td>
                            </tr>
                            <tr>
                                <td>{t("Bag (4T) Columns per Aisle")}</td>
                                <td>{site.configNoColumns}</td>
                            </tr>
                            <tr>
                                <td>{t("OV Columns per Aisle")}</td>
                                <td>{site.configNoColumnsOv}</td>
                            </tr>
                            <tr>
                                <td>{t("Sensors per Bag (4T) Column")}</td>
                                <td>{site.configNoSensors}</td>
                            </tr>
                            <tr>
                                <td>{t("Sensors per OV")}</td>
                                <td>{site.configNoSensorsOv}</td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </Boxes>

            <Grid
                className={classNames(activeTab === "" ? "" : "hidden")}
                cols={uiNoColumns}
            >
                <Permission scopes="superadmin">
                    <div>
                        <IssuesSection issues={issues} refetch={refetch} />
                    </div>
                </Permission>

                {/* Left Side */}
                {show && (
                    <Col className="overlay-container">
                        <div className="overlay hidden">
                            {t(
                                "This features are not available for your account."
                            )}{" "}
                            <br />
                            {t("Please upgrade to a different plan.")}
                        </div>
                        <Grid cols={2} className="overlay-content">
                            <Col>
                                <Box
                                    title={t("Health Score")}
                                    bottom={
                                        <div className="text-center">
                                            {t("Last 24h")}
                                        </div>
                                    }
                                >
                                    <div className="flex items-center justify-center">
                                        <SamplePieHealthScore
                                            data={[
                                                {
                                                    name: `${site.healthScore}%`,
                                                    value: site.healthScore,
                                                },
                                                {
                                                    name: `${100 - site.healthScore}%`,
                                                    value:
                                                        100 - site.healthScore,
                                                },
                                            ]}
                                        />
                                    </div>
                                </Box>
                            </Col>
                            <Col>
                                <Box
                                    title={t("Key Info")}
                                    bottom={t("Last Updated: few minutes ago")}
                                >
                                    <div className="flex flex-col gap-4 mt-4">
                                        {site.issues.length ? (
                                            <Tag
                                                className="text-red-500"
                                                icon={HiExclamationCircle}
                                            >
                                                {t("COUNT_ISSUES_DETECTED", {
                                                    count: site.issues.length,
                                                })}
                                            </Tag>
                                        ) : (
                                            <Tag
                                                className="text-green-700"
                                                icon={HiCheckCircle}
                                            >
                                                {t("No issue detected")}
                                            </Tag>
                                        )}
                                        {/* {site.softwareIsUpToDate ? (
                                        <Tag
                                            className="text-green-700"
                                            icon={HiCheckCircle}
                                        >
                                            {t("All software is up to date")}
                                        </Tag>
                                    ) : (
                                        <Tag
                                            className="text-red-500"
                                            icon={HiExclamationCircle}
                                        >
                                            Updates are missing
                                        </Tag>
                                    )} */}

                                        <div className="flex justify-between">
                                            <div>{t("Status")}</div>
                                            <div>
                                                <Tag
                                                    bgColor={generateColor(
                                                        site.status ||
                                                            t("No Status")
                                                    )}
                                                >
                                                    {site.status ||
                                                        t("No Status")}
                                                </Tag>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Col>
                        </Grid>

                        <IssueMonitoringBox site={site} />

                        <div className="h-80"></div>
                    </Col>
                )}

                {/* Right Side */}
                <Col>
                    <Box
                        className={classNames(
                            viewStep === "no-config" ? "" : "hidden"
                        )}
                    >
                        <div className="flex justify-center items-center h-full">
                            <button
                                className="button info w-48 h-12"
                                onClick={() => setConfigurationModalOpen(true)}
                            >
                                {t("Configure Site")}
                            </button>
                        </div>
                    </Box>

                    <Box
                        className={classNames(
                            viewStep === "no-systems" ? "" : "hidden"
                        )}
                    >
                        <div className="flex justify-center items-center h-full">
                            <button
                                className="button info w-48 h-12"
                                onClick={() => setAddSystemModalOpen(true)}
                            >
                                {t("Add System")}
                            </button>
                        </div>
                    </Box>

                    <Box
                        title={t("Clusters")}
                        className={classNames(
                            viewStep === "full" ? "" : "hidden"
                        )}
                    >
                        <TableSystems
                            systems={systems}
                            site={site}
                            refetch={refetch}
                        />
                    </Box>
                </Col>
            </Grid>
        </Content>
    );
};

export default PageSite;
