import React from "react";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { MUTATION_CONFIGURE_SYSTEM } from "../../../graphql/mutations";
import { useLoading } from "../../context/LoadingContext";
import { Form, InputText } from "../../layout/Form";
import { configValue } from "../../../helpers/utils";
import classNames from "classnames";

type Errors = {
    form?: string;
    name?: string;
    configNoAisles?: string;
};

interface FormState {
    name: string | null;
    configNoAisles: number | null;
}

interface ConfigureClusterProps {
    site: CoreSite;
    cluster: {
        macAddress: string;
        name: string;
        clientRef: string | null;
        aisles: number;
    };
    closeModal: () => void;
    refetch: () => void;
}

export default function ConfigureClusterForm({
    site,
    cluster,
    closeModal,
    refetch,
}: ConfigureClusterProps) {
    const { t } = useTranslation();
    const defaultFormState = {
        name: cluster.clientRef,
        configNoAisles: cluster.aisles,
    };

    const [formState, setFormState] = useState<FormState>(defaultFormState);

    const [errors, setErrors] = useState<Errors>({});

    const { setLoading } = useLoading();

    const [submitFormMutation] = useMutation(MUTATION_CONFIGURE_SYSTEM);

    const handleInputTextChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleInputNumberChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        const numericValue = Number(value);
        setFormState((prevState) => ({
            ...prevState,
            [name]: value === "" ? "" : numericValue < 0 ? "" : numericValue,
        }));
    };

    const validate = () => {
        const newErrors: Errors = {};
        if (!formState.name) {
            newErrors.name = t("Controller Location is required");
        }
        return newErrors;
    };

    const handleSubmit = ({ reset = false }) => {
        const errors = validate();
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);

        const state = {
            name: formState.name || "",
            configNoAisles: configValue(
                formState.configNoAisles,
                site.configNoAisles
            ),
        };

        if (reset === true) {
            console.log("Resetting to defaults");
            state.configNoAisles = site.configNoAisles;
        }

        if (JSON.stringify(state) === JSON.stringify(defaultFormState)) {
            console.log("No changes over the System configuration");
            setLoading(false);
            closeModal();
            return;
        }

        setFormState((prevState) => ({
            ...prevState,
            ...state,
        }));

        submitFormMutation({
            variables: {
                macAddress: cluster.macAddress,
                ...state,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                refetch();
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
                setErrors({ ...errors, form: "Something went wrong" });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Form error={errors.form}>
            <InputText
                label={t("Controller Location (e.g. A1, D6, ...)")}
                error={errors.name}
                props={{
                    name: "name",
                    required: true,
                    value: formState.name,
                    onChange: handleInputTextChange,
                }}
            />

            <InputText
                label={t("Number of Aisles")}
                error={errors.configNoAisles}
                props={{
                    name: "configNoAisles",
                    type: "number",
                    min: "0",
                    value: formState.configNoAisles,
                    onChange: handleInputNumberChange,
                    placeholder: site.configNoAisles.toString(),
                }}
            />

            <div className="buttons justify-end">
                <button
                    onClick={closeModal}
                    className={classNames("button light w-32")}
                >
                    {t("Close")}
                </button>

                <button
                    id="btn-cluster-save-config"
                    type="button"
                    className="button info w-32"
                    onClick={() => handleSubmit({ reset: false })}
                >
                    {t("Update")}
                </button>
            </div>
        </Form>
    );
}
