import { useState } from "react";
import classNames from "classnames";
import { HiOutlineCog } from "react-icons/hi";

import ModalSmartCartSettings from "./modals/ModalSmartCartSettings";

export default function SmartCartSettings({ system }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <button
                className={classNames(
                    "flex justify-center items-center gap-2 bg-white text-sm py-2 px-4 border border-sky-600 rounded",
                    "text-sky-600 hover:text-sky-950 hover:border-sky-950"
                )}
                onClick={() => setIsModalOpen(true)}
            >
                <HiOutlineCog fontSize={24} />
                Settings
            </button>

            <ModalSmartCartSettings
                system={system}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
        </>
    );
}
