import { useRef, useState } from "react";
import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useMutation } from "@apollo/client";

import TailwindForm from "../../../rjsf";
import Modal from "../../../layout/Modal";
import { useLoading } from "../../../context/LoadingContext";
import { MUTATION_BUZZ_NODE } from "../../../../graphql/mutations";

const schema: RJSFSchema = {
    title: "Node Buzz",
    type: "object",
    required: ["frequencyHz", "durationMs", "duty"],
    properties: {
        frequencyHz: {
            type: "integer",
            title: "Frequency (Hz)",
        },
        durationMs: {
            type: "integer",
            title: "Duration (Ms)",
        },
        duty: {
            type: "number",
            title: "Duty",
        },
    },
};
const uischema = {};

type ModalNodeBuzzProps = {
    system: CoreSystem;
    selectedNode: CoreNode;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
};

export default function ModalNodeBuzz({
    system,
    selectedNode,
    isOpen,
    setIsOpen,
}: ModalNodeBuzzProps) {
    const formRef = useRef<any>(null);
    const [submitFormMutation] = useMutation(MUTATION_BUZZ_NODE);
    const [formData, setFormData] = useState({});
    const { setLoading } = useLoading();

    if (!system || !selectedNode) {
        return null;
    }

    const handleSubmit = (ev) => {
        setLoading(true);
        submitFormMutation({
            variables: {
                macAddress: system.macAddress,
                address: selectedNode.address,
                frequencyHz: ev.formData.frequencyHz,
                durationMs: ev.formData.durationMs,
                duty: ev.formData.duty,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                setFormData(ev.formData);
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeModal = () => setIsOpen(false);

    return (
        <Modal
            children={
                <>
                    <TailwindForm
                        schema={schema}
                        uiSchema={uischema}
                        formData={formData}
                        onSubmit={handleSubmit}
                        validator={validator}
                        noHtml5Validate
                        ref={formRef}
                        children={true}
                        showErrorList={false}
                        focusOnFirstError={true}
                    />
                    <div className="flex flex-row gap-2 justify-end border-t pt-5">
                        <button
                            className="button light"
                            type="button"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="button info"
                            type="button"
                            onClick={() => formRef.current.submit()}
                        >
                            Execute
                        </button>
                    </div>
                </>
            }
            isOpen={isOpen}
            onClose={closeModal}
            size="max-w-md"
        />
    );
}
