import { gql } from "@apollo/client";

const QUERY_SYSTEM = gql`
    query System($macAddress: String!) {
        system(macAddress: $macAddress) {
            hostname
            macAddress
            status
            reachable
            clientRef
            siteReference
            configNoAisles
            heartbeatCommsAt
            heartbeatServiceAt
            softwareStatus
            health {
                percentage
            }
            healthScore
            containers {
                name
                image
                started
            }
            networkInterfaces
            nodes {
                address
                status
                reachable
                clientRef
                category
                configNoColumns
                configNoColumnsOv
                hardwareVersion
                firmwareVersion
                deleted
                serial
                nodes {
                    address
                    status
                    reachable
                    clientRef
                    category
                    hardwareVersion
                    firmwareVersion
                    deleted
                    serial
                    peripherals {
                        address
                        clientRef
                        model
                        status
                        reachable
                        updatedAt
                        deleted
                    }
                }
            }
        }
    }
`;

const QUERY_SYSTEM_SMARTSTOW_SETTINGS = gql`
    query SystemSmartStowSettings($macAddress: String!) {
        system(macAddress: $macAddress) {
            macAddress
            smartstow {
                settings
            }
        }
    }
`;

const QUERY_SYSTEM_SMARTCART_SETTINGS = gql`
    query SystemSmartStowSettings($macAddress: String!) {
        system(macAddress: $macAddress) {
            macAddress
            smartcart {
                settings
            }
        }
    }
`;

const QUERY_SYSTEM_STATUS = gql`
    query SystemStatus($macAddress: String!) {
        system(macAddress: $macAddress) {
            macAddress
            liveStatus
        }
    }
`;

const QUERY_SYSTEMS = gql`
    query Systems($limit: Int, $offset: Int, $search: String) {
        systems(limit: $limit, offset: $offset, search: $search) {
            edges {
                node {
                    macAddress
                    hostname
                    status
                    reachable
                    clientRef
                    siteReference
                    heartbeatCommsAt
                    heartbeatServiceAt
                    health {
                        percentage
                    }
                    healthScore
                }
            }
            pageInfo {
                limit
                offset
                totalCount
            }
        }
    }
`;

const QUERY_USERS = gql`
    query AcountUsers($limit: Int, $offset: Int) {
        account {
            reference
            users(limit: $limit, offset: $offset) {
                edges {
                    node {
                        email
                        role
                        sites
                        expiresAt
                    }
                }
                pageInfo {
                    limit
                    offset
                    totalCount
                }
            }
        }
    }
`;

const QUERY_SITE_ISSUES = gql`
    query Issues($siteCode: String!) {
        organisation {
            issuesv2(
                filters: { site: { ilike: $siteCode }, status: { eq: Raised } }
            ) {
                edges {
                    node {
                        entityId
                        devicesAffected
                        clientUserRef
                        entityType
                        issueType
                        number
                        raisedAt
                        severity
                        site
                        system
                    }
                }
            }
        }
    }
`;

const QUERY_ISSUES = gql`
    query Issues($limit: Int!, $offset: Int!) {
        organisation {
            issues(limit: $limit, offset: $offset) {
                edges {
                    node {
                        number
                        severity
                        raisedAt
                        status
                        legacyNumber
                        site
                        title
                    }
                }
                pageInfo {
                    limit
                    offset
                    totalCount
                }
            }
        }
    }
`;

const QUERY_ISSUE = gql`
    query Issue($number: [Int!]!) {
        organisation {
            issues(filters: { number: { eq: $number } }) {
                edges {
                    node {
                        number
                        legacyNumber
                        title
                        raisedBy
                        raisedAt
                        severity
                        description
                        status
                        site
                        location
                        clientUserRef
                        system
                        hostname
                        resolution
                        troubleshootingCorrelationId
                        acknowledgeAt
                        resolvedAt
                        log {
                            time
                            message
                        }
                    }
                }
            }
        }
    }
`;

const QUERY_PROJECTS = gql`
    query Projects($limit: Int!, $offset: Int!) {
        maxilux {
            projects(limit: $limit, offset: $offset) {
                edges {
                    node {
                        uuid
                        name
                        code
                        status
                    }
                }
                pageInfo {
                    limit
                    offset
                    totalCount
                }
            }
        }
    }
`;

const QUERY_PROJECT = gql`
    query Project($code: String!) {
        maxilux {
            project(code: $code) {
                uuid
                name
                code
                status
                softwareIsUpToDate
                status
                sites {
                    name
                    reference
                    region
                    location
                    status
                    healthScore
                    issues {
                        type
                        entities
                        attributes
                    }
                }
            }
        }
    }
`;

const QUERY_SITES = gql`
    query Sites($limit: Int!, $offset: Int!) {
        account {
            reference
            sites(limit: $limit, offset: $offset) {
                edges {
                    node {
                        name
                        reference
                        region
                        location
                        healthScore
                        status
                    }
                }
                pageInfo {
                    limit
                    offset
                    totalCount
                }
            }
        }
    }
`;

const QUERY_SITE = gql`
    query Site($code: String!) {
        account {
            reference
            site(reference: $code) {
                name
                reference
                region
                location
                maintenanceMode
                configNoSystems
                configNoAisles
                configNoColumns
                configNoColumnsOv
                configNoSensors
                configNoSensorsOv
                healthScore
                softwareIsUpToDate
                issues {
                    type
                    entities
                    attributes
                }
                status
            }
        }
        systems(search: $code) {
            edges {
                node {
                    macAddress
                    hostname
                    status
                    reachable
                    clientRef
                    commissioned
                    commissionedPercent
                    health {
                        percentage
                    }
                    healthScore
                }
            }
        }
        organisation {
            issuesv2(
                filters: { site: { ilike: [$code] }, status: { ne: Resolved } }
            ) {
                edges {
                    node {
                        entityId
                        devicesAffected
                        clientUserRef
                        entityType
                        issueType
                        number
                        raisedAt
                        severity
                        site
                        system
                        status
                    }
                }
            }
        }
    }
`;

const QUERY_ISSUE_V2 = gql`
    query Site($number: [Int!]!) {
        organisation {
            issuesv2(filters: { number: { eq: $number } }) {
                edges {
                    node {
                        entityId
                        devicesAffected
                        clientUserRef
                        entityType
                        issueType
                        number
                        raisedAt
                        severity
                        site
                        system
                        status
                    }
                }
            }
        }
    }
`;

const QUERY_CLUSTER = gql`
    query Cluster($macAddress: String!, $site: String!) {
        account {
            reference
            site(reference: $site) {
                name
                reference
                configNoSystems
                configNoAisles
                configNoColumns
                configNoColumnsOv
                configNoSensors
                configNoSensorsOv
            }
        }
        system(macAddress: $macAddress) {
            hostname
            macAddress
            status
            maintenanceMode
            reachable
            clientRef
            siteReference
            configNoAisles
            expectedNoSensors
            heartbeatCommsAt
            heartbeatServiceAt
            commissioned
            commissionedAt
            health {
                percentage
            }
            healthScore
            softwareStatus
            nodes {
                address
                status
                clientRef
                category
                configNoColumns
                configNoColumnsOv
                configNoSensors
                configNoSensorsOv
                commissioned
                commissionedAt
                reachable
                hardwareVersion
                firmwareVersion
                deleted
                mappedPeripherals {
                    clientRef
                    model
                    status
                    aliasSensorIndex
                    updatedAt
                    reachable
                    aliasAddress
                    aliasSensorIndex
                    parentStatus
                }
            }
        }
    }
`;

const QUERY_GUIDES = gql`
    query Guides {
        maxilux {
            guides {
                edges {
                    node {
                        uuid
                        name
                        type
                        version
                        lastUpdate
                    }
                }
                pageInfo {
                    limit
                    offset
                    totalCount
                }
            }
        }
    }
`;

const QUERY_GUIDE_STEPS = gql`
    query GuideSteps($uuid: String!) {
        maxilux {
            guideSteps(uuid: $uuid) {
                uuid
                content
            }
        }
    }
`;

export {
    QUERY_SYSTEM,
    QUERY_SYSTEMS,
    QUERY_SYSTEM_SMARTSTOW_SETTINGS,
    QUERY_SYSTEM_SMARTCART_SETTINGS,
    QUERY_SYSTEM_STATUS,
    QUERY_USERS,
    QUERY_SITE_ISSUES,
    QUERY_ISSUES,
    QUERY_ISSUE,
    QUERY_ISSUE_V2,
    QUERY_PROJECTS,
    QUERY_PROJECT,
    QUERY_SITES,
    QUERY_SITE,
    QUERY_CLUSTER,
    QUERY_GUIDES,
    QUERY_GUIDE_STEPS,
};
