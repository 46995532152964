import { useState } from "react";
import { useTranslation } from "react-i18next";

import { formatDate, generateColor } from "../../../helpers/utils";
import { Content, ContentTop } from "../../layout/Content";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import { Box, Boxes } from "../../layout/Boxes";
import Modal from "../../layout/Modal";
import Tabs from "../../layout/Tabs";
import { Tag } from "../../layout/Tag";
import ViewGuide from "./ViewGuide";
import { useUser } from "../../context/UserContext";

interface PageSupportProps {
    guides: Connection<Guide>;
}

const PageSupport = ({ guides }: PageSupportProps) => {
    const { t } = useTranslation();
    const { hasScope } = useUser();

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        { label: "Support" },
    ];
    const tabPaths = [{ label: "Resources", href: "#" }];

    if (hasScope("admin")) {
        tabPaths.push({ label: "Support Tickets", href: "/support/issues" });
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeViewModal = () => setIsModalOpen(false);

    const [selectedGuide, selectGuide] = useState<Guide | null>(null);

    const openViewModal = (guide: Guide) => {
        selectGuide(guide);
        setIsModalOpen(true);
    };

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />
            <ContentTop h1="Support" />
            <Tabs paths={tabPaths} activeTab="#" />

            <Boxes>
                {guides.edges.map(({ node }) => (
                    <Box
                        key={node.uuid}
                        title={`${node.name}`}
                        className="flex-none w-full md:w-[calc(33.333%-1rem)]"
                    >
                        <div className="flex gap-1 my-1">
                            <Tag bgColor={generateColor(node.type)}>
                                {node.type}
                            </Tag>
                            {node.version &&
                                node.version
                                    .split(" ")
                                    .map((version) => (
                                        <Tag bgColor={generateColor(version)}>
                                            {version}
                                        </Tag>
                                    ))}
                        </div>
                        <div className="flex justify-between py-1">
                            <div className="text-sm">
                                Last updated: {formatDate(node.lastUpdate)}
                            </div>
                            <div>
                                <button
                                    className="button light"
                                    onClick={() => openViewModal(node)}
                                >
                                    View Guide
                                </button>
                            </div>
                        </div>
                    </Box>
                ))}
                <Modal
                    isOpen={isModalOpen}
                    onClose={closeViewModal}
                    size="max-w-4xl"
                    title={selectedGuide ? selectedGuide.name : ""}
                >
                    <ViewGuide
                        uuid={selectedGuide ? selectedGuide.uuid : null}
                    />
                </Modal>
            </Boxes>
        </Content>
    );
};

export default PageSupport;
