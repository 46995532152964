import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { MUTATION_CONFIGURE_SITE } from "../../../graphql/mutations";
import { useLoading } from "../../context/LoadingContext";
import { Form, InputText } from "../../layout/Form";
import { configValue } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";

type Errors = {
    form?: string;
    configNoSystems?: string;
    configNoAisles?: string;
    configNoColumns?: string;
    configNoColumnsOv?: string;
    configNoSensors?: string;
    configNoSensorsOv?: string;
};

interface FormState {
    configNoSystems: number | null;
    configNoAisles: number | null;
    configNoColumns: number | null;
    configNoColumnsOv: number | null;
    configNoSensors?: number | null;
    configNoSensorsOv?: number | null;
}

interface ConfigureSiteProps {
    site: CoreSite;
    closeModal: () => void;
    refetch: () => void;
}

export default function ConfigureSiteForm({
    site,
    closeModal,
    refetch,
}: ConfigureSiteProps) {
    const [formState, setFormState] = useState<FormState>({
        configNoSystems: site.configNoSystems,
        configNoAisles: site.configNoAisles,
        configNoColumns: site.configNoColumns,
        configNoColumnsOv: site.configNoColumnsOv,
        configNoSensors: site.configNoSensors,
        configNoSensorsOv: site.configNoSensorsOv,
    });
    const { t } = useTranslation();

    const [errors, setErrors] = useState<Errors>({});

    const { setLoading } = useLoading();

    const [submitFormMutation] = useMutation(MUTATION_CONFIGURE_SITE);

    const handleInputNumberChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        const numericValue = Number(value);
        setFormState((prevState) => ({
            ...prevState,
            [name]: value === "" ? "" : numericValue < 0 ? "" : numericValue,
        }));
    };

    const validate = () => {
        const newErrors: Errors = {};
        return newErrors;
    };

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        const errors = validate();
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);

        submitFormMutation({
            variables: {
                site: site.reference,
                configNoSystems: configValue(formState.configNoSystems, 0),
                configNoAisles: configValue(formState.configNoAisles, 0),
                configNoColumns: configValue(formState.configNoColumns, 0),
                configNoColumnsOv: configValue(formState.configNoColumnsOv, 0),
                configNoSensors: configValue(formState.configNoSensors, 2),
                configNoSensorsOv: configValue(formState.configNoSensorsOv, 4),
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                refetch();
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
                setErrors({ ...errors, form: "Something went wrong" });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Form props={{ onSubmit: handleSubmit }} error={errors.form}>
            <InputText
                label={t("Number of Systems")}
                error={errors.configNoSystems}
                props={{
                    name: "configNoSystems",
                    type: "number",
                    min: "0",
                    required: true,
                    value: formState.configNoSystems,
                    onChange: handleInputNumberChange,
                }}
            />
            <InputText
                label={t("Aisles per System")}
                error={errors.configNoAisles}
                props={{
                    name: "configNoAisles",
                    type: "number",
                    min: "0",
                    required: true,
                    value: formState.configNoAisles,
                    onChange: handleInputNumberChange,
                }}
            />
            <div className="mt-4">
                <b>{t("Bag (4T) Columns")}</b>
                <div className="flex justify-between gap-4">
                    <InputText
                        label={t("Columns per Aisle")}
                        error={errors.configNoColumns}
                        props={{
                            name: "configNoColumns",
                            type: "number",
                            min: "0",
                            value: formState.configNoColumns,
                            onChange: handleInputNumberChange,
                        }}
                        className="w-1/2"
                    />
                    <InputText
                        label={t("Sensors per Column")}
                        error={errors.configNoSensors}
                        props={{
                            name: "configNoSensors",
                            type: "number",
                            min: "0",
                            value: formState.configNoSensors,
                            onChange: handleInputNumberChange,
                            placeholder: "2",
                        }}
                        className="w-1/2"
                    />
                </div>
            </div>
            <div className="mt-2">
                <b>{t("OV Columns")}</b>
                <div className="flex justify-between gap-4">
                    <InputText
                        label={t("OV per Aisle")}
                        error={errors.configNoColumnsOv}
                        props={{
                            name: "configNoColumnsOv",
                            type: "number",
                            min: "0",
                            value: formState.configNoColumnsOv,
                            onChange: handleInputNumberChange,
                        }}
                        className="w-1/2"
                    />

                    <InputText
                        label={t("Sensors per OV")}
                        error={errors.configNoSensors}
                        props={{
                            name: "configNoSensorsOv",
                            type: "number",
                            min: "0",
                            value: formState.configNoSensorsOv,
                            onChange: handleInputNumberChange,
                            placeholder: "4",
                        }}
                        className="w-1/2"
                    />
                </div>
            </div>

            <div className="buttons justify-end">
                <button className="button light" onClick={closeModal}>
                    {t("Close")}
                </button>
                <button id="btn-site-save-config" type="submit" className="button info">
                    {t("Update")}
                </button>
            </div>
        </Form>
    );
}
