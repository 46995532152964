import {
    HiOutlineViewGrid,
    HiOutlineCube,
    HiOutlineServer,
    HiOutlineUsers,
    HiTemplate,
} from "react-icons/hi";
import { TfiHeadphoneAlt } from "react-icons/tfi";

export const DASHBOARD_SIDEBAR_LINKS = [
    {
        key: "dashboars",
        label: "Dashboard",
        path: "/",
        icon: <HiTemplate />,
        scopes: ["admin", "installer", "maintainer"],
    },
    {
        key: "projects",
        label: "Projects",
        path: "/projects",
        icon: <HiOutlineViewGrid />,
        scopes: ["admin"],
    },
    {
        key: "sites",
        label: "Sites",
        path: "/sites",
        icon: <HiOutlineCube />,
        scopes: ["superadmin"],
    },
    {
        key: "systems",
        label: "Systems",
        path: "/systems",
        icon: <HiOutlineServer />,
        scopes: ["superadmin"],
    },
    {
        key: "resources",
        label: "Resources",
        path: "/support",
        icon: <TfiHeadphoneAlt />,
        scopes: ["superadmin"],
    },
    {
        key: "users",
        label: "Users",
        path: "/users",
        icon: <HiOutlineUsers />,
        scopes: ["admin"],
    },
    // {
    //     key: "templates",
    //     label: "Templates",
    //     path: "/templates",
    //     icon: <HiOutlineTemplate />,
    // },
];

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
    // {
    //     key: "settings",
    //     label: "Settings",
    //     path: "/settings",
    //     icon: <HiOutlineCog />,
    //     scopes: ["admin"],
    // },
];

export const SEVERITY_OPTIONS = [
    { value: "Severity1", label: "Severity1" },
    { value: "Severity2", label: "Severity2" },
    { value: "Severity3", label: "Severity3" },
];
