import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { HiOutlineLogout } from "react-icons/hi";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi2";

import {
    DASHBOARD_SIDEBAR_BOTTOM_LINKS,
    DASHBOARD_SIDEBAR_LINKS,
} from "../../constants";
import { getSignOutUrl } from "../../services/auth";
import Permission from "./Permision";

const linkClass =
    "flex items-center gap-2 rounded font-light px-3 py-2 hover:bg-neutral-700 hover:text-white hover:no-underline active:bg-neutral-600 rounded-sm text-base";

export default function Sidebar() {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => setIsOpen(!isOpen);

    return (
        <div
            className={classNames(
                "bg-white flex flex-col",
                isOpen ? "w-40 md:w-52" : "w-10 md:w-14 items-center"
            )}
        >
            <div className="flex items-center justify-between">
                <Link
                    to="/"
                    title={t("Home")}
                    className="flex items-center gap-2 px-1 py-3 hover:no-underline"
                >
                    <img
                        src="/logo192b.png"
                        alt="MaxiluxSystems"
                        className={classNames(isOpen ? "w-10 h-10" : "w-8 h-8")}
                    />
                    {isOpen && (
                        <span className="text-neutral-600">Maxilux Portal</span>
                    )}
                </Link>
            </div>
            <div className={classNames("py-6 flex flex-1 flex-col gap-0.5")}>
                {DASHBOARD_SIDEBAR_LINKS.map((link) => (
                    <SidebarLink key={link.key} link={link} isOpen={isOpen} />
                ))}
            </div>

            <div
                className={classNames(
                    "flex flex-col gap-0.5 pt-2 border-t border-neutral-700"
                )}
            >
                {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => (
                    <SidebarLink key={link.key} link={link} isOpen={isOpen} />
                ))}
                <Link
                    to={getSignOutUrl()}
                    className={classNames(
                        linkClass,
                        "cursor-pointer text-red-500"
                    )}
                    title={t("Logout")}
                >
                    <span className="text-xl">
                        <HiOutlineLogout />
                    </span>
                    {isOpen && t("Logout")}
                </Link>
            </div>

            <div className="my-2 pt-2 border-t border-neutral-700">
                <button
                    onClick={toggleSidebar}
                    className={classNames(
                        linkClass,
                        "cursor-pointer text-neutral-600 w-full"
                    )}
                    title={t("Collapse")}
                >
                    <span className="text-xl">
                        {isOpen ? (
                            <HiChevronDoubleLeft />
                        ) : (
                            <HiChevronDoubleRight />
                        )}
                    </span>
                    {isOpen && t("Collapse")}
                </button>
            </div>

            {/* <div className="mt-2 pt-2 border-t border-neutral-700">
                {user && (
                    <>
                        <div className="flex gap-2 items-center">
                            <div
                                className="h-10 w-10 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                                style={
                                    user.picture
                                        ? {
                                              backgroundImage: `url("${user.picture}")`,
                                          }
                                        : {}
                                }
                            ></div>
                            {isOpen && (
                                <div className="pl-13 py-3">
                                    <div className="text-neutral-200 text-sm">
                                        {user.name}
                                    </div>
                                    <div className="text-neutral-400 text-xs">
                                        {user.email}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div> */}
        </div>
    );
}

function SidebarLink({ link, isOpen }) {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const isActive = (path) => {
        if (path === "/") {
            // special case for home
            return pathname === path;
        }
        return pathname.startsWith(path);
    };

    return (
        <Permission scopes={link.scopes}>
            <Link
                to={link.path}
                className={classNames(
                    isActive(link.path)
                        ? "bg-neutral-700 text-white"
                        : "text-neutral-600",
                    linkClass
                )}
                title={t(link.label)}
            >
                <span className="text-xl">{link.icon}</span>
                {isOpen && t(link.label)}
            </Link>
        </Permission>
    );
}
