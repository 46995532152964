import { useEffect, useState } from "react";

import { timeAgo } from "../../../helpers/utils";

const HeartbeatComponent = ({ system }) => {
    const [heartbeat, setHeartbeat] = useState(
        timeAgo(system.heartbeatCommsAt)
    );

    useEffect(() => {
        // Function to update the heartbeat display
        const updateHeartbeat = () => {
            setHeartbeat(timeAgo(system.heartbeatCommsAt));
        };

        // Update heartbeat every second
        const intervalId = setInterval(updateHeartbeat, 1000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [system.heartbeatCommsAt]);

    return <>{heartbeat}</>;
};

export default HeartbeatComponent;
