import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineCloudOff } from "react-icons/md";

import Modal from "../../layout/Modal";
import { generateColor, timeAgo } from "../../../helpers/utils";
import { useMutation, useQuery } from "@apollo/client";
import { useLoading } from "../../context/LoadingContext";
import {
    MUTATION_ISSUE_ADD_RESOLUTION,
    MUTATION_ISSUE_ESCALATE,
} from "../../../graphql/mutations";
import { useUser } from "../../context/UserContext";
import { Tag } from "../../layout/Tag";
import { QUERY_ISSUE_V2 } from "../../../graphql/queries";

export type IssuesSectionProps = {
    issues: Connection<CoreIssue>;
    refetch: () => void;
};

export type IssueSectionProps = {
    issue: CoreIssue;
    refetch: () => void;
};

const EscalteButton = ({ issue, refetch }: IssueSectionProps) => {
    const { user } = useUser();

    const [isModalOpen, setModalOpen] = useState(false);
    const closeModal = () => setModalOpen(false);

    const [submitFormMutation] = useMutation(MUTATION_ISSUE_ESCALATE);
    const { setLoading } = useLoading();

    const handleSubmit = () => {
        setLoading(true);
        submitFormMutation({
            variables: {
                email: user.email,
                number: issue.number,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                refetch();
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <button
                type="button"
                className="btn danger"
                onClick={() => setModalOpen(true)}
            >
                ESCALTE TO SEV1
            </button>
            {isModalOpen && (
                <Modal
                    title={`Issue #${issue.number} - ${issue.status}`}
                    children={
                        <>
                            <div className="flex my-3">
                                <div className=" w-[100px] flex items-center justify-center">
                                    <MdOutlineCloudOff className="text-red-600 font-extrabold text-4xl" />
                                </div>
                                <div className="flex justify-between w-full">
                                    Are you sure you want to escalate this issue
                                    to SEV1?
                                </div>
                            </div>
                            <div className="flex justify-end gap-4">
                                <button
                                    type="button"
                                    className="btn danger"
                                    onClick={handleSubmit}
                                >
                                    Escalate
                                </button>
                            </div>
                        </>
                    }
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    size="max-w-2xl"
                />
            )}
        </>
    );
};

const IssueAddResolution = ({ handleInputChange }) => {
    return (
        <textarea
            className="w-full h-24 p-2 border border-gray-300 rounded"
            placeholder="Add resolution"
            onChange={handleInputChange}
        />
    );
};

const IssueModal = ({ issueNumber, refetchPage, isModalOpen, closeModal }) => {
    const [resolution, setResolution] = useState("");

    const {
        data,
        loading,
        refetch: refetchIssue,
    } = useQuery(QUERY_ISSUE_V2, {
        variables: {
            number: issueNumber,
        },
        fetchPolicy: "network-only",
    });

    const [submitFormMutation] = useMutation(MUTATION_ISSUE_ADD_RESOLUTION);
    const { setLoading } = useLoading();

    const handleSubmit = () => {
        setLoading(true);
        submitFormMutation({
            variables: {
                number: issue.number,
                resolutionType: "",
                resolutionNotes: resolution,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                refetchPage();
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setResolution(event.target.value);
    };

    const refetch = () => {
        refetchIssue();
    };

    const handleAddresolution = () => {
        handleSubmit();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 3000);

        return () => clearInterval(interval);
    }, [refetch]);

    if (loading) return null;

    const issue = data.organisation.issuesv2.edges[0].node;

    return (
        <Modal
            title={`Issue #${issue.number} - ${issue.status}`}
            children={
                <>
                    <div className="flex my-3">
                        <div className=" w-[100px] flex items-center justify-center">
                            <MdOutlineCloudOff className="text-red-600 font-extrabold text-4xl" />
                        </div>
                        <div className="w-full">
                            <div className="flex justify-between w-full">
                                <div className="flex-1 p-2">
                                    <div className="my-2">
                                        Device type:{" "}
                                        <strong>{issue.entityType}</strong>
                                    </div>
                                    <div className="my-2">
                                        Last seen:{" "}
                                        <strong>
                                            {timeAgo(issue.raisedAt)}
                                        </strong>
                                    </div>
                                </div>
                                <div className="flex-1 p-2">
                                    <div className="my-2">
                                        Location:{" "}
                                        <strong>
                                            {issue.site.toUpperCase()}
                                        </strong>
                                    </div>
                                    <div className="my-2">
                                        Devices affected:{" "}
                                        <strong>{issue.devicesAffected}</strong>
                                    </div>
                                </div>
                            </div>
                            {issue.status === "Resolved" && (
                                <IssueAddResolution
                                    handleInputChange={handleInputChange}
                                />
                            )}
                        </div>
                    </div>

                    <div className="flex justify-end gap-4">
                        {issue.status === "Resolved" && (
                            <button
                                type="button"
                                className="btn danger"
                                onClick={handleAddresolution}
                            >
                                ADD RESOLUTION
                            </button>
                        )}
                        {!["Escalated", "Resolved"].includes(issue.status) && (
                            <EscalteButton issue={issue} refetch={refetch} />
                        )}
                        <button type="button" className="btn danger">
                            OPEN TROUBLESHOOTER
                        </button>
                    </div>
                </>
            }
            isOpen={isModalOpen}
            onClose={closeModal}
            size="max-w-2xl"
        />
    );
};

const IssueButton = ({ issue, refetch }: IssueSectionProps) => {
    const { t } = useTranslation();

    const [isModalOpen, setModalOpen] = useState(false);
    const closeModal = () => setModalOpen(false);

    return (
        <>
            <button className="btn danger" onClick={() => setModalOpen(true)}>
                {t(`FIX ISSUE`)}
            </button>
            {isModalOpen && (
                <IssueModal
                    issueNumber={issue.number}
                    refetchPage={refetch}
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                />
            )}
        </>
    );
};

const IssuesSection = ({ issues, refetch }: IssuesSectionProps) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-wrap">
            {issues.edges.map((issue, index) => (
                <div
                    key={issue.node.number}
                    className={`${issues.edges.length === 1 ? "w-full" : "w-full sm:w-1/2"} p-2`}
                    style={{ height: "150px" }}
                >
                    <div className="flex h-full border border-red-600 rounded bg-white">
                        <div className=" w-[100px] flex items-center justify-center">
                            <MdOutlineCloudOff className="text-red-600 font-extrabold text-4xl" />
                        </div>
                        <div className="flex flex-col gap-1 justify-center w-full p-4">
                            <div className="mt-1">
                                <strong>
                                    {t(`${issue.node.entityType}-title`)}
                                </strong>
                            </div>
                            <div>
                                {t(`${issue.node.entityType}-description`)}
                            </div>

                            <div className="flex justify-between">
                                <div>
                                    <Tag
                                        bgColor={generateColor(
                                            issue.node.status
                                        )}
                                    >
                                        {issue.node.status}
                                    </Tag>
                                </div>
                                <IssueButton
                                    issue={issue.node}
                                    refetch={refetch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default IssuesSection;
